import React, { useEffect, useState } from 'react';

import { ConfigProvider, Flex, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

import VizContainer from './VizContainer';

const TableauController = ({
  teamProfileMode,
  vizRef,
}: {
  teamProfileMode: string;
  vizRef: any;
}) => {
  const { teamData } = useSelector((state: AppStateType) => state.teamReducer);
  const [token, setToken] = useState('');
  const [multitoolSelectedTab, setMultitoolSelectedTab] =
    useState('1_GeneralGroup');
  const [t] = useTranslation();
  const { selectedRowKeys } = useSelector((state: AppStateType) => {
    return {
      selectedRowKeys: state.teamMultitoolReducer.selectedRowKeys,
    };
  });
  useEffect(() => {
    if (localStorage.getItem('multi_token') !== undefined) {
      setToken(localStorage.getItem('multi_token') || '');
    }
  }, [localStorage]);
  return (
    <Flex
      style={{
        maxHeight: 1100,
        // height: 'calc(100vh - 168px)',
        backgroundColor: 'white',
        width: '100%',
        borderRadius: 12,
        padding: 8,
        marginBottom: 8,
        overflow: 'hidden',
      }}
      vertical
      justify="flex-start"
    >
      <ConfigProvider>
        <Segmented
          style={{ width: '100%' }}
          size="middle"
          color="black"
          block
          value={multitoolSelectedTab}
          options={[
            {
              label: t('General group'),
              className: 'segment-class',
              value: '1_GeneralGroup',
            },
            {
              label: t('Shots'),
              className: 'segment-class',
              value: '2_Shots',
            },
            {
              label: t('Set-pieces'),
              className: 'segment-class',
              value: '3_Set-pieces',
            },
            {
              label: t('Corners'),
              className: 'segment-class',
              value: '3_1Corners',
            },
            {
              label: t('Ind. Free kicks'),
              className: 'segment-class',
              value: '3_2IndirectFreeKicks',
            },
            {
              label: t('Passes'),
              className: 'segment-class',
              value: '4_Passes',
            },
            {
              label: t('Losses'),
              className: 'segment-class',
              value: '5_Losses',
            },
            {
              label: t('Duels +'),
              className: 'segment-class',
              value: '6_Duels1v1Interceptions',
            },
            {
              label: t('Discipline'),
              className: 'segment-class',
              value: '7_Discipline',
            },
            {
              label: t('Trans. to def.'),
              className: 'segment-class',
              value: '8_Defense',
            },
            {
              label: t('Trans. to att.'),
              className: 'segment-class',
              value: '9_Attack',
            },
            {
              label: t('Possessions'),
              className: 'segment-class',
              value: '10_Possessions',
            },
          ]}
          onChange={setMultitoolSelectedTab}
        />
      </ConfigProvider>
      {teamData?.name && token && selectedRowKeys !== undefined && (
        <VizContainer
          selectedTab={multitoolSelectedTab}
          teamProfileMode={teamProfileMode}
          vizRef={vizRef}
        />
      )}
    </Flex>
  );
};
export default TableauController;
