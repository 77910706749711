import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Flex, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { GetSingleSeasonSelectedTeamLineup } from 'actions/teamLineup.actions';
import {
  GetSingleSeasonSelectedMultitool,
  SetSelectedMultitoolTeamLastMatch,
} from 'actions/teamMultitool.actions';
import { AuthAPI } from 'api/authentication';
import { ClubsAPI } from 'api/clubs';
import LogoGrey from 'assets/img/LogoSotaGrey.svg';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { teamLineupsReducer } from 'reducers/teamLineups.reducer';
import { teamMultitoolReducer } from 'reducers/teamMultitool.reducer';
import { useAppDispatch } from 'store';

import { ReactComponent as PlayCircleFilledWhite } from '../../../assets/img/icons/faPlayCircleFilledWhite.svg';
import './index.css';
import MatchesSelector from '../TeamTableauController/MatchesSelector';
import TeamTournamentTableController from '../TeamTournamentTableController';

const TeamHeaderV2 = ({ vizRef }: { vizRef: any }) => {
  const [t] = useTranslation();
  const { teamId } = useParams();
  const [openTournamentTable, setOpenTournamenTable] = useState(false);
  const { teamProfileMode, teamData } = useSelector((state: AppStateType) => {
    return state.teamReducer;
  });
  const { isMultitoolPopupOpen } = useSelector((state: AppStateType) => {
    return state.teamMultitoolReducer;
  });
  const { isTeamLineupPopupOpen } = useSelector((state: AppStateType) => {
    return state.teamLineupsReducer;
  });
  const navigate = useNavigate();
  const { setTeamProfileMode, setTeamData } = teamReducer.actions;
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const getLinup = async () => {
      if (teamId) {
        dispatch(setTeamData([]));
        const teamResponse = await ClubsAPI.getTeam(teamId, navigate);
        const metaElement = document.getElementById('meta-top-name');
        const titleElement = document.getElementById('title-top-name');
        if (teamResponse) {
          dispatch(setTeamData(teamResponse.data));
          if (metaElement && titleElement) {
            metaElement.setAttribute('content', teamResponse.data.name);
            titleElement.innerHTML = teamResponse.data.name;
          }
        } else {
          if (metaElement && titleElement) {
            metaElement.setAttribute('content', t('Page not found'));
            titleElement.innerHTML = t('Page not found');
          }
        }
      }
    };
    getLinup().then();
  }, [teamId]);
  useEffect(() => {
    if (
      searchParams.get('teamProfileMode') === 'overview' ||
      !searchParams.get('teamProfileMode')
    ) {
      dispatch(setTeamProfileMode('overview'));
    } else if (searchParams.get('teamProfileMode') === 'players') {
      dispatch(setTeamProfileMode('players'));
    } else if (searchParams.get('teamProfileMode') === 'multitool') {
      dispatch(setTeamProfileMode('multitool'));
    }
  }, [teamId]);
  const { setMultitoolSelectedSeason, setIsMultitoolPopupOpen } =
    teamMultitoolReducer.actions;
  const { setSelectedTeamLineupsSeason, setIsTeamLineupPopupOpen } =
    teamLineupsReducer.actions;
  const dispatch = useAppDispatch();
  return (
    <>
      <Flex vertical>
        <Flex>
          <div
            className=""
            style={{
              display: 'grid',
              width: '100%',
              marginTop: 8,
              gridTemplateColumns: 'repeat(9, 1fr)',
            }}
          >
            <Flex className="g-col-3" gap={4} flex={1}>
              <Flex gap={21}>
                <Flex
                  align="center"
                  justify="center"
                  style={{
                    width: 76,
                    height: 76,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <img
                    style={{
                      height: '100%',
                    }}
                    src={teamData?.image || LogoGrey}
                  />
                </Flex>
                <Flex
                  vertical
                  // style={{ marginTop: 4, marginBottom: 12 }}
                  justify="center"
                >
                  <div className="team-player-name">{teamData?.name}</div>
                  <div
                    className="team-league-name"
                    onClick={() => setOpenTournamenTable(true)}
                  >
                    {teamData?.league?.country?.name}. {teamData?.league?.name}
                  </div>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              className="g-col-3"
              gap={4}
              align="center"
              justify="center"
              flex={1}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      trackBg: 'var(--segmentedGroupBg)',
                    },
                  },
                }}
              >
                <Segmented
                  style={{ width: '100%' }}
                  size="large"
                  color="black"
                  block
                  value={teamProfileMode}
                  options={[
                    {
                      label: t('Overview'),
                      value: 'overview',
                      className: 'segment-class',
                    },
                    {
                      label: t('Players'),
                      className: 'segment-class',
                      value: 'players',
                    },
                    {
                      label: 'Multitool',
                      className: 'segment-class',
                      value: 'multitool',
                    },
                  ]}
                  onChange={(value) => {
                    if (value === 'overview') {
                      setSearchParams((params: any) => {
                        params.set('teamProfileMode', 'overview');
                        return params;
                      });
                    } else if (value === 'multitool') {
                      setSearchParams((params: any) => {
                        params.set('teamProfileMode', 'multitool');
                        return params;
                      });
                      AuthAPI.getCurrentUser().then((response) => {
                        if (response.status === 200) {
                          const user = response.data;
                          localStorage.setItem('multi_token', user.multi_token);
                        }
                      });
                    } else {
                      setSearchParams((params: any) => {
                        params.set('teamProfileMode', 'players');
                        return params;
                      });
                    }
                    dispatch(setTeamProfileMode(value)); // string
                  }}
                />
              </ConfigProvider>
            </Flex>
            <Flex
              className="g-col-3"
              gap={4}
              flex={1}
              align="center"
              justify={'flex-end'}
            >
              {teamProfileMode === 'multitool' && (
                <MatchesSelector
                  getSingleSeasonAction={GetSingleSeasonSelectedMultitool}
                  vizRef={vizRef}
                  open={isMultitoolPopupOpen}
                  setOpen={setIsMultitoolPopupOpen}
                  setSelectedSeasonAction={setMultitoolSelectedSeason}
                  setSelectedLastMatchCallback={
                    SetSelectedMultitoolTeamLastMatch
                  }
                  reducerName={'teamMultitoolReducer'}
                  reducer={teamMultitoolReducer}
                />
              )}
              {teamProfileMode === 'players' && (
                <MatchesSelector
                  getSingleSeasonAction={GetSingleSeasonSelectedTeamLineup}
                  vizRef={vizRef}
                  open={isTeamLineupPopupOpen}
                  setOpen={setIsTeamLineupPopupOpen}
                  setSelectedSeasonAction={setSelectedTeamLineupsSeason}
                  setSelectedLastMatchCallback={(lastMatch: any) =>
                    dispatch(
                      teamLineupsReducer.actions.setSelectedLastMatch(
                        lastMatch,
                      ),
                    )
                  }
                  reducerName={'teamLineupsReducer'}
                  reducer={teamLineupsReducer}
                />
              )}
              {teamProfileMode === 'search' ? (
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        paddingInline: 12,
                      },
                    },
                  }}
                >
                  <Button icon={<PlayCircleFilledWhite />} type="primary">
                    {t('View {} episodes')}
                  </Button>
                </ConfigProvider>
              ) : (
                <></>
              )}
            </Flex>
          </div>
        </Flex>
      </Flex>
      <TeamTournamentTableController
        isOpen={openTournamentTable}
        onClose={() => setOpenTournamenTable(false)}
      />
    </>
  );
};
export default TeamHeaderV2;
