import React, { useState } from 'react';

import { Flex, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AppStateType } from 'reducers';

import { ReactComponent as DownOutlinedBlack } from '../../../../assets/img/icons/faDownOutlinedBlack.svg';
import AverageStatValueTag from '../AverageStatValueTag';
import ConsolidatedStatValueTag from '../ConsolidatedStatValueTag';
import { makeLink } from '../const';
import MetricFilter from '../MetricFilter';

import './index.css';

const StatisticsDetailsBlock = ({
  selectedMetricFilters,
  // selectedSeason,
  setSelectedMetricFilters,
  rightMargin = 8,
}: {
  selectedMetricFilters: any;
  selectedSeason: any;
  setSelectedMetricFilters: any;
  rightMargin?: number;
}) => {
  const { playerId } = useParams();
  const {
    statsState,
    teamPlayerStats,
    timeOnField,
    selectedRowKeys,
    timeOnFieldByPosition,
    loadingPercentiles,
  } = useSelector((state: AppStateType) => {
    return state.teamPlayerStatsReducer;
  });
  const { openedTeamPlayer, selectedPosition } = useSelector(
    (state: AppStateType) => {
      return state.teamPlayerReducer;
    },
  );
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const showAveragesTag = openedTeamPlayer && openedTeamPlayer.team.sport !== 2;

  const makeLinkAndNavigate = (stat: any, overBaseMetric: string | null) => {
    const metricStringSuccess = `${stat.key}_success`;
    const metricStringFail = `${stat.key}_fail`;
    let finalMetric = [stat];
    if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringSuccess)
    ) {
      finalMetric = [{ key: metricStringSuccess }];
    } else if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringFail)
    ) {
      finalMetric = [{ key: metricStringFail }];
    } else if (overBaseMetric) {
      finalMetric = [{ key: stat.key + overBaseMetric }];
    }
    const link = makeLink(
      [{ id: null }],
      openedTeamPlayer.team,
      finalMetric,
      undefined,
      '',
      playerId,
      selectedPosition,
    );
    localStorage.setItem(
      `player_matches_${link}`,
      JSON.stringify(selectedRowKeys),
    );
    window.open(link, '_blank');
  };
  const toggleSelectedMetric = (
    metricString: string,
    removedMetricString: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    asPoint?: boolean,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev: any) =>
          prev.filter((el: any) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
      } else {
        setSelectedMetricFilters((prev: any) => [
          ...prev.filter((p: any) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
  };
  const onMousEnter = (e: any, stat: any, hasSuccess: boolean) => {
    e.preventDefault();
    if (stat?.percent !== null && stat?.percent !== undefined && hasSuccess) {
      setHoveredMetricFilters((prev) => [...prev, stat.key]);
    }
  };
  return (
    <Flex
      vertical
      style={{ margin: `0 ${rightMargin}px 8px 16px`, alignSelf: 'stretch' }}
    >
      {[...teamPlayerStats]
        ?.sort((a: any, b: any) => a.ordering - b.ordering)
        ?.map((stat: any, index: number) => {
          return (
            <div key={index}>
              <Flex
                key={index}
                justify="flex-start"
                style={{ width: '100%', margin: '2px 0px' }}
              >
                <Flex
                  justify="space-between"
                  style={{ width: '100%' }}
                  align="center"
                  gap={8}
                  onMouseEnter={(e: any) => {
                    onMousEnter(e, stat.stat, stat.success);
                  }}
                  onMouseLeave={() => {
                    setHoveredMetricFilters((prev) =>
                      prev.filter((el: string) => el !== stat.key),
                    );
                  }}
                >
                  <Flex
                    style={{
                      marginLeft: 0,
                      overflow: 'hidden',
                    }}
                    align="center"
                    justify="flex-start"
                    gap={8}
                    flex={1}
                    className={`${stat.children?.length ? 'enabledClickable' : ''}`}
                  >
                    {stat.label}
                    {stat.children?.length && (
                      <DownOutlinedBlack
                        style={{
                          zIndex: 20,
                          width: 30,
                          height: 30,
                          padding: 7,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            stat.children?.length &&
                            expandedStats.includes(stat.key)
                          ) {
                            setExpandedStats((prev) =>
                              prev.filter((item: string) => item !== stat.key),
                            );
                          } else {
                            setExpandedStats((prev) => [...prev, stat.key]);
                          }
                        }}
                        className={`enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                      />
                    )}
                  </Flex>
                  <Flex>
                    <MetricFilter
                      metric={stat.key}
                      asPoint={stat.as_point}
                      selectedMetricFilters={selectedMetricFilters}
                      hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                        stat.key,
                      )}
                      toggleSelectedMetric={toggleSelectedMetric}
                      reverseMatchColor={stat.reverse_match_color}
                    />
                  </Flex>
                  <Flex gap={8}>
                    {statsState === 'loading' || statsState === 'init' ? (
                      <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                        <Skeleton.Button
                          active
                          style={{ minWidth: 130, minHeight: 38 }}
                        />
                      </div>
                    ) : (
                      <ConsolidatedStatValueTag
                        showPth={showAveragesTag}
                        stat={stat.stat || stat}
                        overBaseMetric={stat?.over_base_metric}
                        makeLink={makeLinkAndNavigate}
                        loadingPercentiles={loadingPercentiles}
                        selectedMetricFilters={selectedMetricFilters}
                        selectedPositions={selectedPosition}
                      />
                    )}
                    {showAveragesTag &&
                      (statsState === 'loading' || statsState === 'init' ? (
                        <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                          <Skeleton.Button
                            active
                            style={{
                              maxWidth: 56,
                              minWidth: 56,
                              minHeight: 38,
                            }}
                          />
                        </div>
                      ) : (
                        <AverageStatValueTag
                          stat={stat.stat}
                          overBaseMetric={stat?.over_base_metric}
                          loadingPercentiles={loadingPercentiles}
                          selectedMetricFilters={selectedMetricFilters}
                          selectedPositions={selectedPosition}
                          timeOnField={timeOnField}
                          timeOnFieldByPosition={
                            selectedPosition &&
                            timeOnFieldByPosition[selectedPosition]
                          }
                        />
                      ))}
                  </Flex>
                </Flex>
              </Flex>
              {stat.children?.length > 0 && (
                <Flex
                  key={`${index}-x`}
                  vertical
                  style={{ width: '100%', marginBottom: 2 }}
                  gap={4}
                  className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
                >
                  {[...stat.children]
                    ?.sort((a: any, b: any) => a.ordering - b.ordering)
                    .map((child: any, index: number) => {
                      return (
                        <Flex
                          key={index}
                          justify="space-between"
                          style={{ width: '100%' }}
                          gap={8}
                        >
                          <Flex
                            style={{
                              marginLeft: 20,
                              overflow: 'hidden',
                            }}
                            align="center"
                            justify="space-between"
                            gap={8}
                            flex={1}
                            onMouseEnter={(e: any) => {
                              onMousEnter(
                                e,
                                child.stat?.stat || child.stat,
                                child.stat?.success || child.success,
                              );
                            }}
                            onMouseLeave={() => {
                              const s = child.stat?.stat || child.stat;
                              setHoveredMetricFilters((prev) =>
                                prev.filter((el: string) => el !== s.key),
                              );
                            }}
                          >
                            <Flex>{child.label}</Flex>
                            <MetricFilter
                              metric={child.key}
                              asPoint={child.as_point}
                              selectedMetricFilters={selectedMetricFilters}
                              hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                                child.key,
                              )}
                              toggleSelectedMetric={toggleSelectedMetric}
                              reverseMatchColor={child.reverse_match_color}
                            />
                          </Flex>
                          <Flex gap={8}>
                            {statsState === 'loading' ||
                            statsState === 'init' ? (
                              <>
                                <div
                                  style={{
                                    borderRadius: 8,
                                    overflow: 'hidden',
                                  }}
                                >
                                  <Skeleton.Button
                                    active
                                    style={{ minWidth: 130, minHeight: 38 }}
                                  />
                                </div>
                                {showAveragesTag && (
                                  <div
                                    style={{
                                      borderRadius: 8,
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Skeleton.Button
                                      active
                                      style={{
                                        maxWidth: 56,
                                        minWidth: 56,
                                        minHeight: 38,
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <ConsolidatedStatValueTag
                                  showPth={
                                    openedTeamPlayer &&
                                    openedTeamPlayer.team.sport !== 2
                                  }
                                  overBaseMetric={child.over_base_metric}
                                  stat={child.stat?.stat || child.stat}
                                  loadingPercentiles={loadingPercentiles}
                                  selectedMetricFilters={selectedMetricFilters}
                                  makeLink={makeLinkAndNavigate}
                                  selectedPositions={selectedPosition}
                                />
                                {showAveragesTag && (
                                  <AverageStatValueTag
                                    stat={child.stat?.stat || child.stat}
                                    overBaseMetric={child?.over_base_metric}
                                    loadingPercentiles={loadingPercentiles}
                                    selectedMetricFilters={
                                      selectedMetricFilters
                                    }
                                    selectedPositions={selectedPosition}
                                    timeOnField={timeOnField}
                                    timeOnFieldByPosition={
                                      selectedPosition &&
                                      timeOnFieldByPosition[selectedPosition]
                                    }
                                  />
                                )}
                              </>
                            )}
                          </Flex>
                        </Flex>
                      );
                    })}
                </Flex>
              )}
            </div>
          );
        })}
    </Flex>
  );
};
export default StatisticsDetailsBlock;
