import React, { useEffect } from 'react';

import { Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import PhasesTooltipAndButton from 'components/PhasesTooltipAndButton';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';

import MatchStatsContainer from './MatchStatsContainer';
import { ReactComponent as ChevronRight } from '../../../../assets/img/icons/faChevronRight.svg';
import { ReactComponent as PlayCircleFilled } from '../../../../assets/img/icons/faPlayCircleFilled.svg';
import GameEventsTags from '../GameEventsTags';
import ProtocolButton from '../ProtocolButton';

import './index.css';
interface MatchRowInterface {
  match: any;
  matches: any;
  isExpanded: boolean;
  toggleExpanded: any;
  getEventCoordinates: any;
  populateCoordinates: any;
  clearAllFiltersCallback: any;
}

const MatchRow: React.FC<MatchRowInterface> = ({
  match,
  matches,
  isExpanded,
  toggleExpanded,
  getEventCoordinates,
  populateCoordinates,
  clearAllFiltersCallback,
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { setExpandedGameClickedId } = teamPlayerReducer.actions;
  const { setStatsCustomGameList } = teamReducer.actions;
  const { expandedGameClickedId } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });

  const { playerId } = useParams();
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  const { setExpandedGamesPlayer } = teamPlayerReducer.actions;
  useEffect(() => {
    if (expandedGameClickedId !== null) {
      const gameDiv = document.getElementById(expandedGameClickedId.id);
      if (gameDiv) {
        gameDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
      if (expandedGameClickedId.expanded) {
        matches
          ?.filter((match: any) => match.id === expandedGameClickedId.id)[0]
          ?.stats.filter(
            (stat: any) =>
              metricsRegistry.match?.filter(
                (regStat: any) => stat.key === regStat.key,
              ).length > 0,
          )
          ?.map((stat: any) => {
            if (stat.value) {
              if (stat.details) {
                getEventCoordinates(
                  expandedGameClickedId.id,
                  stat.details.success.key,
                  stat.key,
                  ['success'],
                );
                getEventCoordinates(
                  expandedGameClickedId.id,
                  stat.details.fail.key,
                  stat.key,
                  ['fail'],
                );
              } else {
                getEventCoordinates(
                  expandedGameClickedId.id,
                  stat.key,
                  stat.key,
                );
              }
            }
          });
        dispatch(setExpandedGamesPlayer([expandedGameClickedId.id]));
      }
    }
  }, [matches]);
  const { openedTeamPlayer } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });
  const makeLink = (
    match: any,
    team?: any,
    stat?: any,
    part?: any,
    phase?: any,
  ) => {
    let link = `/player?eventId=${match.id}`;
    if (team) {
      if (part === 'highlights') {
        link = `${link}&teamId=${team?.id}`;
      } else {
        link = `${link}&teamId=${team?.id}`;
      }
    }
    if (stat) {
      link = `${link}&m=${stat.key}`;
    }
    if (part) {
      link = `${link}&p=${part}`;
    }
    if (phase) {
      link = `${link}&ph=${phase}`;
    }
    link = `${link}&pl=${openedTeamPlayer.id}`;
    dispatch(AC_setIntermediateActivitiesAction(match ? [match.id] : []));
    dispatch(setStatsCustomGameList(match ? [match.id] : []));
    return link;
  };
  const matchHasEvents = (match: any) => {
    return (
      match.stats?.filter(
        (stat: any) =>
          (stat.key === 'yellow_cards' &&
            stat.value !== null &&
            stat.value > 0) ||
          (stat.key === 'red_cards' && stat.value !== null && stat.value > 0) ||
          (stat.key === 'goal' && stat.value !== null && stat.value > 0) ||
          (stat.key === 'goal_pass' && stat.value !== null && stat.value > 0),
      ).length > 0
    );
  };
  return (
    <div
      className={`team-match-wrapper player ${isExpanded ? 'pressed' : 'enabledClickable'}`}
      onClick={() => {
        toggleExpanded(match);
      }}
      id={match.id}
      key={match.id}
      onMouseEnter={() => {
        openedTeamPlayer && populateCoordinates(match);
      }}
    >
      <div
        className="enabledClickable"
        onClick={(e) => {
          e.stopPropagation();
          toggleExpanded(match);
        }}
      >
        <div
          style={{
            width: 32,
            height: 32,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexShrink: 0,
          }}
        >
          <ChevronRight
            className={`${isExpanded ? 'rotate90' : 'rotate0'} `}
            fill="var(--colorLink, #1677FF)"
          />
        </div>
        <div className="match-details">
          <div>{match.date}</div>
          <div
            style={{
              width: '100%',
            }}
            className="elipsis-text"
          >
            {match.tournament?.country}
          </div>
          <div
            style={{
              width: '100%',
            }}
            className="elipsis-text"
          >
            {match.tournament?.name}
          </div>
        </div>
        <div className="match-participants-container">
          <Flex
            style={{ textAlign: 'end', flex: 1, textWrap: 'nowrap' }}
            justify="flex-end"
          >
            <div
              className="team-match-row-team-name"
              onClick={(e) => {
                e.stopPropagation();
                window.open(`/teams/${match.teams[0].id}`, '_blank');
              }}
            >
              {match.teams[0].name}
            </div>
          </Flex>
          <div
            className="score"
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div>{match.teams[0].score}</div>:<div>{match.teams[1].score}</div>
          </div>
          <Flex
            style={{ textAlign: 'start', flex: 1, textWrap: 'nowrap' }}
            justify="flex-start"
          >
            <div
              className="team-match-row-team-name"
              onClick={(e) => {
                e.stopPropagation();
                window.open(`/teams/${match.teams[1].id}`, '_blank');
              }}
            >
              {match.teams[1].name}
            </div>
          </Flex>
        </div>
        <Flex align="center">
          <Flex
            // vertical
            // gap={6}
            style={{ height: '96px' }}
            justify="flex-end"
          >
            <GameEventsTags
              match={match}
              hasEvents={matchHasEvents(match)}
              playerId={playerId || ''}
              isExpanded={isExpanded}
            />
            <ProtocolButton
              matchData={match}
              matchId={match.id}
              playedTime={
                openedTeamPlayer &&
                openedTeamPlayer.team.sport !== 2 &&
                match.stats?.filter(
                  (stat: any) => stat.key === 'played_time',
                )[0]
              }
              roleStats={
                openedTeamPlayer &&
                openedTeamPlayer.team.sport !== 2 &&
                match.roles
              }
              role={
                openedTeamPlayer &&
                openedTeamPlayer.team.sport !== 2 &&
                match.stats?.filter((stat: any) => stat.key === 'role')[0]
              }
            />
          </Flex>
          <Divider type="vertical" style={{ height: 80 }} />
          <Flex align="center" className="buttons-controller">
            <div
              style={{
                height: 32,
              }}
              className="enabledClickable"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setExpandedGameClickedId({
                    id: match.id,
                    expanded: isExpanded,
                  }),
                );
                window.open(
                  makeLink(
                    match,
                    { id: openedTeamPlayer.team.id },
                    null,
                    'full',
                  ),
                  '_blank',
                );
              }}
            >
              <PlayCircleFilled />
              {t('Match')}
            </div>
            <div
              style={{
                height: 32,
              }}
              className="enabledClickable"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setExpandedGameClickedId({
                    id: match.id,
                    expanded: isExpanded,
                  }),
                );
                window.open(
                  makeLink(match, { id: openedTeamPlayer.team.id }, null, null),
                  '_blank',
                );
              }}
            >
              <PlayCircleFilled />
              {t('All actions')}
            </div>
            <PhasesTooltipAndButton
              match={match}
              teamId={openedTeamPlayer?.team?.id}
            />
          </Flex>
        </Flex>
      </div>
      <MatchStatsContainer
        isExpanded={isExpanded}
        loadingCoordinates={false}
        clearAllFiltersCallback={clearAllFiltersCallback}
        match={match}
        makeLink={makeLink}
      />
    </div>
  );
};
export default MatchRow;
