import { ThunkAction } from 'redux-thunk';

import { ClubsAPI } from 'api/clubs';
import { EventsAPI } from 'api/events';
import { RegistriesAPI } from 'api/registries';
import { makeTeamStatsPlaceholders } from 'components/TeamPageController/TeamStatisticsV2/const';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { teamLineupsReducer } from 'reducers/teamLineups.reducer';
import { teamMultitoolReducer } from 'reducers/teamMultitool.reducer';

const {
  setSelectedLastMatch,
  setTeamStatsMatches,
  setTeamSeasonsList,
  setTeamTournamentSeasonTable,
  setTeamTournamentSelectedSeason,
  setGameRowPerTeamStats,
  setTableMatchesLoading,
  setTableMatches,
  setSelectedRowKeys,
  setCheckedSeasons,
  setSelectedTournaments,
  setSelectedMatches,
  setExpandedSeasons,
  setAppliedSeasons,
  setTeamStats,
  // setTeamMatches,
  setStatsState,
} = teamReducer.actions;

const {
  setMultitoolTableMatchesLoading,
  setMultitoolTeamStatsMatches,
  setMultitoolAppliedSeasons,
} = teamMultitoolReducer.actions;
export const SetSelectedTeamLastMatch =
  (
    count: number | string | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedLastMatch(count));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const GetTournamentTable =
  (
    tournamentId: number,
    seasonId: number,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    const teamResponse = await RegistriesAPI.getScoreTableV2(
      tournamentId,
      seasonId,
    );
    dispatch(setTeamTournamentSeasonTable(teamResponse.data));
  };

export const InitializeTeamSeasons =
  (teamId: string): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    const response = await ClubsAPI.getTeamSeasons(teamId, true);
    dispatch(setTeamSeasonsList(response.data));
    if (response.data.length) {
      dispatch(
        GetTournamentTable(response.data[0].tournament.id, response.data[0].id),
      );
      dispatch(
        setTeamTournamentSelectedSeason(
          `${response.data[0].tournament.id}-${response.data[0].id}`,
        ),
      );
    }
  };

export const GetMatchStatsPerTeam =
  (
    matchId: string,
    teamId: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    const response = await EventsAPI.getGameStatsForTeam(matchId, teamId);
    dispatch(
      setGameRowPerTeamStats({ matchId: matchId, stats: response.data }),
    );
  };

export const GetTeamAllMatches =
  (teamId: string): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setTableMatchesLoading('loading'));
      dispatch(setMultitoolTableMatchesLoading('loading'));
      try {
        const data = await ClubsAPI.getTeamGamesWithSeasons(teamId, true);
        dispatch(setTeamStatsMatches(data.data));
        dispatch(setMultitoolTeamStatsMatches(data.data));
        dispatch(teamLineupsReducer.actions.setTeamStatsMatches(data.data));
        if (data.data.length) {
          const firstSeasonMatchIds = data.data[0]['leagues'][0]['matches'].map(
            (match: any) => match.id,
          );
          dispatch(
            setTableMatches(
              data.data[0]['leagues'][0]['matches'].map((match: any) => {
                return { key: match.id, gameDetails: match };
              }),
            ),
          );
          dispatch(
            teamMultitoolReducer.actions.setTableMatches(
              data.data[0]['leagues'][0]['matches'].map((match: any) => {
                return { key: match.id, gameDetails: match };
              }),
            ),
          );
          dispatch(
            teamLineupsReducer.actions.setTableMatches(
              data.data[0]['leagues'][0]['matches'].map((match: any) => {
                return { key: match.id, gameDetails: match };
              }),
            ),
          );
          dispatch(setSelectedRowKeys(firstSeasonMatchIds));
          dispatch(
            teamMultitoolReducer.actions.setSelectedRowKeys(
              firstSeasonMatchIds,
            ),
          );
          dispatch(
            teamLineupsReducer.actions.setSelectedRowKeys(firstSeasonMatchIds),
          );
          dispatch(
            setCheckedSeasons([
              `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
            ]),
          );
          dispatch(
            teamMultitoolReducer.actions.setCheckedSeasons([
              `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
            ]),
          );
          dispatch(
            teamLineupsReducer.actions.setCheckedSeasons([
              `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
            ]),
          );
          dispatch(
            setSelectedTournaments([
              `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
            ]),
          );
          dispatch(
            teamMultitoolReducer.actions.setSelectedTournaments([
              `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
            ]),
          );
          dispatch(
            teamLineupsReducer.actions.setSelectedTournaments([
              `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
            ]),
          );
          dispatch(setSelectedMatches(data.data[0]['leagues'][0]['matches']));
          dispatch(
            teamMultitoolReducer.actions.setSelectedMatches(
              data.data[0]['leagues'][0]['matches'],
            ),
          );
          dispatch(
            teamLineupsReducer.actions.setSelectedMatches(
              data.data[0]['leagues'][0]['matches'],
            ),
          );
          dispatch(setExpandedSeasons([`0-${data.data[0].name}`]));
          dispatch(
            teamMultitoolReducer.actions.setExpandedSeasons([
              `0-${data.data[0].name}`,
            ]),
          );
          dispatch(
            teamLineupsReducer.actions.setExpandedSeasons([
              `0-${data.data[0].name}`,
            ]),
          );
          dispatch(setAppliedSeasons(data.data[0].leagues[0]?.season_id));
          dispatch(
            setMultitoolAppliedSeasons(data.data[0].leagues[0]?.season_id),
          );
        }
        dispatch(setTableMatchesLoading('loaded'));
        dispatch(setMultitoolTableMatchesLoading('loaded'));
      } catch (e) {
        dispatch(setTableMatchesLoading('error'));
        dispatch(setMultitoolTableMatchesLoading('error'));
      }
    } catch (e) {
      console.log(e);
    }
  };

export const GetSingleSeasonSelected =
  (): ThunkAction<Promise<string>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { teamStatsMatches, selectedRowKeys } = getState().teamReducer;
    let seasonId = 'manual';
    let found = true;
    selectedRowKeys !== undefined &&
      teamStatsMatches.forEach((season: any) => {
        if (!found) {
          return;
        }
        season.leagues?.forEach((league: any) => {
          if (league.matches.length === selectedRowKeys.length) {
            const leagueIds = league.matches
              .map((leagueMatch: any) => leagueMatch.id)
              .sort();
            const tableMatchesIds = selectedRowKeys.map((el) => el).sort();
            const e = leagueIds.every((value: string, index: number) => {
              return value === tableMatchesIds[index];
            });
            if (e) {
              found = false;
              seasonId = league.season_id;
              return;
            }
          }
        });
      });
    return seasonId;
  };

export const FilterApply =
  (
    t: any,
    checkedSeason: string,
    selectedMatchesIds: any[],
    teamId: string,
    abortSignal: AbortSignal,
    mode = 'overview',
  ): ThunkAction<Promise<any>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    if (mode === 'overview') {
      dispatch(setStatsState('loading'));
    }
    try {
      let tournamentIdForFilter;
      const { selectedPosition } = getState().teamPlayerReducer;
      const { teamData } = getState().teamReducer;
      const { selectedPosition_stats } =
        getState().teamPlayerStatsSectionReducer;
      const response = await EventsAPI.getTeamGamesStatsByPost(
        checkedSeason,
        teamId,
        mode === 'overview'
          ? selectedPosition || ''
          : selectedPosition_stats || '',
        tournamentIdForFilter,
        selectedMatchesIds,
        false,
        abortSignal,
      );
      const finalStats: any = [];
      const placeholder = makeTeamStatsPlaceholders(t, teamData.sport);
      placeholder.forEach((stat) => {
        const populatedStat: any = {
          label: stat.label,
          key: stat.key,
          excluded_sports: stat.excluded_sports,
          for_sport: stat.for_sport,
          stat: response?.data?.stats.filter(
            (s: any) => s.key === stat?.key,
          )[0],
        };
        if (stat.children && stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const childPopulatedStat = {
              ...childStat,
              excluded_sports: childStat.excluded_sports,
              for_sport: childStat.for_sport,
              as_point: childStat.as_point,
              disabled: childStat.disabled,
              stat: response.data.stats.filter(
                (s: any) => s.key === childStat.key,
              )[0],
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });
      if (mode === 'overview') {
        await dispatch(setTeamStats(finalStats));
        dispatch(setStatsState('loaded'));
      }
      return finalStats;
    } catch (e) {
      if (mode === 'overview') {
        dispatch(setStatsState('error'));
      }
      console.error('first error', e);
    }
  };
