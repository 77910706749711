import React, { useEffect, useState } from 'react';

import { ConfigProvider, Divider, Flex, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MetricFilter from 'components/PlayerPageController/PlayerStatisticsBlock/MetricFilter';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';

import { ReactComponent as DownOutlinedBlack } from '../../../assets/img/icons/faDownOutlinedBlack.svg';
import MetricPlayerButtonWithPercent from '../MetricPlayerButtonWIthPercent';
import './index.css';

const TeamMetrics = ({ match }: { match: any }) => {
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  const { teamData, gameRowPerTeamStats } = useSelector(
    (state: AppStateType) => {
      return state.teamReducer;
    },
  );
  const { setExpandedGameClickedId } = teamReducer.actions;
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [t] = useTranslation();
  const SPORT_SPECIFIC_LABELS: any = {
    2: {
      goal_kick: t('Goal clearance'),
      out: t('Kick-ins'),
    },
  };
  const currentLabels = SPORT_SPECIFIC_LABELS[teamData?.sport];
  const GAME_STAT = {
    home_team: match.teams[0].name,
    away_team: match.teams[1].name,
    metrics: [
      {
        label: t('Shots'),
        key: 'shot',
        status: 'ready',
        percent: 0,
        home_team: {
          value: 10,
          average: 10,
        },
        away_team: {
          value: 20,
          average: 10,
        },
      },
      {
        label: t('Passes'),
        key: 'pass',
        status: 'ready',
        home_team: {
          value: 0,
          average: 10,
        },
        away_team: {
          value: 0,
          average: 10,
        },
        children: [
          {
            label: t('forward__'),
            status: 'ready',
            key: 'pass_forward',
            home_team: {
              value: 0,
              average: 10,
            },
            away_team: {
              value: 0,
              average: 10,
            },
          },
          {
            label: t('progressive'),
            status: 'ready',
            key: 'pass_progressive',
            home_team: {
              value: 0,
              average: 10,
            },
            away_team: {
              value: 0,
              average: 10,
            },
          },
          {
            label: t('key'),
            status: 'loading',
            key: 'key_pass',
          },
          {
            label: t('into 3/3'),
            status: 'loading',
            key: 'pass_to_3rd',
          },
          {
            label: t('into pen. area'),
            status: 'loading',
            key: 'pass_to_box',
          },
          {
            label: t('crosses'),
            status: 'loading',
            key: 'pass_cross',
          },
          {
            label: t('long'),
            status: 'loading',
            key: 'pass_long',
          },
          // <ConfigProvider
          //   theme={{
          //     token: {
          //       marginLG: 0,
          //     },
          //   }}
          // >
          //   <Divider />
          // </ConfigProvider>,
        ],
      },
    ],
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [teamStats, setTeamStats] = useState(GAME_STAT);
  useEffect(() => {
    const finalStats = {
      ...gameRowPerTeamStats[match.id],
      metrics: gameRowPerTeamStats[match.id]?.metrics.map((m: any) => {
        return {
          ...m,
          label: currentLabels ? currentLabels[m.key] || m.label : m.label,
        };
      }),
    };
    setTeamStats(finalStats);
  }, [gameRowPerTeamStats]);
  const toggleSelectedMetric = (
    metricString: string,
    removedMetricString: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev: any) =>
          prev.filter((el: any) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
      } else {
        setSelectedMetricFilters((prev: any) => [
          ...prev.filter((p: any) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
  };
  const onMousEnter = (e: any, stat: any) => {
    e.preventDefault();
    if (stat?.percent !== null && stat?.percent !== undefined) {
      setHoveredMetricFilters((prev) => [...prev, stat.key]);
    }
  };
  const showStat = (stat: any) => {
    return (
      teamData &&
      (stat.for_sport?.length === 0 ||
        (stat.for_sport?.length && stat.for_sport.includes(teamData.sport))) &&
      !stat.excluded_sports?.includes(teamData.sport)
    );
  };
  return (
    <>
      <Flex
        className="team-profile-block-column-title team-profile-block-stats-column-title"
        style={{ paddingBottom: 4 }}
        justify="space-between"
        gap={56}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{ flex: 1, maxWidth: 240 }}
          className="stats-header-v2"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {t('Actions')}
        </div>
        <Flex gap={48} style={{ flex: 1 }}>
          <div style={{ minWidth: 130, flex: 1 }} className="stats-header-v2">
            {match.teams[0].name}
          </div>
          <div className="stats-header-v2" style={{ minWidth: 130, flex: 1 }}>
            {match.teams[1].name}
          </div>
        </Flex>
      </Flex>
      <Flex
        vertical
        style={{ margin: '0 0px 8px 0px', alignSelf: 'stretch' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {teamStats?.metrics
          ?.filter((stat: any) => showStat(stat))
          .map((stat: any, index: number) => {
            return (
              <ConfigProvider>
                <Flex
                  justify="flex-start"
                  className={`team-profile-game-stat-row ${stat.key}-${index}-${stat.label}-${match.id}`}
                  style={{ width: '100%', margin: '4px 0px', paddingLeft: 12 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseEnter={(e: any) => {
                    onMousEnter(e, stat);
                  }}
                  onMouseLeave={() => {
                    setHoveredMetricFilters((prev) =>
                      prev.filter((el: string) => el !== stat.key),
                    );
                  }}
                  key={`${stat.key}-${index}-${stat.label}-${match.id}-x`}
                >
                  <Flex
                    justify="space-between"
                    style={{ width: '100%', paddingRight: 48, zIndex: 2 }}
                    gap={48}
                  >
                    <Flex
                      style={{
                        marginLeft: 0,
                        marginRight: 8,
                        overflow: 'hidden',
                        width: '100%',
                        maxWidth: 240,
                        justifyContent: 'space-between',
                      }}
                      align="center"
                      justify="center"
                      gap={8}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Flex
                        gap={8}
                        align="center"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {t(stat.label)}
                        {stat.children?.length && (
                          <DownOutlinedBlack
                            style={{
                              width: 30,
                              height: 30,
                              padding: 7,
                            }}
                            className={`enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                stat.children?.length &&
                                expandedStats.includes(stat.key)
                              ) {
                                setExpandedStats((prev) =>
                                  prev.filter(
                                    (item: string) => item !== stat.key,
                                  ),
                                );
                              } else {
                                setExpandedStats((prev) => [...prev, stat.key]);
                              }
                            }}
                          />
                        )}
                      </Flex>
                      <MetricFilter
                        asPoint={false}
                        metric={stat.key}
                        selectedMetricFilters={selectedMetricFilters}
                        hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                          stat.key,
                        )}
                        toggleSelectedMetric={toggleSelectedMetric}
                        reverseMatchColor={stat.reverse_match_color}
                      />
                    </Flex>
                    <Flex
                      gap={48}
                      flex={1}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {stat.status === 'loading' ? (
                        <div
                          style={{
                            borderRadius: 8,
                            overflow: 'hidden',
                            flex: 1,
                            width: '100%',
                          }}
                        >
                          <Skeleton.Button
                            active
                            className="w100"
                            style={{
                              minWidth: 130,
                              minHeight: 32,
                            }}
                          />
                        </div>
                      ) : (
                        <div style={{ minWidth: 130, minHeight: 32, flex: 1 }}>
                          <MetricPlayerButtonWithPercent
                            match={match}
                            statKey={stat.key}
                            stat={stat.home_team}
                            indexStats={index}
                            teamId={match.teams[0].id || ''}
                            selectedMetricFilters={selectedMetricFilters}
                            setExpandedGameClickedId={setExpandedGameClickedId}
                            teamType={''}
                          />
                        </div>
                      )}
                      {stat.status === 'loading' ? (
                        <div
                          style={{
                            borderRadius: 8,
                            overflow: 'hidden',
                            flex: 1,
                            width: '100%',
                          }}
                        >
                          <Skeleton.Button
                            active
                            className="w100"
                            style={{
                              minWidth: 130,
                              minHeight: 32,
                              flex: 1,
                              width: '100%',
                            }}
                          />
                        </div>
                      ) : (
                        <div style={{ minWidth: 130, minHeight: 32, flex: 1 }}>
                          <MetricPlayerButtonWithPercent
                            match={match}
                            statKey={stat.key}
                            stat={stat.away_team}
                            indexStats={index}
                            teamId={match.teams[1].id || ''}
                            selectedMetricFilters={selectedMetricFilters}
                            setExpandedGameClickedId={setExpandedGameClickedId}
                            teamType={''}
                          />
                        </div>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                {stat?.children?.length > 0 && (
                  <Flex
                    vertical
                    style={{ width: '100%', margin: '4px 0px' }}
                    gap={8}
                    className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
                    key={`${stat.key}-ch-${stat?.children?.length}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {stat.children
                      ?.filter((stat: any) => showStat(stat))
                      .map((child: any, childIndex: number) => {
                        return child.status === 'loading' ? (
                          <Flex
                            gap={46}
                            key={`${child.key}-${childIndex}-${child.label}-${match.id}`}
                            className="team-profile-game-stat-row child"
                            justify="space-between"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{
                              width: '100%',
                              paddingLeft: 24,
                              paddingRight: 48,
                            }}
                            align="center"
                            onMouseEnter={(e: any) => {
                              onMousEnter(e, child);
                            }}
                            onMouseLeave={() => {
                              setHoveredMetricFilters((prev) =>
                                prev.filter((el: string) => el !== child.key),
                              );
                            }}
                          >
                            <Flex
                              style={{
                                marginLeft: 0,
                                marginRight: 8,
                                overflow: 'hidden',
                                width: '100%',
                                maxWidth: 230,
                                zIndex: 2,
                                justifyContent: 'space-between',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              align="center"
                              justify="center"
                              gap={8}
                            >
                              <div>{t(child.label)}</div>
                              <MetricFilter
                                asPoint={false}
                                metric={child.key}
                                selectedMetricFilters={selectedMetricFilters}
                                hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                                  child.key,
                                )}
                                toggleSelectedMetric={toggleSelectedMetric}
                                reverseMatchColor={child.reverse_match_color}
                              />
                            </Flex>
                            <Flex gap={48} flex={1}>
                              <div
                                style={{
                                  borderRadius: 8,
                                  overflow: 'hidden',
                                  flex: 1,
                                  width: '100%',
                                }}
                              >
                                <Skeleton.Button
                                  active
                                  className="w100"
                                  style={{
                                    minWidth: 130,
                                    minHeight: 32,
                                    width: '100%',
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  borderRadius: 8,
                                  overflow: 'hidden',
                                  flex: 1,
                                  width: '100%',
                                }}
                              >
                                <Skeleton.Button
                                  active
                                  className="w100"
                                  style={{
                                    minWidth: 130,
                                    minHeight: 32,
                                  }}
                                />
                              </div>
                            </Flex>
                          </Flex>
                        ) : (
                          <Flex
                            gap={46}
                            className="team-profile-game-stat-row child"
                            justify="space-between"
                            style={{
                              width: '100%',
                              paddingLeft: 24,
                              paddingRight: 48,
                              zIndex: 2,
                            }}
                            align="center"
                            onMouseEnter={(e: any) => {
                              onMousEnter(e, child);
                            }}
                            onMouseLeave={() => {
                              setHoveredMetricFilters((prev) =>
                                prev.filter((el: string) => el !== child.key),
                              );
                            }}
                          >
                            <Flex
                              style={{
                                marginLeft: 0,
                                marginRight: 8,
                                overflow: 'hidden',
                                width: '100%',
                                maxWidth: 230,
                                justifyContent: 'space-between',
                              }}
                              align="center"
                              justify="center"
                              gap={8}
                            >
                              <div>{t(child.label)}</div>
                              <MetricFilter
                                asPoint={false}
                                metric={child.key}
                                selectedMetricFilters={selectedMetricFilters}
                                hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                                  child.key,
                                )}
                                toggleSelectedMetric={toggleSelectedMetric}
                                reverseMatchColor={child.reverse_match_color}
                              />
                            </Flex>
                            <Flex gap={48} flex={1}>
                              <div
                                style={{
                                  minWidth: 130,
                                  minHeight: 32,
                                  width: '100%',
                                  flex: '1 1 100%',
                                  zIndex: 2,
                                }}
                              >
                                <MetricPlayerButtonWithPercent
                                  match={match}
                                  statKey={child.key}
                                  stat={child.home_team}
                                  indexStats={childIndex}
                                  teamId={match.teams[0].id || ''}
                                  selectedMetricFilters={selectedMetricFilters}
                                  setExpandedGameClickedId={
                                    setExpandedGameClickedId
                                  }
                                  teamType={''}
                                />
                              </div>
                              <div
                                style={{
                                  minWidth: 130,
                                  minHeight: 32,
                                  flex: '1 1 100%',
                                  zIndex: 2,
                                }}
                              >
                                <MetricPlayerButtonWithPercent
                                  match={match}
                                  statKey={child.key}
                                  stat={child.away_team}
                                  indexStats={childIndex}
                                  teamId={match.teams[1].id || ''}
                                  selectedMetricFilters={selectedMetricFilters}
                                  setExpandedGameClickedId={
                                    setExpandedGameClickedId
                                  }
                                  teamType={''}
                                />
                              </div>
                            </Flex>
                          </Flex>
                        );
                      })}
                    {stat.children?.length && (
                      <Divider
                        style={{ width: '100%', height: 1, margin: 0 }}
                      />
                    )}
                  </Flex>
                )}
              </ConfigProvider>
            );
          })}
      </Flex>
    </>
  );
};
export default TeamMetrics;
