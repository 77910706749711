import React, { useEffect, useState } from 'react';

import { ConfigProvider, Flex, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { GetMatchStatsPerTeam } from 'actions/team.actions';
import { ClubsAPI } from 'api/clubs';
import MatchSkeleton from 'components/Skeletons/MatchSkeleton';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';

import { EventsAPI } from '../../../api/events';
import { useAppDispatch } from '../../../store';
import TeamMatchBlock from '../TeamMatchRow';

const MatchesBlock = () => {
  const [t] = useTranslation();
  const { setExpandedGamesTeam, setTeamSchemas, setTeamData } =
    teamReducer.actions;
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [matches, setMatches] = useState<any>([]);
  const [selectedPlayedSchemas, setSelectedPlayedSchemas] = useState<any>(null);
  const [playedSchemas, setPlayedSchemas] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    expandedGameClickedId,
    teamData,
    expandedGames,
    gameRowPerTeamStats,
  } = useSelector((state: AppStateType) => {
    return state.teamReducer;
  });
  const toggleExpanded = (matchId: string) => {
    if (expandedGames.includes(matchId)) {
      dispatch(
        setExpandedGamesTeam([...expandedGames.filter((el) => el !== matchId)]),
      );
    } else {
      dispatch(setExpandedGamesTeam([...expandedGames, matchId]));
    }
  };
  useEffect(() => {
    if (teamId) {
      dispatch(setTeamData([]));
      setSelectedPlayedSchemas(null);
      ClubsAPI.getTeamSchemas(teamId).then((response) => {
        const allSchemas: any = [
          ...response.data.schemas.map((schema: any) => {
            return {
              value: schema.id,
              label: schema.name,
            };
          }),
        ];
        setPlayedSchemas(allSchemas);
        dispatch(setTeamSchemas(allSchemas));
      });
      ClubsAPI.getTeam(teamId, navigate).then((response: any) => {
        response?.data && dispatch(setTeamData(response.data));
      });
    }
  }, [teamId]);
  useEffect(() => {
    if (teamId) {
      setMatches([]);
      setLoading(true);
      EventsAPI.getGameStatsBySeasonV2(
        null,
        teamId,
        selectedPlayedSchemas,
      ).then((matchesStatsResponse) => {
        if (matchesStatsResponse.data) {
          setMatches(matchesStatsResponse.data);
        }
        setLoading(false);
      });
    }
  }, [selectedPlayedSchemas, teamId]);
  useEffect(() => {
    if (expandedGameClickedId !== null) {
      const gameDiv = document.getElementById(expandedGameClickedId.id);
      if (gameDiv) {
        gameDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
      if (expandedGameClickedId.expanded) {
        dispatch(setExpandedGamesTeam([expandedGameClickedId.id]));
      }
    }
  }, [matches]);
  const dispatch = useAppDispatch();
  const populateTeamStatsForGame = (matchId: string) => {
    if (teamId && !Object.keys(gameRowPerTeamStats).includes(matchId)) {
      dispatch(GetMatchStatsPerTeam(matchId, teamId));
    }
  };
  return (
    <div className="block-wrapper left">
      <Flex
        className="block-title"
        gap={16}
        justify="flex-start"
        style={{
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <div className="block-title">
          <div className="block-title-h">{t('Matches')}</div>
        </div>
        <div className="enabledClickable">
          {teamData?.sport !== 2 && playedSchemas.length > 0 && (
            <ConfigProvider
              theme={{
                token: {
                  colorText: 'var(--colorPrimaryBase, #1668DC);',
                },
                components: {
                  Select: {
                    optionSelectedColor:
                      'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                  },
                },
              }}
            >
              <Select
                placeholder={
                  <div className="select-placeholder">
                    {t('All formations')}
                  </div>
                }
                variant="borderless"
                value={selectedPlayedSchemas}
                popupMatchSelectWidth={false}
                onSelect={(value: string) => {
                  if (value === 'clear') {
                    setSelectedPlayedSchemas(null);
                  } else {
                    setSelectedPlayedSchemas(value);
                  }
                }}
                size="small"
                placement="bottomRight"
              >
                <Select.Option
                  style={{
                    mpaddingTop: 10,
                    borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                    borderRaius: 0,
                    color: 'black',
                  }}
                  className="enabledClickable"
                  key={0}
                  onClick={(e: any) => {
                    e.preventDefault();
                    // setSelectedPlayedSchemas(undefined);
                  }}
                  label={''}
                  value={'clear'}
                >
                  {t('Clear filter')}
                </Select.Option>
                {playedSchemas.map((schema: any, index: number) => (
                  <Select.Option
                    key={index + 1}
                    label={schema.value}
                    value={schema.value}
                    style={{
                      color: 'black',
                    }}
                  >
                    {schema.label}
                  </Select.Option>
                ))}
              </Select>
            </ConfigProvider>
          )}
        </div>
      </Flex>
      <Flex gap={8} className="block-content flex-column">
        {matches.map((match: any) => (
          <TeamMatchBlock
            key={match.id}
            match={match}
            isExpanded={expandedGames.includes(match.id)}
            toggleExpanded={toggleExpanded}
            populateTeamStatsForGame={populateTeamStatsForGame}
            is2ndSport={teamData && teamData.sport === 2}
          />
        ))}
        {loading && <MatchSkeleton />}
      </Flex>
    </div>
  );
};
export default MatchesBlock;
