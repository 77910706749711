import { ThunkAction } from 'redux-thunk';

import { AppStateType } from 'reducers';
import { teamMultitoolReducer } from 'reducers/teamMultitool.reducer';

const { setSelectedLastMatch } = teamMultitoolReducer.actions;

export const SetSelectedMultitoolTeamLastMatch =
  (
    count: number | string | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedLastMatch(count));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const GetSingleSeasonSelectedMultitool =
  (): ThunkAction<Promise<string>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { teamStatsMatches, selectedRowKeys } =
      getState().teamMultitoolReducer;
    let seasonId = 'manual';
    let found = true;
    selectedRowKeys !== undefined &&
      teamStatsMatches.forEach((season: any) => {
        if (!found) {
          return;
        }
        season.leagues?.forEach((league: any) => {
          if (league.matches.length === selectedRowKeys.length) {
            const leagueIds = league.matches
              .map((leagueMatch: any) => leagueMatch.id)
              .sort();
            const tableMatchesIds = selectedRowKeys.map((el) => el).sort();
            const e = leagueIds.every((value: string, index: number) => {
              return value === tableMatchesIds[index];
            });
            if (e) {
              found = false;
              seasonId = league.season_id;
              return;
            }
          }
        });
      });
    return seasonId;
  };
