import { createSlice } from '@reduxjs/toolkit';

import { OpenedPlaylistType } from 'types/playlist.types';
type InitialStateType = {
  playlistIsLoading: boolean;
  playlistsAndFoldersLoading: boolean;
  folders: any;
  playlistsAndFolders: any[];
  openedPlaylist: OpenedPlaylistType | null;
  openedPlaylistId: string | null;
  playlistOpenError: string | null;
  selectedPlaylists: any;
  sharedPlaylist: OpenedPlaylistType | null;
  playlistForDeletionId: string | null;
  addToPlaylistPopoverIsOpen: boolean;
  addNewEpisodeToPlaylistPopoverIsOpen: boolean;
};
const initialState: InitialStateType = {
  playlistIsLoading: false,
  playlistsAndFoldersLoading: false,
  folders: [],
  playlistsAndFolders: [],
  openedPlaylist: null,
  openedPlaylistId: null,
  playlistOpenError: null,
  selectedPlaylists: {},
  sharedPlaylist: null,
  playlistForDeletionId: null,
  addToPlaylistPopoverIsOpen: false,
  addNewEpisodeToPlaylistPopoverIsOpen: false,
};
export const playlistReducerV2 = createSlice({
  name: 'playlists',
  initialState: initialState,
  reducers: {
    AC_addSelectedPlaylistElement(state, action) {
      if (
        Object.keys(state.selectedPlaylists).includes(action.payload.gameId)
      ) {
        state.selectedPlaylists[action.payload.gameId].push(
          action.payload.episodeId,
        );
      } else {
        state.selectedPlaylists[action.payload.gameId] = [
          action.payload.episodeId,
        ];
      }
    },
    addUserEpisodeToOpenedPlaylist(state, action) {
      state.openedPlaylist?.playlist_events
        .filter((event) => event.id === action.payload.eventId)[0]
        .episodes.unshift(action.payload.episode);
    },
    AC_removeSelectedPlaylistElement(state, action) {
      if (state.selectedPlaylists[action.payload.gameId].length === 1) {
        delete state.selectedPlaylists[action.payload.gameId];
      } else {
        state.selectedPlaylists[action.payload.gameId] =
          state.selectedPlaylists[action.payload.gameId].filter(
            (episode: string) => episode !== action.payload.episodeId,
          );
      }
      return state;
    },
    AC_setPlaylistIsLoading(state, action) {
      state.playlistIsLoading = action.payload;
    },
    AC_setFoldersList(state, action) {
      state.folders = action.payload;
      state.playlistsAndFoldersLoading = false;
    },
    AC_setPlaylistsAndFolders(state, action) {
      state.playlistsAndFolders = action.payload;
      state.playlistsAndFoldersLoading = false;
    },
    AC_setSharedPlaylist(state, action) {
      state.sharedPlaylist = action.payload;
    },
    AC_setOpenedPlaylistId(state, action) {
      state.openedPlaylistId = action.payload;
    },
    AC_setOpenedPlaylist(state, action) {
      state.openedPlaylist = action.payload;
    },
    AC_toggleCollapsedPlaylistGame(state, action) {
      if (state.openedPlaylist) {
        state.openedPlaylist.playlist_events[action.payload].isExpanded =
          !state.openedPlaylist.playlist_events[action.payload].isExpanded;
      }
    },
    AC_setPlaylistOpenError(state, action) {
      state.playlistOpenError = action.payload;
    },
    AC_removeGameFromPlaylist(state, action) {
      if (state.openedPlaylist) {
        state.openedPlaylist.playlist_events =
          state.openedPlaylist.playlist_events.filter(
            (game: any) => game.id === action.payload,
          );
      }
      return state;
    },
    AC_removeEpisodeFromGame(state, action) {
      if (state.openedPlaylist) {
        const game = state.openedPlaylist.playlist_events.filter(
          (g: any) => g.id === action.payload.gameId,
        )[0];
        // console.log('sss', action.payload);
        game.episodes = game.episodes.filter(
          (episode: any) => episode.id !== action.payload.episodeId,
        );
        if (game.episodes.length === 0) {
          state.openedPlaylist.playlist_events =
            state.openedPlaylist.playlist_events.filter(
              (g: any) => g.id !== action.payload.gameId,
            );
        }
      }
      return state;
    },
    setPlaylistsAndFoldersLoading(state, action) {
      state.playlistsAndFoldersLoading = action.payload;
    },
    setPlaylistForDeletionId(state, action) {
      state.playlistForDeletionId = action.payload;
    },
    setAddToPlaylistPopoverIsOpen(state, action) {
      state.addToPlaylistPopoverIsOpen = action.payload;
    },
    setAddNewEpisodeToPlaylistPopoverIsOpen(state, action) {
      state.addNewEpisodeToPlaylistPopoverIsOpen = action.payload;
    },
  },
});
