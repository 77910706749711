/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import {
  Tooltip as ATooltip,
  Button,
  ConfigProvider,
  Flex,
  Popover,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { getMatchWord } from 'utils/functions';

import { TeamGamesSelectorMultitoolPopoverContent } from '../TeamStatisticsV2/TeamGamesSelectorMulitoolPopover';
import './index.css';

const MatchesSelector = ({
  vizRef,
  open,
  setOpen,
  setSelectedSeasonAction,
  setSelectedLastMatchCallback,
  reducer,
  reducerName,
  getSingleSeasonAction,
}: {
  vizRef: any;
  open: boolean;
  setOpen: any;
  setSelectedSeasonAction: any;
  setSelectedLastMatchCallback: any;
  reducer: any;
  reducerName: string;
  getSingleSeasonAction: any;
}) => {
  const dispatch = useAppDispatch();
  const [isLong, setIsLong] = useState<boolean>(false);
  const updateStats = (data: any) => {
    dispatch(setSelectedSeasonAction(data));
  };
  const PopoverHandlerRef = React.createRef<any>();
  const hide = () => {
    dispatch(setOpen(false));
  };
  const [t] = useTranslation();
  const handleOpenChange = (newOpen: boolean) => {
    dispatch(setOpen(newOpen));
  };
  const {
    selectedTournaments,
    selectedRowKeys,
    tableMatches,
    appliedLastMatch,
  } = useSelector((state: any) => {
    return state[reducerName];
  });
  const [selectedSeasonLabel, setSelectedSeasonLabel] = useState<string>();
  const constructMatchesString = () => {
    if (selectedRowKeys) {
      const matcheString = `${selectedRowKeys?.length} ${t(getMatchWord(selectedRowKeys?.length?.toString()))}`;
      if (selectedRowKeys?.length === 0) {
        return matcheString;
      }
      if (selectedTournaments.length === 1) {
        return `(${matcheString})`;
      }
      return matcheString;
    }
    if (selectedRowKeys === undefined) {
      return;
    } else {
      return 0;
    }
  };
  return (
    <Popover
      placement="left"
      content={
        <TeamGamesSelectorMultitoolPopoverContent
          setSelectedSeason={updateStats}
          reducer={reducer}
          reducerName={reducerName}
          hide={hide}
          isOpen={open}
          vizRef={vizRef}
          firstSeason={tableMatches.length > 0 ? `${tableMatches[0].name}` : ''}
          getSingleSeasonAction={getSingleSeasonAction}
        />
      }
      open={open}
      align={{ offset: [-10, 70] }}
      overlayClassName="games-popover-overlay"
      trigger="contextMenu"
      onOpenChange={handleOpenChange}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              paddingInline: 16,
              colorPrimaryHover: 'rgba(9, 88, 217, 1)',
              colorPrimaryBorderHover: 'rgba(9, 88, 217, 1)',
              colorPrimaryTextHover: 'rgba(9, 88, 217, 1)',
            },
          },
        }}
      >
        <Flex
          className="block-title"
          align="center"
          onClick={() => {
            open && hide();
          }}
        >
          <Flex gap={20} align="center" flex={1} justify="flex-start">
            <div
              ref={PopoverHandlerRef}
              className="playerStatsPopoverHandler"
              style={{
                color: 'var(--colorTextLabel, rgba(0, 0, 0, 0.65))',
              }}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                dispatch(setOpen(!open));
                dispatch(setSelectedLastMatchCallback(appliedLastMatch));
              }}
            >
              {isLong && (
                <ATooltip
                  title={
                    tableMatches.filter(
                      (s: any) => s.name === selectedSeasonLabel,
                    )[0]?.name
                  }
                  arrow={true}
                >
                  <div>
                    {
                      tableMatches.filter(
                        (s: any) => s.name === selectedSeasonLabel,
                      )[0]?.name
                    }
                  </div>
                </ATooltip>
              )}
              {!isLong && (
                <Button
                  style={{ minWidth: 154 }}
                  className={open ? 'active-button' : ''}
                >
                  {selectedTournaments.length === 1
                    ? selectedTournaments[0].split('-')[1]
                    : ''}
                  &nbsp;{constructMatchesString()}
                </Button>
              )}
            </div>
          </Flex>
        </Flex>
      </ConfigProvider>
    </Popover>
  );
};
export default MatchesSelector;
