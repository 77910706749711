import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  ConfigProvider,
  Divider,
  Flex,
  Input,
  List,
  Popover,
  Segmented,
  Table,
  TableProps,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { PlaylistsAPI } from 'api/playlists';
import { ReactComponent as CloseIcon } from 'assets/img/icons/faCloseCrossWhite.svg';
import { ReactComponent as CloseCross } from 'assets/img/icons/faCloseCrossWhite.svg';
import { ReactComponent as PlusIconGrey } from 'assets/img/icons/faPlusGrey.svg';
import { ReactComponent as PlusIcon } from 'assets/img/icons/faPlusSmall.svg';
import { ReactComponent as SearchIcon } from 'assets/img/icons/faSearchGreyIcon.svg';
// import { ReactComponent as UserIcon } from 'assets/img/icons/faUserIcon.svg';
import { useAppDispatch } from 'store';

import { ReactComponent as CheckboxFilled } from '../../../assets/img/icons/faCheckboxFilled.svg';

interface DataType {
  key: string;
  name: string;
}

const SelectPlaylistPopoverContent = ({
  wrappedComponent,
  closeAddEpisodeModeCallback,
  addUserEpisodeToPlaylist,
  offset,
  isOpen,
  setIsOpen,
  hideArrow = false,
  passPlaylist = false,
}: {
  wrappedComponent: any;
  closeAddEpisodeModeCallback: any;
  addUserEpisodeToPlaylist: any;
  offset: any;
  isOpen: boolean;
  setIsOpen: any;
  hideArrow: boolean;
  passPlaylist: boolean;
}) => {
  const [t] = useTranslation();
  const [playlists, setPlaylists] = useState([]);
  const [selectMode, setSelectMode] = useState('single');
  const [selectedPlaylists, setSelectedPlaylists] = useState<React.Key[]>([]);
  const [addPlaylistMode, setAddPlaylistMode] = useState(false);
  const [playlistCreating, setPlalistCreating] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState(null);
  const popoverRef = useRef<any>(null);
  useEffect(() => {
    if (newPlaylistName === '') {
      setSelectedPlaylists((prev: any[]) => prev.filter((el) => el !== null));
    }
  }, [newPlaylistName]);
  const [inputStatus, setInputStatus] = useState<any>('');
  const populatePlaylists = async () => {
    PlaylistsAPI.getPlaylists({ flat: true }).then((response) => {
      const data = response.data.map((p: any) => {
        return {
          key: p.key,
          value: p.key,
          name: p.title,
          qty: p.episodes_count,
        };
      });
      // console.log(data);
      setPlaylists(data);
    });
  };
  const createNewPlaylist = async () => {
    setPlalistCreating(true);
    const newPlaylistResponse = await PlaylistsAPI.addPlaylist(
      newPlaylistName || '',
    );
    if (newPlaylistResponse?.status === 'error') {
      setInputStatus('error');
    } else {
      await populatePlaylists();
      setAddPlaylistMode(false);
    }
    setNewPlaylistName(null);
    setPlalistCreating(false);
  };
  const columns: TableProps<DataType>['columns'] = [
    { key: 'name', dataIndex: 'name', ellipsis: true },
    {
      key: 'qty',
      dataIndex: 'qty',
      width: 40,
      render: (value) => <div className="playlist-qty-value">{value}</div>,
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedPlaylists(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys: selectedPlaylists,
    onChange: onSelectChange,
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    setSelectMode('single');
    populatePlaylists();
  }, [isOpen]);
  const selectRow = (record: any) => {
    const selectedRowKeys = [...selectedPlaylists];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    setSelectedPlaylists(selectedRowKeys);
  };
  const addToPlaylists = (playlists: any[]) => {
    addUserEpisodeToPlaylist(playlists);
    closeAddEpisodeModeCallback();
  };
  const handleOpenChange = (newOpen: boolean) => {
    dispatch(setIsOpen(newOpen));
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Popover: {
            marginXS: 0,
            borderRadiusLG: 16,
          },
          Button: {
            textHoverBg: 'rgba(255, 255, 255, 0.12)',
          },
        },
        token: {
          boxShadowSecondary: '',
        },
      }}
    >
      <Popover
        ref={popoverRef}
        placement="top"
        open={isOpen}
        align={{ offset: offset }}
        color="var(--colorBgLayout, #292D32)"
        arrow={hideArrow ? false : { pointAtCenter: true }}
        overlayClassName="playlists-add-popover-overlay"
        trigger="contextMenu"
        title={
          <Flex
            gap={26}
            align="center"
            style={{ margin: '16px 24px 8px 24px' }}
            justify="space-between"
          >
            <Flex flex={1} gap={24} justify="space-between" align="center">
              <span
                style={{
                  flex: 1,
                  color: 'var(--colorText, rgba(255, 255, 255, 0.85))',
                  lineHeight: '24px',
                }}
              >
                {t('Add to playlists')}
              </span>
              {/* <UserIcon className="enabledClickable" /> */}
              <SearchIcon className="enabledClickable" />
            </Flex>
            <CloseIcon
              className="enabledClickable"
              onClick={() => dispatch(setIsOpen(false))}
            />
          </Flex>
        }
        content={
          <Flex
            className="pop"
            style={{
              flex: 1,
            }}
            flex={1}
            vertical
          >
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: 'transparent',
                  colorText: 'rgba(255, 255, 255, 0.85)',
                  colorSplit: 'rgba(255, 255, 255, 0.06)',
                  colorBorder: '#424242',
                },
                components: {
                  Segmented: {
                    trackBg: 'var(--segmentedGroupBg, #000);',
                    itemSelectedColor:
                      'var(--colorText, rgba(255, 255, 255, 0.85))!important',
                    itemColor:
                      'var(--colorText, rgba(255, 255, 255, 0.85))!important',
                    itemHoverColor:
                      'var(--colorText, rgba(255, 255, 255, 0.85))',
                    itemSelectedBg: 'var(--segmentedBgColorSelected, #1F1F1F)',
                    // itemActiveBg: 'var(--segmentedBgColorSelected, #1F1F1F)',
                  },
                  Divider: {},
                },
              }}
            >
              <div style={{ padding: '8px 12px 6px 12px' }}>
                <Segmented
                  color="black"
                  block
                  value={selectMode}
                  onChange={setSelectMode}
                  style={{ width: '100%' }}
                  options={[
                    { label: t('Single playlist'), value: 'single' },
                    { label: t('Multiple playlists'), value: 'multiple' },
                  ]}
                />
              </div>
              <Flex
                style={{
                  width: '100%',
                  // maxHeight: '100%',
                  maxHeight:
                    'calc(100vh - 16px - 56px - 140px - 48px - 46px - 42px + 40px)',
                  // flex: 1,
                }}
                justify="space-between"
                vertical
              >
                <div
                  style={{
                    overflow: 'hidden auto',
                  }}
                >
                  {selectMode === 'single' && (
                    <List
                      style={{
                        margin: '0px 24px 0px 24px',
                      }}
                      itemLayout="horizontal"
                      size="small"
                      split
                      dataSource={playlists}
                      renderItem={(item: any) => (
                        <Flex
                          onClick={() => {
                            if (passPlaylist) {
                              addToPlaylists([item]);
                            } else {
                              addToPlaylists([item.value]);
                            }
                          }}
                          justify="space-between"
                          align="center"
                          className="single-playlist-add-item"
                          style={{
                            height: 39,
                            borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
                          }}
                          gap={16}
                        >
                          <div
                            className="elipsis-text"
                            style={{ flex: 1, maxWidth: 272 }}
                          >
                            {item.name}
                          </div>
                          <div className="playlist-qty-value">{item.qty}</div>
                          <PlusIconGrey className="playlist-qty-plus" />
                        </Flex>
                      )}
                    />
                  )}
                  {selectMode === 'multiple' && (
                    <ConfigProvider
                      theme={{
                        components: {
                          Table: {
                            borderColor: 'rgba(255, 255, 255, 0.06)',
                            cellPaddingInlineSM: 0,
                            rowSelectedBg: 'transparent',
                            rowSelectedHoverBg: 'transparent',
                            // cellPaddingBlock: 8,
                            paddingXS: 4,
                            paddingXXS: 4,
                            cellPaddingInline: 6,
                          },
                        },
                      }}
                    >
                      <Table
                        style={{
                          margin: '0px 24px 0px 16px',
                        }}
                        rowSelection={rowSelection}
                        size="small"
                        showHeader={false}
                        dataSource={playlists}
                        pagination={false}
                        columns={columns}
                        onRow={(record: any) => ({
                          onClick: () => {
                            selectRow(record);
                          },
                        })}
                        rowClassName="add-to-playlist-row"
                      />
                    </ConfigProvider>
                  )}
                </div>
                <Flex vertical>
                  <Divider
                    style={{
                      borderColor: 'rgba(255, 255, 255, 0.06)',
                      margin: '0px 0px 4px -12px',
                      width: '120%',
                    }}
                  />
                  {addPlaylistMode ? (
                    <Flex
                      gap={4}
                      align="center"
                      justify="center"
                      style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.04)',
                        borderRadius: 8,
                        height: 38.51,
                        margin: '0px 8px 0px 8px',
                        padding: '0.8px 8px 0.7px 4px',
                      }}
                    >
                      <ConfigProvider
                        theme={{
                          token: {
                            colorTextPlaceholder: 'var(--colorTextPlaceholder)',
                            fontFamily: 'SFProTextRegular',
                            fontSize: 14,
                            colorBorder: 'var(--colorBorder)',
                          },
                          components: {
                            Input: {
                              activeShadow: '0 0 0 2px rgba(17, 37, 69, 1)',
                            },
                          },
                        }}
                      >
                        <Input
                          disabled={playlistCreating}
                          required={true}
                          status={inputStatus}
                          className="new-folder-input"
                          type="text"
                          onFocus={() => {
                            dispatch(AC_SetPlayerHotkeysAllowedAction(false));
                          }}
                          name="newPlaylistName"
                          onChange={(event: any) => {
                            setNewPlaylistName(event.target.value);
                          }}
                          onBlur={() => {
                            dispatch(AC_SetPlayerHotkeysAllowedAction(true));
                          }}
                          autoFocus={true}
                          maxLength={100}
                          height={32}
                          placeholder={t('Enter playlist name')}
                          style={{
                            background: 'var(--colorBgContainer)',
                            color: 'var(--colorText)',
                            height: 32,
                            margin: 'auto',
                            // marginLeft: 4,
                          }}
                        ></Input>
                        <Button
                          disabled={playlistCreating}
                          style={{
                            border: '1px solid var(--colorIcon)',
                            background: 'transparent',
                            height: 24,
                            width: 32,
                            lineHeight: 0,
                          }}
                          className="bnt-line-height"
                          onClick={() => {
                            setNewPlaylistName(null);
                            setAddPlaylistMode(false);
                            setPlalistCreating(false);
                            dispatch(AC_SetPlayerHotkeysAllowedAction(true));
                          }}
                          icon={<CloseCross />}
                        />
                        <Button
                          disabled={playlistCreating}
                          style={{
                            height: 24,
                            width: 32,
                            border: '1px solid var(--colorPrimaryBase)',
                            background: 'rgba(22, 104, 220, 0.4)',
                          }}
                          className="bnt-line-height"
                          onClick={() => createNewPlaylist()}
                          icon={<CheckboxFilled />}
                          type="default"
                        />
                      </ConfigProvider>
                    </Flex>
                  ) : (
                    <Flex
                      className="enabledClickable"
                      gap={8}
                      align="center"
                      style={{
                        marginTop: 4,
                        marginLeft: 24,
                        marginRight: 24,
                        paddingBottom: 14,
                        color: 'rgba(255, 255, 255, 0.85)',
                      }}
                      onClick={() => {
                        setAddPlaylistMode(true);
                      }}
                    >
                      <PlusIcon width={12} height={12} />
                      {t('Create playlist')}
                    </Flex>
                  )}
                  {selectedPlaylists.length ? (
                    <Flex
                      className="enabledClickable"
                      gap={8}
                      align="center"
                      style={{
                        marginTop: 4,
                        marginLeft: 24,
                        marginRight: 24,
                        paddingBottom: 14,
                        color: 'rgba(255, 255, 255, 0.85)',
                      }}
                    >
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              defaultBg: '#141414',
                              colorText: 'var(--white)',
                              colorBorder: 'var(--colorBorder)',
                              defaultHoverBg: 'var(--colorBgContainer)',
                              defaultActiveBg: 'var(--colorBgContainer)',
                              paddingBlockLG: 16,
                              primaryShadow:
                                '0px 2px 0px 0px rgba(255, 255, 255, 0.04)',
                            },
                          },
                        }}
                      >
                        <Button
                          style={{
                            width: '33%',
                          }}
                          onClick={() => setSelectedPlaylists([])}
                        >
                          {t('Cancel')}
                        </Button>
                      </ConfigProvider>
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              defaultBg: 'var(--colorPrimaryBase)',
                              colorText: 'var(--white)',
                              colorBorder: 'var(--colorBorder)',
                              defaultHoverBg: 'var(--colorBgContainer)',
                              defaultActiveBg: 'var(--colorBgContainer)',
                              paddingBlockLG: 16,
                              primaryShadow: '0 2px 0 rgba(5, 145, 255, 0.1)',
                            },
                          },
                        }}
                      >
                        <Button
                          style={{
                            width: '66%',
                          }}
                          type="primary"
                          onClick={() => {
                            addToPlaylists(
                              playlists.filter((el: any) =>
                                selectedPlaylists.includes(el.key),
                              ),
                            );
                            setSelectedPlaylists([]);
                          }}
                        >
                          {t('Add into')} {selectedPlaylists.length}
                        </Button>
                      </ConfigProvider>
                    </Flex>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Flex>
            </ConfigProvider>
          </Flex>
        }
        onOpenChange={handleOpenChange}
      >
        {wrappedComponent}
      </Popover>
    </ConfigProvider>
  );
};
export default SelectPlaylistPopoverContent;
