/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import './index.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PeriodLabel from 'components/PeriodLabel';
import { AppStateType } from 'reducers';
import { WINDOW_MODE_WIDTH } from 'types/constants';
import { formatMillisecondsToTime } from 'types/functions';
import { EpisodeTypes } from 'types/types';

const EpisodeFloatingLabel = () => {
  const [t] = useTranslation();
  const [periodName, setPeriodName] = useState<any>('');
  const [startTime, setStartTime] = useState<any>('');
  const [finishTime, setFinishTime] = useState<any>('');
  const { playedEpisode } = useSelector((state: AppStateType) => {
    return {
      playedEpisode: state.playerReducerV2.playedEpisode,
    };
  });
  const renderPeriodName = (name: string) => {
    if (name.slice(-1) === '1' || name.slice(-1) === '2') {
      return (
        <>
          {Array.from(name)[0]}
          <sup style={{ fontSize: 7, lineHeight: '10px' }}>{name.slice(1)}</sup>
        </>
      );
    }
    return name;
  };
  const initTimerWithFormat = (
    timerType: string,
    useGameTimer = false,
    useFileTimer = false,
  ) => {
    let timer = 0;
    if (useFileTimer) {
      timer = playedEpisode?.file_timer[timerType];
    } else {
      if (useGameTimer) {
        timer = playedEpisode?.game_timer[timerType];
      } else if (
        playedEpisode?.file_timer &&
        playedEpisode?.file_timer[timerType] >= 0
      ) {
        timer = playedEpisode?.file_timer[timerType];
      }
    }
    const hasUserTimer = Boolean(playedEpisode?.user_timer);
    let windowModeOffset = WINDOW_MODE_WIDTH;
    if (playedEpisode?.episode_type === EpisodeTypes.period) {
      windowModeOffset = 0;
    }
    if (
      playedEpisode?.user_timer ||
      playedEpisode?.episode_type === EpisodeTypes.user_episode
    ) {
      windowModeOffset = 0;
    }
    if (timerType === 'started_at') {
      if (!hasUserTimer) {
        if (timer >= windowModeOffset * 1000) {
          timer -= windowModeOffset * 1000;
        } else {
          timer = 0;
        }
      }
    } else {
      if (!hasUserTimer) {
        if (
          Number(playedEpisode?.video.meta.video_length) * 1000 - timer >
          windowModeOffset * 1000
        ) {
          timer += windowModeOffset * 1000;
        } else {
          timer = Number(playedEpisode?.video.meta.video_length) * 1000;
        }
      }
    }
    return formatMillisecondsToTime(timer);
  };
  const renderTimeElement = (value: string) => {
    if (playedEpisode?.event_type?.activity_type !== null) {
      return <span>{value}</span>;
    }
    return value;
  };
  useEffect(() => {
    playedEpisode?.period_name &&
      setPeriodName(renderPeriodName(t(playedEpisode?.period_name)));
    setStartTime(renderTimeElement(initTimerWithFormat('started_at', true)));
    setFinishTime(renderTimeElement(initTimerWithFormat('finished_at', true)));
  }, [playedEpisode]);

  return (
    <div className="floating-episode-label">
      <div className={'flex-row ai-c f-ga-8'}>
        <PeriodLabel name={periodName} isLight={true} />
        <div>
          {startTime}
          &nbsp;-&nbsp;
          {finishTime}
        </div>
      </div>
    </div>
  );
};
export default EpisodeFloatingLabel;
