import React, { FC, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';

// import BestPlayersBlock from './BestPlayersBlock';
import './index.css';
import TeamDataBlock from './TeamDataBlock';
import TeamEpisodesSearchController from './TeamEpisodesSearchController';
import TeamHeaderV2 from './TeamHeaderV2';
import TeamPlayersTableController from './TeamPlayersTableController';
import TableauController from './TeamTableauController';
import {
  AC_AddSelectedTournamentFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_SetLastXFilterAction,
  AC_SetSelectedAttributes,
} from '../../actions/filter.actions';
import { setStyleModeAction } from '../../actions/interface.actions';
import {
  AC_GetVideoEpisodesSuccess,
  AC_SetActiveVideoAction,
  AC_SetSelectedGames,
  AC_SetVisibleRangeAction,
} from '../../actions/player.acitons';
import { useAppDispatch } from '../../store';
import GameProtocolSidePanel from '../GameProtocolSidePanel';
import GamesSelectionModal from '../modals/GamesSelectionModal';

export const TeamPageController: FC<any> = () => {
  const dispatch = useAppDispatch();
  const { setStatsCustomGameList, setTeamProfileMode } = teamReducer.actions;
  const { resetReelsState } = reelsReducer.actions;
  const { setOpenedPlayer } = teamPlayerReducer.actions;
  const { resetEditMode } = playerReducerV2.actions;
  const { setSelectedLastMatch_stats, setSelectedMetricFilters } =
    teamPlayerStatsSectionReducer.actions;
  const { teamProfileMode } = useSelector((state: AppStateType) => {
    return state.teamReducer;
  });
  const [wasLoaded, setWasLoaded] = useState(false);
  const { teamId } = useParams();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (teamProfileMode === 'multitool') {
      setWasLoaded(true);
    }
  }, [teamProfileMode]);
  useEffect(() => {
    dispatch(setStyleModeAction('light'));
    dispatch(setStatsCustomGameList([]));
    dispatch(AC_GetVideoEpisodesSuccess([]));
    dispatch(AC_SetLastXFilterAction(null));
    dispatch(AC_SetSelectedAttributes([]));
    dispatch(AC_AddSelectedTournamentFilterAction([]));
    dispatch(AC_SetSelectedGames([]));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(resetEditMode());
    dispatch(setSelectedLastMatch_stats(''));
    dispatch(setSelectedMetricFilters([]));
    dispatch(AC_SetVisibleRangeAction([]));
    dispatch(resetReelsState());
    dispatch(setOpenedPlayer(null));
    window.document.body.setAttribute('data-theme', 'light');
  }, []);
  useEffect(() => {
    if (
      searchParams.get('teamProfileMode') === 'overview' ||
      !searchParams.get('teamProfileMode')
    ) {
      dispatch(setTeamProfileMode('overview'));
    } else if (searchParams.get('teamProfileMode') === 'players') {
      dispatch(setTeamProfileMode('players'));
    }
  }, [teamId]);
  const vizRef = useRef<any>();
  return (
    <>
      <div className="team-page-controller">
        <TeamHeaderV2 vizRef={vizRef} />
        <div
          style={{
            display: teamProfileMode === 'overview' ? 'block' : 'none',
            width: '100%',
          }}
        >
          <TeamDataBlock />
        </div>
        <div
          style={{
            display: teamProfileMode === 'players' ? 'block' : 'none',
            width: '100%',
          }}
        >
          <TeamPlayersTableController />
        </div>
        <div
          style={{
            display: teamProfileMode === 'search' ? 'block' : 'none',
            width: '100%',
          }}
        >
          <TeamEpisodesSearchController />
        </div>
        <div
          style={{
            display: teamProfileMode === 'multitool' ? 'block' : 'none',
            width: '100%',
          }}
        >
          {wasLoaded && (
            <TableauController
              teamProfileMode={teamProfileMode}
              vizRef={vizRef}
            />
          )}
        </div>
      </div>
      <GameProtocolSidePanel />
      <GamesSelectionModal />
    </>
  );
};
