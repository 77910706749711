import { ThunkAction } from 'redux-thunk';

import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';

const { setGameEventCoordinates } = teamPlayerReducer.actions;

export const GetMatchPlayerEventCoordinates =
  (
    matchId: string,
    metrics: string[],
    result: string[] = ['all'],
    playerId: string,
    match?: any,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    const response = await EventsAPI.getPlayerGameEventCoordinates(
      matchId,
      metrics,
      playerId,
      result,
    );
    match.stats.map((stat: any) => {
      if (stat.value) {
        if (stat.details) {
          if (stat.details.success.value) {
            dispatch(
              setGameEventCoordinates({
                matchId: matchId,
                result: 'success',
                metric: stat.key,
                coordinates: response.data[stat.details.success.key],
              }),
            );
          }
          if (stat.details.fail.value) {
            dispatch(
              setGameEventCoordinates({
                matchId: matchId,
                result: 'fail',
                metric: stat.key,
                coordinates: response.data[stat.details.fail.key],
              }),
            );
          }
        } else {
          dispatch(
            setGameEventCoordinates({
              matchId: matchId,
              result: 'all',
              metric: stat.key,
              coordinates: response.data[stat.key],
            }),
          );
        }
      }
    });
  };
