import React, { useState } from 'react';

import { Divider, Flex, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import MetricFilter from 'components/PlayerPageController/PlayerStatisticsBlock/MetricFilter';
import MetricPlayerButtonWithPercent from 'components/TeamPageController/MetricPlayerButtonWIthPercent';
import { AppStateType } from 'reducers';

import { ReactComponent as DownOutlinedBlack } from '../../../../assets/img/icons/faDownOutlinedBlack.svg';
import AverageStatTeamValueTag from '../AverageStatTeamValueTag';

const PerTeamStatistics = ({
  teamStats,
  teamValuesStatsMode,
}: {
  teamStats: any[];
  teamValuesStatsMode: string;
}) => {
  const [t] = useTranslation();
  const { teamData, statsState } = useSelector((state: AppStateType) => {
    return state.teamReducer;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSeason, setSelectedSeason] = useState<string>();
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const toggleSelectedMetric = (
    metricString: string,
    removedMetricString: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev: any) =>
          prev.filter((el: any) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
      } else {
        setSelectedMetricFilters((prev: any) => [
          ...prev.filter((p: any) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
  };
  const makeLink = (matches: any[], team?: any, stats?: any[], part?: any) => {
    let link = '/player?';
    let added = false;
    matches.forEach((match) => {
      if (match.id) {
        link = `${link}${added ? '&' : ''}eventId=${match.id}`;
        added = true;
      }
    });
    if (team) {
      if (part === 'highlights') {
        link = `${link}${added ? '&' : ''}teamId=${team?.id}`;
        added = true;
      } else {
        link = `${link}${added ? '&' : ''}teamId=${team?.id}`;
        added = true;
      }
    }
    link = `${link}${added ? '&' : ''}season=${selectedSeason}`;
    added = true;
    if (stats?.length) {
      stats.forEach((stat: any) => {
        link = `${link}${added ? '&' : ''}m=${stat.key}`;
        added = true;
      });
    }
    link = `${link}&pl=${teamData.id}`;
    return link;
  };
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const makeLinkAndNavigate = (stat: any) => {
    navigate(makeLink([{ id: null }], teamData, [stat]));
  };
  const onMousEnter = (e: any, stat: any) => {
    e.preventDefault();
    if (stat?.percent !== null && stat?.percent !== undefined) {
      setHoveredMetricFilters((prev) => [...prev, stat.key]);
    }
  };
  const showStat = (stat: any) => {
    return (
      teamData &&
      (stat.for_sport?.length === 0 ||
        (stat.for_sport?.length && stat.for_sport.includes(teamData.sport))) &&
      !stat.excluded_sports?.includes(teamData.sport)
    );
  };
  // true && (false || (true && false) && true)
  return (
    <>
      <Flex
        className="team-profile-block-column-title f-ga-0"
        style={{ paddingBottom: 4 }}
        justify="space-between"
        gap={0}
      >
        <div style={{ flex: 1 }} className="stats-header-v2">
          {t('Actions')}
        </div>
        <Flex gap={24} style={{ flex: 1 }}>
          <div style={{ minWidth: 130, flex: 1 }} className="stats-header-v2">
            {teamData.name}
          </div>
          <div className="stats-header-v2" style={{ minWidth: 130, flex: 1 }}>
            {t('Opponents')}
          </div>
        </Flex>
      </Flex>
      <Flex
        vertical
        // gap={24}
        style={{ margin: '0 12px 8px 20px', alignSelf: 'stretch' }}
      >
        {teamStats
          ?.filter((stat) => showStat(stat))
          .map((stat: any, index: number) => {
            return (
              <div key={index}>
                <Flex
                  justify="flex-start"
                  style={{ width: '100%', margin: '4px 0px' }}
                >
                  <Flex
                    justify="space-between"
                    style={{ width: '100%' }}
                    gap={8}
                  >
                    <Flex
                      style={{
                        marginLeft: 0,
                        marginRight: 8,
                        overflow: 'hidden',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      align="center"
                      justify="center"
                      gap={8}
                      onMouseEnter={(e: any) => {
                        onMousEnter(e, stat.stat || stat);
                      }}
                      onMouseLeave={() => {
                        setHoveredMetricFilters((prev) =>
                          prev.filter((el: string) => el !== stat.key),
                        );
                      }}
                    >
                      <Flex gap={8} align="center">
                        <div>{stat.label}</div>
                        {stat.children?.length && (
                          <DownOutlinedBlack
                            className={`enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                            onClick={() => {
                              if (
                                stat.children?.length &&
                                expandedStats.includes(stat.key)
                              ) {
                                setExpandedStats((prev) =>
                                  prev.filter(
                                    (item: string) => item !== stat.key,
                                  ),
                                );
                              } else {
                                setExpandedStats((prev) => [...prev, stat.key]);
                              }
                            }}
                          />
                        )}
                      </Flex>
                      <MetricFilter
                        asPoint={false}
                        metric={stat.key}
                        selectedMetricFilters={selectedMetricFilters}
                        hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                          stat.key,
                        )}
                        toggleSelectedMetric={toggleSelectedMetric}
                        reverseMatchColor={stat.reverse_match_color}
                      />
                    </Flex>
                    <Flex gap={24}>
                      {statsState === 'loading' ||
                      stat === undefined ||
                      stat.stat === undefined ? (
                        <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                          <Skeleton.Button
                            active
                            style={{ minWidth: 130, minHeight: 32 }}
                          />
                        </div>
                      ) : (
                        <div style={{ minWidth: 130, minHeight: 32 }}>
                          {teamValuesStatsMode === 'sum' ? (
                            <MetricPlayerButtonWithPercent
                              statKey={stat.key}
                              stat={stat.stat || stat}
                              indexStats={index}
                              teamId={teamData.id}
                              teamType={'own_team'}
                              selectedMetricFilters={selectedMetricFilters}
                            />
                          ) : (
                            <AverageStatTeamValueTag
                              statKey={stat.key}
                              teamId={teamData.id}
                              teamType={'own_team'}
                              stat={stat.stat || stat}
                              selectedMetricFilters={selectedMetricFilters}
                            />
                          )}
                        </div>
                      )}
                      {statsState === 'loading' ||
                      stat === undefined ||
                      stat.stat === undefined ? (
                        <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                          <Skeleton.Button
                            active
                            style={{ minWidth: 130, minHeight: 32 }}
                          ></Skeleton.Button>
                        </div>
                      ) : (
                        <div style={{ minWidth: 130, minHeight: 32 }}>
                          {teamValuesStatsMode === 'sum' ? (
                            <MetricPlayerButtonWithPercent
                              statKey={stat.key}
                              stat={stat.stat || stat}
                              indexStats={index}
                              teamId={teamData.id}
                              teamType={'opponents'}
                              selectedMetricFilters={selectedMetricFilters}
                            />
                          ) : (
                            <AverageStatTeamValueTag
                              statKey={stat.key}
                              teamId={teamData.id}
                              teamType={'opponents'}
                              stat={stat.stat || stat}
                              selectedMetricFilters={selectedMetricFilters}
                            />
                          )}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                {stat?.children?.length > 0 && (
                  <Flex
                    vertical
                    style={{ width: '100%', margin: '4px 0px' }}
                    gap={8}
                    className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
                  >
                    {stat.children
                      ?.filter((stat: any) => showStat(stat))
                      .map((child: any, childIndex: number) => {
                        return statsState === 'loading' ||
                          child === undefined ||
                          child.stat === undefined ? (
                          <Flex
                            key={childIndex}
                            justify="space-between"
                            style={{ width: '100%', paddingLeft: 24 }}
                            align="center"
                          >
                            <div>{child.label}</div>
                            <MetricFilter
                              asPoint={false}
                              metric={child.key}
                              selectedMetricFilters={selectedMetricFilters}
                              hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                                child.key,
                              )}
                              toggleSelectedMetric={toggleSelectedMetric}
                              reverseMatchColor={child.reverse_match_color}
                            />
                            <Flex gap={24}>
                              <div
                                style={{ borderRadius: 8, overflow: 'hidden' }}
                              >
                                <Skeleton.Button
                                  active
                                  style={{ minWidth: 130, minHeight: 32 }}
                                ></Skeleton.Button>
                              </div>
                              <div
                                style={{ borderRadius: 8, overflow: 'hidden' }}
                              >
                                <Skeleton.Button
                                  active
                                  style={{ minWidth: 130, minHeight: 32 }}
                                ></Skeleton.Button>
                              </div>
                            </Flex>
                          </Flex>
                        ) : (
                          <Flex
                            key={childIndex}
                            style={{
                              width: '100%',
                              justifyContent: 'space-between',
                            }}
                            justify="center"
                            align="center"
                            gap={16}
                            onMouseEnter={(e: any) => {
                              onMousEnter(e, child.stat);
                            }}
                            onMouseLeave={() => {
                              setHoveredMetricFilters((prev) =>
                                prev.filter((el: string) => el !== child.key),
                              );
                            }}
                          >
                            <Flex
                              style={{
                                width: '100%',
                              }}
                              justify="space-between"
                            >
                              <Flex
                                style={{
                                  marginLeft: 20,
                                  overflow: 'hidden',
                                }}
                                align="center"
                                justify="center"
                                gap={8}
                              >
                                {child.label}
                              </Flex>
                              <MetricFilter
                                asPoint={false}
                                metric={child.key}
                                selectedMetricFilters={selectedMetricFilters}
                                hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                                  child.key,
                                )}
                                toggleSelectedMetric={toggleSelectedMetric}
                                reverseMatchColor={child.reverse_match_color}
                              />
                            </Flex>
                            <Flex gap={24}>
                              <div style={{ minWidth: 130, minHeight: 32 }}>
                                {teamValuesStatsMode === 'sum' ? (
                                  <MetricPlayerButtonWithPercent
                                    statKey={child.key}
                                    stat={child?.stat?.stat || child?.stat}
                                    indexStats={childIndex}
                                    teamId={teamData.id}
                                    teamType={'own_team'}
                                    selectedMetricFilters={
                                      selectedMetricFilters
                                    }
                                  />
                                ) : (
                                  <AverageStatTeamValueTag
                                    statKey={
                                      child?.stat?.stat
                                        ? child?.stat.stat.key
                                        : child?.stat.key
                                    }
                                    teamId={teamData.id}
                                    teamType={'own_team'}
                                    stat={child?.stat?.stat || child?.stat}
                                    selectedMetricFilters={
                                      selectedMetricFilters
                                    }
                                  />
                                )}
                              </div>
                              <div style={{ minWidth: 130, minHeight: 32 }}>
                                {teamValuesStatsMode === 'sum' ? (
                                  <MetricPlayerButtonWithPercent
                                    statKey={child.key}
                                    stat={child?.stat?.stat || child?.stat}
                                    indexStats={childIndex}
                                    teamId={teamData.id}
                                    teamType={'opponents'}
                                    selectedMetricFilters={
                                      selectedMetricFilters
                                    }
                                  />
                                ) : (
                                  <AverageStatTeamValueTag
                                    statKey={
                                      child?.stat?.stat
                                        ? child?.stat.stat.key
                                        : child?.stat.key
                                    }
                                    teamId={teamData.id}
                                    teamType={'opponents'}
                                    stat={child?.stat?.stat || child?.stat}
                                    selectedMetricFilters={
                                      selectedMetricFilters
                                    }
                                  />
                                )}
                              </div>
                            </Flex>
                          </Flex>
                        );
                      })}
                    {stat.children?.length && (
                      <Divider
                        style={{ width: '100%', height: 1, margin: 0 }}
                      />
                    )}
                  </Flex>
                )}
              </div>
            );
          })}
      </Flex>
    </>
  );
};
export default PerTeamStatistics;
