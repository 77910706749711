import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import GameProtocolSidePanel from 'components/GameProtocolSidePanel';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import EpisodesSearchContainer from './EpisodesSearchContainer';
import PlayerDataBlock from './PlayerDataBlock';
import PlayerInfoBlock from './PlayerInfoBlock';
import PlayerMultitool from './PlayerMultitool';

import './index.css';

const PlayerPageController = () => {
  const dispatch = useAppDispatch();
  const { teamPlayerProfileMode } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });
  const { setTopPlayerRole, setTeamPlayerProfileMode } =
    teamPlayerReducer.actions;
  useEffect(() => {
    dispatch(setTopPlayerRole(null));
  }, []);

  const [t] = useTranslation();
  const { playerId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (
      searchParams.get('playerProfileMode') === 'overview' ||
      !searchParams.get('playerProfileMode')
    ) {
      dispatch(setTeamPlayerProfileMode(t('Overview')));
    } else if (searchParams.get('playerProfileMode') === 'multitool') {
      dispatch(setTeamPlayerProfileMode('Multitool'));
    }
  }, [playerId]);
  return (
    <div className="player-page-controller">
      <PlayerInfoBlock playerProfileMode={teamPlayerProfileMode} />
      <div
        style={{
          display: teamPlayerProfileMode === t('Overview') ? 'block' : 'none',
        }}
      >
        <PlayerDataBlock />
      </div>
      <div
        style={{
          display: teamPlayerProfileMode !== t('Overview') ? 'block' : 'none',
          height: 'calc(100vh - 96px - 56px)',
          overflow: 'hidden',
        }}
      >
        <PlayerMultitool playerProfileMode={teamPlayerProfileMode} />
      </div>
      <GameProtocolSidePanel />
    </div>
  );
};
export default PlayerPageController;
