import React from 'react';

import { Col, ConfigProvider, Divider, Flex, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { SetBaseProtocolData } from 'actions/protocol.actions';
import PhasesTooltipAndButton from 'components/PhasesTooltipAndButton';
import { protocolReducer } from 'reducers/protocol.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';

import MatchStatsContainer from './MatchStatsContainer';
import { ReactComponent as FieldIconBlue } from '../../../assets/img/FieldIconBlue.svg';
import { ReactComponent as ChevronRight } from '../../../assets/img/icons/faChevronRight.svg';
import { ReactComponent as PlayCircleFilled } from '../../../assets/img/icons/faPlayCircleFilled.svg';

interface TeamMatchBlockProps {
  match: any;
  isExpanded: boolean;
  toggleExpanded: (id: string) => void;
  populateTeamStatsForGame: (id: string) => void;
  is2ndSport: boolean;
}

const TeamMatchBlock: React.FC<TeamMatchBlockProps> = React.memo(
  ({
    match,
    isExpanded,
    toggleExpanded,
    populateTeamStatsForGame,
    is2ndSport,
  }) => {
    const dispatch = useAppDispatch();
    const [t] = useTranslation();
    const { setExpandedGameClickedId, setStatsCustomGameList } =
      teamReducer.actions;
    const { openGameProtocol } = protocolReducer.actions;
    const openProtocol = (matchId: string) => {
      if (!is2ndSport) {
        dispatch(SetBaseProtocolData(match));
        dispatch(openGameProtocol(matchId));
      }
    };
    const { teamId } = useParams();
    const getSchemaLabel = (match: any) => {
      if (is2ndSport) {
        return '';
      }
      if (match.schemas.length === 0) {
        return '';
      }
      if (match.schemas.length === 1) {
        return match.schemas[0].schema;
      }
      return `${match.schemas[0].schema}, +${match.schemas.length - 1}`;
    };
    const makeLink = (match: any, team?: any, stat?: any, part?: any) => {
      let link = `/player?&eventId=${match.id}`;
      if (team) {
        if (part === 'highlights') {
          link = `${link}&teamId=${team?.id}`;
        } else {
          if (['ground_duel', 'aerial_duel'].includes(stat?.key)) {
            link = `${link}&teamId=${team?.id}`;
          } else {
            link = `${link}&teamId=${team?.id}&tid=${team.id}`;
          }
        }
      }
      if (stat) {
        link = `${link}&mid=${stat.metric_id}&m=${stat?.key}`;
      }
      if (part) {
        link = `${link}&p=${part}`;
      }

      dispatch(AC_setIntermediateActivitiesAction([match.id]));
      dispatch(setStatsCustomGameList([match.id]));
      return link;
    };
    return (
      <div
        className={`team-match-wrapper ${isExpanded ? 'pressed' : 'enabledClickable'}`}
        onClick={() => !isExpanded && toggleExpanded(match.id)}
        onMouseEnter={() => {
          populateTeamStatsForGame(match.id);
        }}
        id={match.id}
        key={match.id}
      >
        <div
          className="enabledClickable"
          onClick={(e) => {
            e.stopPropagation();
            toggleExpanded(match.id);
          }}
        >
          <div
            style={{
              width: 32,
              height: 32,
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flexShrink: 0,
            }}
          >
            <ChevronRight
              className={`${isExpanded ? 'rotate90' : 'rotate0'} `}
              fill="var(--colorLink, #1677FF)"
            />
          </div>
          <div className="match-details">
            <div>{match.date}</div>
            <div
              style={{
                width: '100%',
              }}
              className="elipsis-text"
            >
              {match.tournament?.country}
            </div>
            <div
              style={{
                width: '100%',
              }}
              className="elipsis-text"
            >
              {match.tournament?.name}
            </div>
          </div>
          <div className="match-participants-container">
            <Flex style={{ textAlign: 'end', flex: 1 }} justify="flex-end">
              <div
                className="team-match-row-team-name"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`/teams/${match.teams[0].id}`, '_blank');
                }}
              >
                {match.teams[0].name}
              </div>
            </Flex>
            <div
              className="score"
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div>{match.teams[0].score}</div>:
              <div>{match.teams[1].score}</div>
            </div>
            <Flex style={{ textAlign: 'start', flex: 1 }} justify="flex-start">
              <div
                className="team-match-row-team-name"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`/teams/${match.teams[1].id}`, '_blank');
                }}
              >
                {match.teams[1].name}
              </div>
            </Flex>
          </div>
          <Flex
            align="flex-end"
            style={{
              height: 80,
              flex: 0,
              minWidth: 56,
              minHeight: 80,
            }}
            gap={8}
            justify="flex-end"
            vertical
          >
            <FieldIconBlue
              className="enabledClickable"
              onClick={(e) => {
                e.stopPropagation();
                openProtocol(match.id);
              }}
            />
            {match.schemas.length > 1 ? (
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 8,
                    paddingSM: 3,
                    controlHeight: 24,
                  },
                }}
              >
                <Tooltip
                  placement="left"
                  color="#141414"
                  title={() => {
                    return match.schemas.map((schema: any, index: number) => {
                      return (
                        <Row
                          justify={'space-between'}
                          key={`${schema.schema}-${index}`}
                        >
                          <Col style={{ flex: 1, textWrap: 'nowrap' }}>
                            {schema.started_at}’–{schema.finished_at}’
                          </Col>
                          <Col>
                            <Divider
                              type="vertical"
                              style={{
                                borderColor: 'rgba(255, 255, 255, 0.25)',
                                margin: 'auto',
                                flex: 1,
                              }}
                            />
                          </Col>
                          <Col
                            style={{
                              flex: 1,
                              marginLeft: 5,
                              textWrap: 'nowrap',
                              textAlign: 'end',
                            }}
                          >
                            {schema.schema}
                          </Col>
                        </Row>
                      );
                    });
                  }}
                  overlayStyle={{
                    minWidth: 150,
                  }}
                >
                  <div
                    style={{
                      height: 22,
                      textWrap: 'nowrap',
                      marginLeft: getSchemaLabel(match).lenght > 1 ? -15 : 0,
                      marginRight: getSchemaLabel(match).lenght > 1 ? -10 : 0,
                    }}
                    className="labelText"
                  >
                    {getSchemaLabel(match)}
                  </div>
                </Tooltip>
              </ConfigProvider>
            ) : (
              <div
                style={{ height: 22, textWrap: 'nowrap' }}
                className="labelText"
              >
                {getSchemaLabel(match)}
              </div>
            )}
          </Flex>
          <Divider type="vertical" style={{ height: 80 }} />
          <Flex
            className="buttons-controller"
            style={{
              marginTop: -4,
              marginBottom: -4,
            }}
            align="center"
          >
            <div
              style={{
                height: 34.6,
              }}
              className="enabledClickable"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setExpandedGameClickedId({
                    id: match.id,
                    expanded: isExpanded,
                  }),
                );
                const link = makeLink(match, { id: teamId }, null, 'full');
                window.open(link, '_blank');
              }}
            >
              <PlayCircleFilled />
              {t('Match')}
            </div>
            <div
              style={{
                height: 34.6,
              }}
              className="enabledClickable"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setExpandedGameClickedId({
                    id: match.id,
                    expanded: isExpanded,
                  }),
                );
                const link = makeLink(
                  match,
                  { id: teamId },
                  null,
                  'highlights',
                );
                window.open(link, '_blank');
              }}
            >
              <PlayCircleFilled />
              {t('Highlights')}
            </div>
            <PhasesTooltipAndButton match={match} teamId={teamId} />
          </Flex>
        </div>
        <MatchStatsContainer match={match} isExpanded={isExpanded} />
      </div>
    );
  },
  (prevProps: any, nextProps: any) => {
    // Only re-render if these specific props have changed:
    return (
      prevProps.isExpanded === nextProps.isExpanded &&
      prevProps.toggleExpanded === nextProps.toggleExpanded
    );
  },
);
export default TeamMatchBlock;
