import React, { FC, useState } from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.css';

interface Interface {
  children: any;
  speedSetCallback: any;
  rewindSpeedSetCallback: any;
  speedValue: number;
  rewindValue: number;
}

const VideoPlayerSettingsContext: FC<Interface> = (props) => {
  const [t] = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState(['x1', '1sec']);
  const speedKeys: any = {
    0.25: 'x0.25',
    0.5: 'x0.5',
    1: 'x1',
    2: 'x2',
  };
  const rewindKeys: any = {
    1: '1sec',
    2: '2sec',
    3: '3sec',
    4: '4sec',
    5: '5sec',
    10: '10sec',
  };
  const setSpeed = (speed: number) => {
    setSelectedKeys((prev) => [
      ...prev.filter((key) => key !== speedKeys[props.speedValue]),
      speedKeys[speed],
    ]);
    props.speedSetCallback(speed);
  };
  const setRewind = (rewind: number) => {
    setSelectedKeys((prev) => [
      ...prev.filter((key) => key !== rewindKeys[props.rewindValue]),
      rewindKeys[rewind],
    ]);
    props.rewindSpeedSetCallback(rewind);
  };
  const items: MenuProps['items'] = [
    {
      type: 'group',
      label: <div style={{ fontSize: 12 }}>{t('Speed')}</div>,
      children: [
        {
          key: 'x0.25',
          label: <div onClick={() => setSpeed(0.25)}>x0.25</div>,
          disabled: false,
        },
        {
          key: 'x0.5',
          label: <div onClick={() => setSpeed(0.5)}>x0.5</div>,
          disabled: false,
        },
        {
          key: 'x1',
          label: <div onClick={() => setSpeed(1)}>x1</div>,
          disabled: false,
        },
        {
          key: 'x2',
          label: <div onClick={() => setSpeed(2)}>x2</div>,
          disabled: false,
        },
      ],
    },
    { type: 'divider' },
    {
      type: 'group',
      label: (
        <div className="no-wrap-visible" style={{ fontSize: 12 }}>
          {t('Rewind step')}
        </div>
      ),
      children: [
        {
          key: '1sec',
          label: <div onClick={() => setRewind(1)}>1 {t('sec')}</div>,
          disabled: false,
        },
        {
          key: '2sec',
          label: <div onClick={() => setRewind(2)}>2 {t('sec')}</div>,
          disabled: false,
        },
        {
          key: '3sec',
          label: <div onClick={() => setRewind(3)}>3 {t('sec')}</div>,
          disabled: false,
        },
        {
          key: '4sec',
          label: <div onClick={() => setRewind(4)}>4 {t('sec')}</div>,
          disabled: false,
        },
        {
          key: '5sec',
          label: <div onClick={() => setRewind(5)}>5 {t('sec')}</div>,
          disabled: false,
        },
        {
          key: '10sec',
          label: <div onClick={() => setRewind(10)}>10 {t('sec')}</div>,
          disabled: false,
        },
      ],
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: { marginLG: 0, padding: 0 },
        },
        token: {
          colorBgElevated: 'var(--colorBgLayout, rgba(41, 45, 50, 1))',
          colorText: 'var(--colorText)',
          // lineHeight: 16
          margin: 0,
          marginXS: 0,
          controlItemBgActive: '#1F1F1F',
          controlItemBgHover: '#1F1F1F',
          controlItemBgActiveHover: '#1F1F1F',
          colorPrimary: 'rgba(255, 255, 255, 0.85)',
          colorTextDescription: 'rgba(255, 255, 255, 0.45)',
        },
      }}
    >
      <Dropdown
        className="enabledClickable"
        menu={{ items, selectedKeys: selectedKeys }}
        trigger={['click']}
        overlayStyle={{
          width: 'max-content',
          paddingBottom: 24,
        }}
        overlayClassName="video-player-settings-menu"
        placement="topRight"
      >
        {props.children}
      </Dropdown>
    </ConfigProvider>
  );
};
export default VideoPlayerSettingsContext;
