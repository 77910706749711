import React, { useState } from 'react';

import { Flex } from 'antd';

import { ReactComponent as GoalGreenIcon } from 'assets/img/icons/faGoalGreenSmall.svg';
import { ReactComponent as GoalIcon } from 'assets/img/icons/faGoalSmall.svg';
import { ReactComponent as RedCardSmall } from 'assets/img/icons/faRedCardSmall.svg';
import { ReactComponent as YellowCardSmall } from 'assets/img/icons/faYellowCardSmall.svg';
import { makeLink } from 'components/PlayerPageController/PlayerStatisticsBlock/const';

import { ReactComponent as PlayerInIcon } from '../../assets/img/icons/faPlayerInGreenIcon.svg';
import { ReactComponent as PlayerOutIcon } from '../../assets/img/icons/faPlayerOutRedIcon.svg';

const EventsTag = ({
  match,
  teamId,
  events,
  playerId,
}: {
  match: any;
  teamId: any;
  events: any[];
  playerId: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isExpanded, setIsExpanded] = useState(false);
  const ICONS: any = {
    goal: <GoalIcon />,
    assist: <GoalGreenIcon />,
    yellowcard: <YellowCardSmall />,
    redcard: <RedCardSmall />,
    in: <PlayerInIcon />,
    out: <PlayerOutIcon />,
  };
  const goToEvent = (keys: string[]) => {
    window.open(
      makeLink(
        [{ id: match }],
        { id: teamId },
        keys.map((key) => {
          return { key: key };
        }),
        null,
        null,
        playerId,
      ),
      '_blank',
    );
  };
  return (
    <Flex align="flex-end" gap={6} className="jersey-event-container">
      {events
        .sort((a: any, b: any) => a.time - b.time)
        .map((detail, index) => {
          return (
            <Flex
              className="enabledClickable"
              // gap={2}
              style={{ overflow: 'hidden' }}
              align="center"
              justify="center"
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                goToEvent([
                  detail.type
                    .replace('out', 'substitution')
                    .replace('in', 'substitution'),
                ]);
              }}
            >
              <div className="time-subst-jersey">{detail.time}’</div>
              <Flex
                align="center"
                className="event-icon"
                style={{
                  position: 'relative',
                  zIndex: 5 - index,
                }}
              >
                {ICONS[detail.type]}
              </Flex>
            </Flex>
          );
        })}
    </Flex>
  );
};
export default EventsTag;
