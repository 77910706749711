import React from 'react';

import { ConfigProvider, Divider, Flex, Skeleton } from 'antd';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

import { ReactComponent as CloseCrossIcon } from '../../assets/img/icons/faCloseCross.svg';

const Title = ({
  onClose,
  activePlacement,
  gameProtocol,
}: {
  onClose: any;
  activePlacement: any;
  gameProtocol: any;
}) => {
  const { protocolIsLoading } = useSelector(
    (state: AppStateType) => state.protocolReducer,
  );
  return (
    <div className="game-protocol-title">
      <ConfigProvider
        theme={{
          components: {
            Skeleton: {
              // gradientFromColor:
              //   'var(--colorFillTertiary, rgba(0, 0, 0, 0.04))',
              gradientToColor: 'var(--colorFillTertiary, rgba(0, 0, 0, 0.09))',
            },
          },
        }}
      >
        <CloseCrossIcon
          className="enabledClickable"
          onClick={onClose}
          style={{ position: 'absolute', top: 16, right: 16 }}
        />
        <Flex flex={1} align="center">
          {protocolIsLoading ? (
            <Skeleton.Button
              active
              shape="round"
              style={{ minWidth: 55, maxHeight: 16 }}
            />
          ) : (
            <div className="title-placement-name">
              {activePlacement?.home_team}
            </div>
          )}
          <div className="game-protocol-team">
            {protocolIsLoading ? (
              <Skeleton.Button
                active
                style={{
                  maxWidth: 56,
                  minWidth: 56,
                  maxHeight: 56,
                  minHeight: 56,
                }}
              />
            ) : (
              <img
                className="game-protocol-logo"
                src={gameProtocol?.homeTeam?.logo}
                alt=""
              />
            )}
            <a
              className="game-protocol-team-name"
              href={`/teams/${gameProtocol?.homeTeam?.id}`}
              target="_blank"
            >
              {gameProtocol?.homeTeam?.name}
            </a>
          </div>
        </Flex>
        <div className="game-protocol-details">
          <div className="date">{gameProtocol?.date}</div>
          <div className="protocol-score">
            <div style={{ flex: 1, textAlign: 'right' }}>
              {gameProtocol?.score.split('-')[0]}
            </div>
            :<div style={{ flex: 1 }}>{gameProtocol?.score.split('-')[1]}</div>
          </div>
          <div className="game-protocol-tournament">
            {gameProtocol?.tournament}
            <Divider type="vertical" />
            {gameProtocol?.country}
          </div>
        </div>
        <Flex flex={1} align="center">
          <div className="game-protocol-team">
            {protocolIsLoading ? (
              <Skeleton.Button
                active
                style={{
                  maxWidth: 56,
                  minWidth: 56,
                  maxHeight: 56,
                  minHeight: 56,
                }}
              />
            ) : (
              <img
                className="game-protocol-logo"
                src={gameProtocol?.awayTeam?.logo}
                alt=""
              />
            )}
            <a
              className="game-protocol-team-name"
              href={`/teams/${gameProtocol?.awayTeam?.id}`}
              target="_blank"
            >
              {gameProtocol?.awayTeam?.name}
            </a>
          </div>
          {protocolIsLoading ? (
            <Skeleton.Button
              active
              shape="round"
              style={{ minWidth: 55, maxHeight: 16 }}
            />
          ) : (
            <div className="title-placement-name">
              {activePlacement.away_team}
            </div>
          )}
        </Flex>
      </ConfigProvider>
    </div>
  );
};
export default Title;
