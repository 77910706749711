import React from 'react';

import { Checkbox, ConfigProvider, Flex } from 'antd';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
interface MetricFilterInterface {
  metric: string;
  asPoint: boolean;
  selectedMetricFilters: any;
  hoveredMetricFilterIncludes: boolean;
  toggleSelectedMetric: any;
  reverseMatchColor: any;
}
const MetricFilter: React.FC<MetricFilterInterface> = React.memo(
  ({
    metric,
    asPoint,
    selectedMetricFilters,
    hoveredMetricFilterIncludes,
    toggleSelectedMetric,
    reverseMatchColor,
  }) => {
    const { metricsRegistry } = useSelector(
      (state: AppStateType) => state.metricsReducer,
    );
    const metricStringSuccess = `${metric}_success`;
    const metricStringFail = `${metric}_fail`;
    if (
      (selectedMetricFilters &&
        (selectedMetricFilters.includes(metricStringSuccess) ||
          selectedMetricFilters.includes(metricStringFail))) ||
      hoveredMetricFilterIncludes
    ) {
      return (
        <Flex
          gap={4}
          style={{ zIndex: 2 }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Flex
            className={`${metricsRegistry.match.filter((m) => m.key === metric)[0]?.reverse_match_color ? 'fail-checkbox' : 'success-checkbox'}`}
            justify="center"
          >
            <ConfigProvider
              theme={{
                token: {},
              }}
            >
              <Checkbox
                checked={selectedMetricFilters.includes(metricStringSuccess)}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSelectedMetric(
                    metricStringSuccess,
                    metricStringFail,
                    metric,
                    asPoint,
                    reverseMatchColor,
                  );
                }}
              />
            </ConfigProvider>
          </Flex>
          <Flex
            className={`${metricsRegistry.match.filter((m) => m.key === metric)[0]?.reverse_match_color ? 'success-checkbox' : 'fail-checkbox'}`}
            justify="center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: 'grey',
                },
              }}
            >
              <Checkbox
                checked={selectedMetricFilters.includes(metricStringFail)}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSelectedMetric(
                    metricStringFail,
                    metricStringSuccess,
                    metric,
                    asPoint,
                  );
                }}
              />
            </ConfigProvider>
          </Flex>
        </Flex>
      );
    } else {
      return <Flex children={undefined}></Flex>;
    }
  },
  (prevProps: any, nextProps: any) => {
    // Only re-render if these specific props have changed:
    return (
      prevProps.selectedMetricFilters === nextProps.selectedMetricFilters &&
      prevProps.hoveredMetricFilterIncludes ===
        nextProps.hoveredMetricFilterIncludes
    );
  },
);
export default MetricFilter;
