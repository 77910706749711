import { ThunkAction } from 'redux-thunk';

import { AppStateType } from 'reducers';
import { protocolReducer } from 'reducers/protocol.reducer';

const { setGameProtocol } = protocolReducer.actions;

export const SetBaseProtocolData =
  (
    matchData: any,
    source = 'profile',
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    // console.log('matchData');
    // console.log(matchData);
    let baseProtocol;
    if (source === 'profile') {
      baseProtocol = {
        country: matchData.tournament.country,
        date: matchData.date,
        score: `${matchData.teams[0].score}-${matchData.teams[1].score}`,
        tournament: matchData.tournament.name,
        homeTeam: {
          name: matchData.teams[0].name,
        },
        awayTeam: {
          name: matchData.teams[1].name,
        },
      };
    } else {
      baseProtocol = {
        country: matchData.tournament.country.name,
        date: matchData.name.split(' ').at(-1),
        score: matchData.result.score,
        tournament: matchData.tournament.name,
        homeTeam: {
          name: matchData.home_team.name,
        },
        awayTeam: {
          name: matchData.away_team.name,
        },
      };
    }
    dispatch(setGameProtocol(baseProtocol));
  };
