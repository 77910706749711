import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  protocolGameID: string;
  protocolIsLoading: boolean;
  gameProtocol: any;
};
const initialState: InitialStateType = {
  protocolGameID: '',
  protocolIsLoading: false,
  gameProtocol: null,
};
export const protocolReducer = createSlice({
  name: 'reelsReducer',
  initialState: initialState,
  reducers: {
    openGameProtocol(state, action) {
      state.protocolGameID = action.payload;
    },
    setProtocolIsLoading(state, action) {
      state.protocolIsLoading = action.payload;
    },
    setGameProtocol(state, action) {
      state.gameProtocol = action.payload;
    },
  },
});
