import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  tableMatches: any[]; // список игр, отображаемых в окне показа для фильтрации статистики
  appliedLastMatch: number | string | null;
  selectedSeason: any;
  selectedTournaments: string[]; // список id выбранных турниров в модалке статистики
  selectedRowKeys: string[] | undefined; // список id игр, выбранных в таблице статистики

  statsCustomGameList: string[];
  expandedGameClickedId: any;
  selectedMatchesStats: any[];
  showGameSelectionModal: boolean;
  manualGamesSelectionSection: string;
  bestPlayerStat: string;
  expandedGames: string[];

  teamProfileMode: string;
  teamData: any;
  teamSchemas: any[];

  teamStatsMatches: any; // консолидированые данные по сезонам/турниру/играм игрока для статистики

  appliedMatches: string[]; // список id игр, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  checkedSeasons: string[]; // список id выбранных сезонов в модалке статистики
  appliedSeasons: string[]; // список id сезонов, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  appliedTournaments: string[]; // список id турниров, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  tableMatchesLoading: string;
  selectedMatches: any[];
  teamMatchesStatsState: string;
  statsState: 'init' | 'loading' | 'loaded' | 'error';
  teamStats: any[];
  expandedSeasons: string[]; // список раскрытых сезонов в дереве модалки

  selectedLastMatch: number | string | null;

  teamSeasonsList: any[];

  isMultitoolPopupOpen: boolean;
};
const initialState: InitialStateType = {
  statsCustomGameList: [],
  expandedGameClickedId: null,
  selectedSeason: null,
  selectedMatchesStats: [],
  showGameSelectionModal: false,
  manualGamesSelectionSection: '',
  bestPlayerStat: 'goals',
  expandedGames: [],

  teamProfileMode: '',
  teamData: {},
  teamSchemas: [],

  tableMatches: [],
  teamStatsMatches: [],
  selectedRowKeys: undefined,
  appliedMatches: [],
  checkedSeasons: [],
  appliedSeasons: [],
  appliedTournaments: [],
  selectedTournaments: [],
  tableMatchesLoading: 'ready',
  selectedMatches: [],
  teamMatchesStatsState: 'loaded',
  expandedSeasons: [],
  statsState: 'init',
  teamStats: [],
  selectedLastMatch: null,
  appliedLastMatch: null,

  teamSeasonsList: [],
  isMultitoolPopupOpen: false,
};

export const teamMultitoolReducer = createSlice({
  name: 'teamMultitoolReducer',
  initialState: initialState,
  reducers: {
    setBestPlayersStat(state, action) {
      state.bestPlayerStat = action.payload;
    },
    setManualGamesSelectionSection(state, action) {
      state.manualGamesSelectionSection = action.payload;
    },
    setShowGameSelectionModal(state, action) {
      state.showGameSelectionModal = action.payload;
    },
    setSelectedMatchesStats(state, action) {
      state.selectedMatchesStats = action.payload;
    },
    setMultitoolSelectedSeason(state, action) {
      state.selectedSeason = action.payload;
    },
    setStatsCustomGameList(state, action) {
      state.statsCustomGameList = action.payload;
    },
    setExpandedGameClickedId(state, action) {
      state.expandedGameClickedId = {
        id: action.payload.id,
        expanded: action.payload.expanded,
      };
    },
    setExpandedGamesTeam(state, action) {
      state.expandedGames = action.payload;
    },
    clearExpandedGamesTeam(state) {
      state.expandedGames = [];
    },
    setTeamProfileMode(state, action) {
      state.teamProfileMode = action.payload;
    },
    setTeamData(state, action) {
      state.teamData = action.payload;
    },
    setTeamSchemas(state, action) {
      state.teamSchemas = action.payload;
    },
    setSelectedLastMatch(state, action) {
      state.selectedLastMatch = action.payload;
    },
    setTeamSeasonsList(state, action) {
      state.teamSeasonsList = action.payload;
    },
    setTableMatches(state, action) {
      state.tableMatches = action.payload;
    },
    setMultitoolTableMatchesLoading(state, action) {
      state.tableMatchesLoading = action.payload;
    },
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setCheckedSeasons(state, action) {
      state.checkedSeasons = action.payload;
    },
    setSelectedTournaments(state, action) {
      state.selectedTournaments = action.payload;
    },
    setSelectedMatches(state, action) {
      state.selectedMatches = action.payload;
    },
    setExpandedSeasons(state, action) {
      state.expandedSeasons = action.payload;
    },
    setMultitoolAppliedSeasons(state, action) {
      state.appliedSeasons = action.payload;
    },
    setTeamMatches(state, action) {
      state.tableMatches = action.payload;
    },
    setTeamMatchesStatsState(state, action) {
      state.teamMatchesStatsState = action.payload;
    },
    setStatsState(state, action) {
      state.statsState = action.payload;
    },
    setMultitoolTeamStatsMatches(state, action) {
      state.teamStatsMatches = action.payload;
    },
    setTeamStats(state, action) {
      state.teamStats = action.payload;
    },
    setIsMultitoolPopupOpen(state, action) {
      state.isMultitoolPopupOpen = action.payload;
    },
  },
});
