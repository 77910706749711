import { ThunkAction } from 'redux-thunk';

import { AppStateType } from 'reducers';

export const GetSingleSeasonSelectedTeamLineup =
  (): ThunkAction<Promise<string>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { teamStatsMatches, selectedRowKeys } = getState().teamLineupsReducer;
    let seasonId = 'manual';
    let found = true;
    selectedRowKeys !== undefined &&
      teamStatsMatches.forEach((season: any) => {
        if (!found) {
          return;
        }
        season.leagues?.forEach((league: any) => {
          if (league.matches.length === selectedRowKeys.length) {
            const leagueIds = league.matches
              .map((leagueMatch: any) => leagueMatch.id)
              .sort();
            const tableMatchesIds = selectedRowKeys.map((el) => el).sort();
            const e = leagueIds.every((value: string, index: number) => {
              return value === tableMatchesIds[index];
            });
            if (e) {
              found = false;
              seasonId = league.season_id;
              return;
            }
          }
        });
      });
    return seasonId;
  };
