/* eslint-disable @typescript-eslint/no-unused-vars */
import { WINDOW_MODE_WIDTH_MS } from 'types/constants';
import { filterAndIndex } from 'types/functions';

export const editEpisodeTimeline = (
  filteredVideos: any,
  playedVideoSet: any,
  playedEpisode: any,
  timeShift: number,
) => {
  const updatedVideo = filterAndIndex(filteredVideos, playedVideoSet?.id, 'id');
  const editedEpisode = filterAndIndex(
    updatedVideo?.element?.episodes,
    playedEpisode?.id,
    'id',
  );
  let gameStartTime, gameEndTime;
  let fileStartTime, fileEndTime;
  const period = playedVideoSet?.periods?.filter(
    (period: any) => period.name === editedEpisode.element.period_name,
  );
  // time calculator
  if (editedEpisode.element.user_timer) {
    ({ gameStartTime, gameEndTime, fileStartTime, fileEndTime } =
      editUserEditedEpisode(timeShift, period, editedEpisode));
  } else {
    ({ gameStartTime, gameEndTime, fileStartTime, fileEndTime } =
      editSystemEpisodeTime(timeShift, period, editedEpisode));
  }
  const updatedFilteredVideos = [
    ...filteredVideos.slice(0, updatedVideo.index),
    {
      ...updatedVideo.element,
      episodes: [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...updatedVideo?.element.episodes?.slice(0, editedEpisode.index),
        {
          ...editedEpisode.element,
          initial_timer: {
            game_timer:
              editedEpisode.element.initial_timer?.game_timer ||
              editedEpisode.element.game_timer,
            file_timer:
              editedEpisode.element.initial_timer?.file_timer ||
              editedEpisode.element.file_timer,
          },
          game_timer: {
            started_at: gameStartTime,
            finished_at: gameEndTime,
          },
          user_timer: {
            started_at: fileStartTime,
            finished_at: fileEndTime,
          },
          file_timer: {
            started_at: fileStartTime,
            finished_at: fileEndTime,
          },
          isTemporary: true,
        },
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...updatedVideo?.element.episodes?.slice(editedEpisode.index + 1),
      ],
    },
    ...filteredVideos.slice(updatedVideo.index + 1),
  ];
  const editedPlayedEpisode = {
    ...playedEpisode,
    initial_timer: {
      game_timer:
        editedEpisode.element.initial_timer?.game_timer ||
        editedEpisode.element.game_timer,
      file_timer:
        editedEpisode.element.initial_timer?.file_timer ||
        editedEpisode.element.file_timer,
    },
    game_timer: {
      started_at: gameStartTime,
      finished_at: gameEndTime,
    },
    user_timer: {
      started_at: fileStartTime,
      finished_at: fileEndTime,
    },
    file_timer: {
      started_at: fileStartTime,
      finished_at: fileEndTime,
    },
  };
  return {
    updatedFilteredVideos: updatedFilteredVideos,
    editedPlayedEpisode: editedPlayedEpisode,
    startTime: fileStartTime || 0,
  };
};

export const editSystemEpisodeTime = (
  timeShift: number,
  period: any,
  editedEpisode: any,
) => {
  let gameStartTime, gameEndTime;
  let fileStartTime, fileEndTime;
  if (timeShift > 0) {
    if (period.length) {
      gameEndTime = Math.min(
        editedEpisode.element.game_timer.finished_at +
          timeShift * 1000 +
          WINDOW_MODE_WIDTH_MS,
        period[0].game_finish,
      );
      fileEndTime = Math.min(
        editedEpisode.element.file_timer.finished_at +
          timeShift * 1000 +
          WINDOW_MODE_WIDTH_MS,
        period[0].finished_at,
      );
    } else {
      gameEndTime =
        editedEpisode.element.game_timer.finished_at +
        timeShift * 1000 +
        WINDOW_MODE_WIDTH_MS;
      fileEndTime =
        editedEpisode.element.file_timer.finished_at +
        timeShift * 1000 +
        WINDOW_MODE_WIDTH_MS;
    }
    gameStartTime =
      editedEpisode.element.game_timer.started_at - WINDOW_MODE_WIDTH_MS;
    fileStartTime =
      editedEpisode.element.file_timer.started_at - WINDOW_MODE_WIDTH_MS;
  } else {
    if (period.length) {
      gameStartTime = Math.max(
        editedEpisode.element.game_timer.started_at +
          timeShift * 1000 -
          WINDOW_MODE_WIDTH_MS,
        period[0].game_start,
      );
      fileStartTime = Math.max(
        editedEpisode.element.file_timer.started_at +
          timeShift * 1000 -
          WINDOW_MODE_WIDTH_MS,
        period[0].started_at,
      );
    } else {
      gameStartTime =
        editedEpisode.element.game_timer.started_at +
        timeShift * 1000 -
        WINDOW_MODE_WIDTH_MS;
      fileStartTime =
        editedEpisode.element.game_timer.started_at +
        timeShift * 1000 -
        WINDOW_MODE_WIDTH_MS;
    }
    gameEndTime =
      editedEpisode.element.game_timer.finished_at + WINDOW_MODE_WIDTH_MS;
    fileEndTime =
      editedEpisode.element.file_timer.finished_at + WINDOW_MODE_WIDTH_MS;
  }
  return {
    gameStartTime: gameStartTime,
    gameEndTime: gameEndTime,
    fileStartTime: fileStartTime,
    fileEndTime: fileEndTime,
  };
};
export const editUserEditedEpisode = (
  timeShift: number,
  period: any,
  editedEpisode: any,
) => {
  let gameStartTime, gameEndTime;
  let fileStartTime, fileEndTime;
  if (timeShift > 0) {
    // сдвигаем эпизод направо
    if (period.length) {
      gameEndTime = Math.min(
        editedEpisode.element.game_timer.finished_at + timeShift * 1000,
        period[0].game_finish,
      );
      fileEndTime = Math.min(
        editedEpisode.element.file_timer.finished_at + timeShift * 1000,
        period[0].finished_at,
      );
    } else {
      gameEndTime =
        editedEpisode.element.game_timer.finished_at + timeShift * 1000;
      fileEndTime =
        editedEpisode.element.file_timer.finished_at + timeShift * 1000;
    }
    gameStartTime = editedEpisode.element.game_timer.started_at;
    fileStartTime = editedEpisode.element.file_timer.started_at;
  } else {
    if (period.length) {
      gameStartTime = Math.max(
        editedEpisode.element.game_timer.started_at + timeShift * 1000,
        period[0].game_start,
      );
      fileStartTime = Math.max(
        editedEpisode.element.file_timer.started_at + timeShift * 1000,
        period[0].started_at,
      );
    } else {
      gameStartTime =
        editedEpisode.element.game_timer.started_at + timeShift * 1000;
      fileStartTime =
        editedEpisode.element.file_timer.started_at + timeShift * 1000;
    }
    gameEndTime = editedEpisode.element.game_timer.finished_at;
    fileEndTime = editedEpisode.element.file_timer.finished_at;
  }
  return {
    gameStartTime: gameStartTime,
    gameEndTime: gameEndTime,
    fileStartTime: fileStartTime,
    fileEndTime: fileEndTime,
  };
};
