export const JERSEY_COLORS: any = {
  '#FF7875': '#FF7875',
  '#FFA940': '#FFA940',
  '#FADB14': '#FADB14',
  '#95DE64': '#95DE64',
  '#5CDBD3': '#5CDBD3',
  '#69B1FF': '#69B1FF',
  '#B37FEB': '#B37FEB',
  '#FF85C0': '#FF85C0',
  '#000000': '#000000',
  '#FFFFFF': 'rgba(217, 217, 217, 1)',
  '#F5F5F5': 'rgba(217, 217, 217, 1)',
};
export const JERSEY_TEXT_COLORS: any = {
  '#FF7875': '#FFF',
  '#FFA940': '#000',
  '#FADB14': '#000',
  '#95DE64': '#000',
  '#5CDBD3': '#000',
  '#69B1FF': '#FFF',
  '#B37FEB': '#FFF',
  '#FF85C0': '#000',
  '#000000': '#FFF',
  '#FFFFFF': '#000',
  '#F5F5F5': '#000',
};
