import React, { FC, useRef, useState } from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/types/base';

import './index.css';
import VideoScroller from './VideoScroller';
import SelectArrowLeftWhite from '../../assets/img/SelectArrowLeftWhite.svg';
import { formattedTimeSeconds } from '../../types/functions';
import { CameraType } from '../../types/types';

interface CameraCheckWindowInterface {
  camera: CameraType;
  video: any;
  backAction: any;
  confirmAction: any;
}

const CameraCheckWindow: FC<CameraCheckWindowInterface> = ({
  camera,
  video,
  backAction,
  confirmAction,
}) => {
  const refPlayer = useRef<ReactPlayer>(null); // Ссылка на проигрыватель
  const [isPlaying, setIsPlaying] = useState(true); // Хранит состояние Вкл/Выкл проигрывателя
  const [playerState, setPlayerState] = useState<OnProgressProps | undefined>(
    undefined,
  ); // Состояние проигрывателя

  const handleVideoScroll = (millisecond: number) => {
    // Перемотка видео на нужную мили секунду
    const seconds = Number(millisecond / 1000);
    refPlayer?.current?.seekTo(seconds, 'seconds');
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [horizontalScroll, setHorizontalScroll] = useState(0);

  const handleProgress = (state: OnProgressProps) => {
    setPlayerState(state);
  };
  const handleError = (
    error: any,
    // data?: any,
    // hlsInstance?: any,
    // hlsGlobal?: any,
  ) => {
    if (error.message?.includes('The play() request was interrupted')) {
      // console.log();
      // dispatch(VideoErrorAction("Видео загружается, пожалуйста, подождите"))
    } else {
      // dispatch(VideoErrorAction("Ошибка получения видео"))
    }
  };
  const progressRef = useRef<any>(null);
  const handleProgressBarClick = (e: any) => {
    // Обработка клика для перехода на точку в видео
    const baseParent = e.target.parentNode.parentNode;
    const parent =
      e.target.id === 'viewedProgressBar' ? baseParent.parentNode : baseParent;
    const x = e.clientX - parent.offsetLeft + horizontalScroll;
    const parentWidth = parent.clientWidth;
    setNewVideoValue(x, parentWidth);
  };
  const handleDrag = (e: any, data: any) => {
    // Обработка drag ползунка видео
    const x = data.x;
    const parentWidth = data.node.parentNode.clientWidth;
    setNewVideoValue(x, parentWidth);
  };
  const setNewVideoValue = (x: number, parentWidth: number) => {
    // Расчет и подача команды на перемотку видео
    if (video.duration) {
      const ratio = (x / parentWidth) * video.duration || 0;
      const newValue = ratio * 1000;
      handleVideoScroll(newValue);
    }
  };

  const getCurrentProgressPosition = (getRatio = false) => {
    // Расчет текущего положения видео в процентах или пикселях от трека видео
    if (!!playerState?.playedSeconds && !!video.duration) {
      if (getRatio) {
        return playerState?.playedSeconds / video.duration;
      }

      const progressWidth = progressRef.current?.clientWidth;
      // console.log("progressWidth", progressWidth)
      // console.log("playerState?.playedSeconds", playerState?.playedSeconds)
      // console.log("video.duration", video.duration)
      return (playerState?.playedSeconds / video.duration) * progressWidth;
    }
    return 0;
  };
  const draggedRef = useRef<any>(null);
  const clickRef = useRef(false);

  const getRightBound = (): number => {
    // Правая граница трека видео
    return progressRef.current?.clientWidth;
  };
  const { t } = useTranslation();

  return (
    <div className={'cameraCheckWindowContainer'}>
      <div className={'cameraCheckWindow_TopControls'}>
        <div className={'cameraCheckWindow_BackButton j-ce enabledClickable'}>
          <img src={SelectArrowLeftWhite} onClick={backAction} />
          <div>
            <span>{t('Teams and players')}</span> /{' '}
            <div>
              {t('Camera check')} "{camera.name}"
            </div>
          </div>
        </div>
        <div
          className={'cameraCheckWindow_DoneButton enabled enabledClickable'}
          onClick={confirmAction}
        >
          {t('Done')}
        </div>
      </div>
      <div className={'cameraCheckWindow_VideoPreview'}>
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => setIsPlaying(!isPlaying)}
        >
          {video ? (
            <ReactPlayer
              config={{
                file: {
                  attributes: {
                    preload: 'metadata',
                    type: 'video/mp4',
                  },
                },
              }}
              ref={refPlayer}
              height={'600px'}
              // width={"50%"}
              width={'auto'}
              url={video.url}
              // playIcon={playIcon}
              playbackRate={1}
              progressInterval={10}
              playing={isPlaying}
              onProgress={handleProgress}
              onError={handleError}
            />
          ) : (
            <Flex gap={6} vertical>
              <div>{t('Nothing is being played')}</div>
              <div>
                {t('Select the episode to play on the panel on the right')}
              </div>
            </Flex>
          )}
        </div>
      </div>
      <div
        className={'cameraCheckWindow_VideoControls'}
        style={
          {
            // height: 0
          }
        }
      >
        <div className={'cameraCheckWindow_ProgressBar'}>
          <div
            className={'newProgressBar'}
            ref={progressRef}
            onClick={handleProgressBarClick}
            id={'progressBar'}
          >
            <VideoScroller
              handleDrag={handleDrag}
              getCurrentProgressPosition={getCurrentProgressPosition}
              getRightBound={getRightBound}
              draggedRef={draggedRef}
              // toggleUserPointAdder={toggleUserPointAdder}
              clickRef={clickRef}
            />
            <div
              style={{
                left: '0px',
                transform: `scaleX(${getCurrentProgressPosition(true)})`,
              }}
              id={'viewedProgressBar'}
              className={'viewedProgressBar'}
            />
          </div>
        </div>
        <div className={'cameraCheckWindow_Timer flex-row f-ga-8 j-fs ai-c'}>
          <span
            style={{
              width:
                formattedTimeSeconds(playerState?.playedSeconds).length > 8
                  ? 78
                  : 70,
            }}
          >
            {formattedTimeSeconds(playerState?.playedSeconds)}
          </span>
          |<span>{formattedTimeSeconds(video?.duration)}</span>
        </div>
      </div>
    </div>
  );
};
export default CameraCheckWindow;
