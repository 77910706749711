import React, { useEffect, useState } from 'react';

import { TableauViz } from '@tableau/embedding-api-react';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

export default function VizContainer({
  selectedTab,
  teamProfileMode,
  vizRef,
}: {
  selectedTab: string;
  teamProfileMode: string;
  vizRef: any;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { teamData } = useSelector((state: AppStateType) => {
    return state.teamReducer;
  });
  const { language } = useSelector((state: AppStateType) => {
    return state.interfaceReducer;
  });
  const { selectedRowKeys } = useSelector((state: AppStateType) => {
    return {
      selectedRowKeys: state.teamMultitoolReducer.selectedRowKeys,
    };
  });
  const [localSelected, setLocalSelected] = useState(selectedRowKeys);
  const [token, setToken] = useState('');
  const EMBEDDING_LINKS: any = {
    en: 'https://prod-uk-a.online.tableau.com/#/site/sotaai/views/TeamMultitoolENG/',
    ru: 'https://prod-uk-a.online.tableau.com/#/site/sotaai/views/TeamMultitoolRUS/',
  };
  useEffect(() => {
    if (localStorage.getItem('multi_token') !== undefined) {
      setToken(localStorage.getItem('multi_token') || '');
    }
    setLocalSelected(selectedRowKeys);
  }, [localStorage, teamProfileMode, selectedTab]);
  return (
    <div style={{ minHeight: 1100 }}>
      <TableauViz
        ref={vizRef}
        token={token}
        toolbar="hidden"
        hideTabs={true}
        width="100%"
        // height="100%"
        src={`${EMBEDDING_LINKS[language]}${selectedTab}`}
        vizFilters={[
          { field: 'p_selected_team', value: teamData.name },
          {
            field: 'match_uuid',
            value: localSelected,
          },
        ]}
      />
    </div>
  );
}
