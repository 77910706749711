import { filterAndIndex } from 'types/functions';
import { EpisodeTypes } from 'types/types';

export const revertEpisodeFunction = (
  episode: any,
  updatedVideo: any,
  playedEpisode: any,
  filteredVideos: any,
) => {
  let updatedFilteredVideos;
  let editedEpisode;
  if (episode.episode_type === EpisodeTypes.episode) {
    editedEpisode = filterAndIndex(
      updatedVideo?.element?.episodes,
      episode.id,
      'id',
    );

    updatedFilteredVideos = [
      ...filteredVideos.slice(0, updatedVideo.index),
      {
        ...updatedVideo.element,
        episodes: [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...updatedVideo?.element.episodes?.slice(0, editedEpisode.index),
          {
            ...editedEpisode.element,
            game_timer:
              editedEpisode.element.initial_timer.game_timer ||
              editedEpisode.element.game_timer,
            file_timer:
              editedEpisode.element.initial_timer.file_timer ||
              editedEpisode.element.file_timer,
            isTemporary: undefined,
            user_timer: undefined,
            user_event_name: undefined,
            initial_timer: undefined,
            keyframes: undefined,
          },
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...updatedVideo?.element.episodes?.slice(editedEpisode.index + 1),
        ],
      },
      ...filteredVideos.slice(updatedVideo.index + 1),
    ];
  } else if (episode.episode_type === EpisodeTypes.user_episode) {
    editedEpisode = filterAndIndex(
      updatedVideo?.element?.user_episodes,
      episode.id,
      'id',
    );
    updatedFilteredVideos = [
      ...filteredVideos.slice(0, updatedVideo.index),
      {
        ...updatedVideo.element,
        user_episodes: [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...updatedVideo?.element.user_episodes?.slice(0, editedEpisode.index),
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...updatedVideo?.element.user_episodes?.slice(
            editedEpisode.index + 1,
          ),
        ],
      },
      ...filteredVideos.slice(updatedVideo.index + 1),
    ];
  }
  return {
    updatedFilteredVideos: updatedFilteredVideos,
  };
};
