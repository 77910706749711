import React, { useEffect, useRef, useState } from 'react';

import { ConfigProvider, Drawer, Flex, Skeleton, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ProtocolAPI } from 'api/protocol';
import { makeLink } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { protocolReducer } from 'reducers/protocol.reducer';
import { JERSEY_COLORS, JERSEY_TEXT_COLORS } from 'utils/constants';

import EventsTag from './EventsTag';
import { shirtSvg } from './PlayerJersey';
import Title from './Title';
// import AtyrauLogo from '../../assets/AtyrauLogo.png';
import { ReactComponent as GoalGreenIcon } from '../../assets/img/icons/faGoalGreenSmall.svg';
import { ReactComponent as GoalSmall } from '../../assets/img/icons/faGoalSmall.svg';
import { ReactComponent as PlayerInIcon } from '../../assets/img/icons/faPlayerInGreenIcon.svg';
import { ReactComponent as PlayerOutIcon } from '../../assets/img/icons/faPlayerOutRedIcon.svg';
import { ReactComponent as RedCardSmall } from '../../assets/img/icons/faRedCardSmall.svg';
import { ReactComponent as YellowCardSmall } from '../../assets/img/icons/faYellowCardSmall.svg';
// import KairatLogo from '../../assets/KairatLogo.png';
import { AppStateType } from '../../reducers';
import { useAppDispatch } from '../../store';
import './index.css';

const GameProtocolSidePanel = () => {
  const { protocolGameID, protocolIsLoading, gameProtocol } = useSelector(
    (state: AppStateType) => state.protocolReducer,
  );
  const { setProtocolIsLoading, setGameProtocol } = protocolReducer.actions;
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [placements, setPlacements] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activePlacement, setActivePlacement] = useState<any>({});
  const { openGameProtocol } = protocolReducer.actions;
  const onClose = () => {
    dispatch(openGameProtocol(''));
  };
  const fieldRef = useRef<any>(null);
  const goToEvent = (keys: string[], playerId: string, teamId: number) => {
    window.open(
      makeLink(
        [{ id: protocolGameID }],
        { id: teamId },
        keys.map((key) => {
          return { key: key };
        }),
        null,
        null,
        playerId,
      ),
      '_blank',
    );
  };
  useEffect(() => {
    if (protocolGameID) {
      dispatch(setProtocolIsLoading(true));
      ProtocolAPI.getMatchProtocol(protocolGameID)
        .then((response) => {
          setPlacements(response.data.placements);
          dispatch(
            setGameProtocol({
              homeTeam: response.data.home_team,
              awayTeam: response.data.away_team,
              date: response.data.date,
              score: response.data.score,
              tournament: response.data.tournament,
              country: response.data.country,
            }),
          );
          setActivePlacement(response.data.placements[0]);
        })
        .finally(() => {
          dispatch(setProtocolIsLoading(false));
        });
    }
    if (!protocolGameID) {
      setGameProtocol(null);
      setPlacements([]);
      setActivePlacement({});
    }
  }, [protocolGameID]);

  const [t] = useTranslation();
  const EVENT_ICONS: any = {
    redcard: <RedCardSmall />,
    assist: <GoalGreenIcon />,
    yellowcard: <YellowCardSmall />,
    goal: <GoalSmall />,
  };
  const PlayerJerey = ({
    player,
    color,
    invert,
    teamId,
  }: {
    player: any;
    color: any;
    invert: boolean;
    teamId: number;
  }) => {
    const svgDataUrl = shirtSvg(JERSEY_COLORS[color]);
    return (
      <Flex
        key={`jersey=${player.player_number}`}
        vertical
        align="center"
        style={{
          position: 'absolute',
          top:
            (invert ? 1 - player.coordY : player.coordY) *
              fieldRef.current?.getBoundingClientRect().height -
            18,
          left:
            (invert ? 1 - player.coordX : player.coordX) *
              fieldRef.current?.getBoundingClientRect().width -
            0,
        }}
        className={`player-shirt ${color}`}
      >
        {player.events?.length > 0 ? (
          <EventsTag
            match={protocolGameID}
            teamId={teamId}
            events={player?.events}
            playerId={player.player_id}
          />
        ) : (
          <></>
        )}
        <Flex
          align="center"
          justify="center"
          className="shirt-number"
          style={{
            color: JERSEY_TEXT_COLORS[color],
            width: 50,
            height: 40,
            backgroundImage: `url("${svgDataUrl}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          {player.player_number}
        </Flex>
        <a
          className="shirt-player-name enabledClickable"
          href={`/players/${player.player_id}`}
          target="_blank"
        >
          {player.last_name}
        </a>
      </Flex>
    );
  };
  return (
    <Drawer
      width={'auto'}
      closable={false}
      styles={{
        header: {
          padding: 0,
        },
        body: {
          padding: 0,
          overflow: 'hidden',
        },
        content: {
          borderRadius: '12px 0px 0px 12px',
          overflow: 'hidden',
        },
      }}
      onClose={onClose}
      open={protocolGameID !== ''}
    >
      <ConfigProvider
        theme={{
          components: {
            Skeleton: {
              gradientToColor: 'var(--colorFillTertiary, rgba(0, 0, 0, 0.09))',
            },
          },
        }}
      >
        <Flex
          style={{
            height: '100%',
          }}
        >
          <Flex className="game-protocol-lineups-wrapper" vertical>
            <Flex className="game-protocol-lineups" vertical gap={12}>
              <div className="time-name">{t('1ST HALF')}</div>
              {protocolIsLoading &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((el: number) => (
                  <Flex
                    key={`skel-${el}`}
                    className={`placement-row-container ${el === 1 ? 'active' : ''}`}
                    justify="space-between"
                    align="center"
                    style={{
                      opacity: 1 / el,
                    }}
                  >
                    <Skeleton.Button
                      active
                      shape="round"
                      style={{
                        maxWidth: 45,
                        minWidth: 45,
                        maxHeight: 16,
                        display: 'block',
                      }}
                    />
                    <Skeleton.Button
                      active
                      shape="round"
                      style={{ maxWidth: 35, minWidth: 35, maxHeight: 16 }}
                    />
                    <Skeleton.Button
                      active
                      shape="round"
                      style={{ maxWidth: 45, minWidth: 45, maxHeight: 16 }}
                    />
                  </Flex>
                ))}
              {placements
                .filter((el: any) => el.time === '1st period')
                .map((placement: any) => (
                  <Flex
                    key={placement.id}
                    className={`placement-row-container enabledClickable ${activePlacement.home_team === placement.home_team && activePlacement.away_team === placement.away_team && activePlacement.started_at === placement.started_at && activePlacement.finished_at === placement.finished_at ? 'active' : ''}`}
                    justify="space-between"
                    align="center"
                    onClick={() => setActivePlacement(placement)}
                  >
                    <div className="placement-name">{placement.home_team}</div>
                    <Flex>
                      <div className="placement-time">
                        {placement.from_minute}’
                      </div>
                      <div className="placement-time">—</div>
                      <div className="placement-time">
                        {placement.to_minute}’
                      </div>
                    </Flex>
                    <div className="placement-name">{placement.away_team}</div>
                  </Flex>
                ))}
              {!protocolIsLoading && (
                <div className="time-name">{t('2ND HALF')}</div>
              )}
              {placements
                .filter((el: any) => el.time === '2nd period')
                .map((placement: any) => (
                  <Flex
                    key={placement.id}
                    className={`placement-row-container enabledClickable ${activePlacement.home_team === placement.home_team && activePlacement.away_team === placement.away_team && activePlacement.started_at === placement.started_at && activePlacement.finished_at === placement.finished_at ? 'active' : ''}`}
                    justify="space-between"
                    align="center"
                    onClick={() => setActivePlacement(placement)}
                  >
                    <div className="placement-name">{placement.home_team}</div>
                    <Flex>
                      <div className="placement-time">
                        {placement.from_minute}’
                      </div>
                      <div className="placement-time">—</div>
                      <div className="placement-time">
                        {placement.to_minute}’
                      </div>
                    </Flex>
                    <div className="placement-name">{placement.away_team}</div>
                  </Flex>
                ))}
            </Flex>
          </Flex>
          <Flex
            vertical
            justify="flex-start"
            style={{
              background: 'var(--colorBgContainer)',
            }}
          >
            <Title
              onClose={onClose}
              activePlacement={activePlacement}
              gameProtocol={gameProtocol}
            />
            <Flex
              style={{
                position: 'relative',
              }}
            >
              <>
                <Flex
                  style={{
                    position: 'absolute',
                    zIndex: 20,
                    top: 'calc(50% - 35px)',
                    left: 'calc(50% - 25px)',
                  }}
                >
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBgContainer: 'transparent',
                      },
                    }}
                  >
                    <Spin
                      spinning={protocolIsLoading}
                      size="large"
                      tip={t('Loading')}
                    >
                      <div
                        style={{ padding: '25px', background: 'tranparent' }}
                      ></div>
                    </Spin>
                  </ConfigProvider>
                </Flex>
                <div className="field-class-protocol" ref={fieldRef}></div>
                {fieldRef.current?.getBoundingClientRect().height > 0 &&
                  activePlacement?.placements?.home_team.positions.map(
                    (player: any, index: number) => (
                      <PlayerJerey
                        player={player}
                        color={gameProtocol?.homeTeam.color}
                        key={`jer-${index}`}
                        invert={false}
                        teamId={gameProtocol.homeTeam.id}
                      />
                    ),
                  )}
                {fieldRef.current?.getBoundingClientRect().height > 0 &&
                  activePlacement?.placements?.away_team.positions.map(
                    (player: any, index: number) => (
                      <PlayerJerey
                        player={player}
                        color={gameProtocol?.awayTeam.color}
                        key={`jer-${index}`}
                        invert={true}
                        teamId={gameProtocol.awayTeam.id}
                      />
                    ),
                  )}
              </>
            </Flex>
            <Flex
              gap={16}
              flex={1}
              style={{ margin: '16px 24px', overflowY: 'auto' }}
            >
              <Flex vertical flex={1}>
                {protocolIsLoading &&
                  [1, 2, 3, 4].map((el: number) => (
                    <Flex
                      key={`subst-${el}`}
                      gap={4}
                      style={{
                        opacity: 1 / el,
                        padding: '13px 0px 13px 0px',
                        width: '100%',
                        borderBottom:
                          '1px solid var(--colorBorderSecondary, rgba(0, 0, 0, 0.06))',
                      }}
                      align="center"
                    >
                      <Flex
                        vertical
                        align="center"
                        justify="space-between"
                        style={{ height: '100%' }}
                      >
                        <PlayerInIcon />
                        <span className="time-subst">
                          {/* {mainPlayer.substitution.time}’ */}
                        </span>
                        <PlayerOutIcon />
                      </Flex>
                      <Flex vertical gap={2} flex={1} justify="center">
                        <Flex align="center" gap={8}>
                          <Skeleton.Button
                            active
                            style={{
                              minWidth: 24,
                              maxWidth: 24,
                              minHeight: 22,
                              maxHeight: 22,
                            }}
                          />
                          <Flex flex={1} gap={8} justify="space-between">
                            <Skeleton.Button
                              shape="round"
                              active
                              style={{
                                minWidth: 92,
                                maxWidth: 92,
                                minHeight: 16,
                                maxHeight: 16,
                              }}
                            />
                          </Flex>
                        </Flex>
                        <Flex align="center" justify="space-between" gap={8}>
                          <Skeleton.Button
                            active
                            style={{
                              minWidth: 24,
                              maxWidth: 24,
                              minHeight: 22,
                              maxHeight: 22,
                            }}
                          />
                          <Flex flex={1} gap={8} justify="space-between">
                            <Skeleton.Button
                              shape="round"
                              active
                              style={{
                                minWidth: 74,
                                maxWidth: 74,
                                minHeight: 12,
                                maxHeight: 12,
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}
                {gameProtocol?.homeTeam?.substitutions?.map(
                  (mainPlayer: any, index: number) => (
                    <Flex
                      key={`subst-${index}`}
                      gap={4}
                      style={{
                        padding: '13px 0px 13px 0px',
                        width: '100%',
                        borderBottom:
                          '1px solid var(--colorBorderSecondary, rgba(0, 0, 0, 0.06))',
                      }}
                      align="center"
                    >
                      <Flex
                        vertical
                        align="center"
                        justify="space-between"
                        style={{ height: '100%' }}
                      >
                        <PlayerInIcon />
                        <span className="time-subst">
                          {mainPlayer.substitution.time}’
                        </span>
                        <PlayerOutIcon />
                      </Flex>
                      <Flex vertical gap={2} flex={1} justify="center">
                        <Flex align="center" gap={8}>
                          <a
                            className="player-number"
                            href={`/players/${mainPlayer.id}`}
                            target="_blank"
                          >
                            {mainPlayer.number}
                          </a>
                          <Flex flex={1} gap={8} justify="space-between">
                            <a
                              style={{
                                color: 'var(--colorTextHeading)',
                                maxWidth: 150,
                              }}
                              className="elipsis-text enabledClickable"
                              href={`/players/${mainPlayer.id}`}
                              target="_blank"
                            >
                              {mainPlayer.first_name} {mainPlayer.last_name}
                            </a>
                            <Flex vertical={false} gap={4}>
                              {mainPlayer.events.map(
                                (
                                  mainPlayerEvt: any,
                                  mainPlayerEvtIndex: number,
                                ) => (
                                  <Flex
                                    align="center"
                                    gap={4}
                                    justify="flex-end"
                                    className="enabledClickable"
                                    key={`evt-${mainPlayerEvtIndex}`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      goToEvent(
                                        [
                                          mainPlayerEvt.type
                                            .replace('out', 'substitution')
                                            .replace('in', 'substitution'),
                                        ],
                                        mainPlayer.id,
                                        gameProtocol.homeTeam.id,
                                      );
                                    }}
                                  >
                                    <span className="time-subst">
                                      {mainPlayerEvt.time}’{' '}
                                    </span>
                                    <Flex
                                      style={{ width: 16 }}
                                      justify="center"
                                    >
                                      {EVENT_ICONS[mainPlayerEvt.type]}
                                    </Flex>
                                  </Flex>
                                ),
                              )}
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex align="center" justify="space-between" gap={8}>
                          <a
                            className="player-number"
                            href={`/players/${mainPlayer.substitution.id}`}
                            target="_blank"
                          >
                            {mainPlayer.substitution.number}
                          </a>
                          <Flex flex={1} gap={8} justify="space-between">
                            <a
                              className="subst-player-name elipsis-text enabledClickable"
                              href={`/players/${mainPlayer.substitution.id}`}
                              target="_blank"
                            >
                              {mainPlayer.substitution.first_name}{' '}
                              {mainPlayer.substitution.last_name}
                            </a>
                            <Flex vertical={false} gap={4}>
                              {mainPlayer.substitution.events.map(
                                (substEvt: any, substIndex: number) => (
                                  <Flex
                                    align="center"
                                    gap={4}
                                    justify="flex-end"
                                    className="enabledClickable"
                                    key={`evt-a-${substIndex}`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      goToEvent(
                                        [
                                          substEvt.type
                                            .replace('out', 'substitution')
                                            .replace('in', 'substitution'),
                                        ],
                                        mainPlayer.substitution.id,
                                        gameProtocol.homeTeam.id,
                                      );
                                    }}
                                  >
                                    <span className="time-subst">
                                      {substEvt.time}’{' '}
                                    </span>
                                    <Flex
                                      style={{ width: 16 }}
                                      justify="center"
                                    >
                                      {EVENT_ICONS[substEvt.type]}
                                    </Flex>
                                  </Flex>
                                ),
                              )}
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  ),
                )}
              </Flex>
              <Flex vertical flex={1}>
                {protocolIsLoading &&
                  [1, 2, 3, 4].map((el: number) => (
                    <Flex
                      key={`subst-${el}`}
                      gap={4}
                      style={{
                        opacity: 1 / el,
                        padding: '13px 0px 13px 0px',
                        width: '100%',
                        borderBottom:
                          '1px solid var(--colorBorderSecondary, rgba(0, 0, 0, 0.06))',
                      }}
                      align="center"
                    >
                      <Flex
                        vertical
                        align="center"
                        justify="space-between"
                        style={{ height: '100%' }}
                      >
                        <PlayerInIcon />
                        <span className="time-subst">
                          {/* {mainPlayer.substitution.time}’ */}
                        </span>
                        <PlayerOutIcon />
                      </Flex>
                      <Flex vertical gap={2} flex={1} justify="center">
                        <Flex align="center" gap={8}>
                          <Skeleton.Button
                            active
                            style={{
                              minWidth: 24,
                              maxWidth: 24,
                              minHeight: 22,
                              maxHeight: 22,
                            }}
                          />
                          <Flex flex={1} gap={8} justify="space-between">
                            <Skeleton.Button
                              shape="round"
                              active
                              style={{
                                minWidth: 92,
                                maxWidth: 92,
                                minHeight: 16,
                                maxHeight: 16,
                              }}
                            />
                          </Flex>
                        </Flex>
                        <Flex align="center" justify="space-between" gap={8}>
                          <Skeleton.Button
                            active
                            style={{
                              minWidth: 24,
                              maxWidth: 24,
                              minHeight: 22,
                              maxHeight: 22,
                            }}
                          />
                          <Flex flex={1} gap={8} justify="space-between">
                            <Skeleton.Button
                              shape="round"
                              active
                              style={{
                                minWidth: 74,
                                maxWidth: 74,
                                minHeight: 12,
                                maxHeight: 12,
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}
                {gameProtocol?.awayTeam?.substitutions?.map(
                  (subst: any, index: number) => (
                    <Flex
                      key={`sub-x-${index}`}
                      gap={4}
                      style={{
                        padding: '13px 0px 13px 0px',
                        width: '100%',
                        borderBottom:
                          '1px solid var(--colorBorderSecondary, rgba(0, 0, 0, 0.06))',
                      }}
                      align="center"
                    >
                      <Flex
                        vertical
                        align="center"
                        justify="space-between"
                        style={{ height: '100%' }}
                      >
                        <PlayerInIcon />
                        <span className="time-subst">
                          {subst.substitution.time}’
                        </span>
                        <PlayerOutIcon />
                      </Flex>
                      <Flex vertical gap={2} flex={1} justify="space-between">
                        <Flex align="center" gap={8}>
                          <a
                            className="player-number"
                            href={`/players/${subst.id}`}
                            target="_blank"
                          >
                            {subst.number}
                          </a>
                          <Flex flex={1} gap={8} justify="space-between">
                            <a
                              style={{
                                color: 'var(--colorTextHeading)',
                                maxWidth: 150,
                              }}
                              className="elipsis-text enabledClickable"
                              href={`/players/${subst.id}`}
                              target="_blank"
                            >
                              {subst.first_name} {subst.last_name}
                            </a>
                            <Flex vertical={false} gap={4}>
                              {subst.events.map((evt: any, index: number) => (
                                <Flex
                                  align="center"
                                  gap={4}
                                  justify="flex-end"
                                  className="enabledClickable"
                                  key={`evt-x-${index}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    goToEvent(
                                      [
                                        evt.type
                                          .replace('out', 'substitution')
                                          .replace('in', 'substitution'),
                                      ],
                                      subst.id,
                                      gameProtocol.awayTeam.id,
                                    );
                                  }}
                                >
                                  <span className="time-subst">
                                    {evt.time}’{' '}
                                  </span>
                                  <Flex style={{ width: 16 }} justify="center">
                                    {EVENT_ICONS[evt.type]}
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex align="center" justify="space-between" gap={8}>
                          <a
                            className="player-number"
                            href={`/players/${subst.substitution.id}`}
                            target="_blank"
                          >
                            {subst.substitution.number}
                          </a>
                          <Flex flex={1} gap={8} justify="space-between">
                            <a
                              className="subst-player-name elipsis-text"
                              href={`/players/${subst.substitution.id}`}
                              target="_blank"
                            >
                              {subst.substitution.first_name}{' '}
                              {subst.substitution.last_name}
                            </a>
                            <Flex vertical={false} gap={4}>
                              {subst.substitution.events.map(
                                (evt: any, index: number) => (
                                  <Flex
                                    align="center"
                                    gap={4}
                                    justify="flex-end"
                                    className="enabledClickable"
                                    key={`evt-b-${index}`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      goToEvent(
                                        [
                                          evt.type
                                            .replace('out', 'substitution')
                                            .replace('in', 'substitution'),
                                        ],
                                        subst.substitution.id,
                                        gameProtocol.awayTeam.id,
                                      );
                                    }}
                                  >
                                    <span className="time-subst">
                                      {evt.time}’{' '}
                                    </span>
                                    <Flex
                                      style={{ width: 16 }}
                                      justify="center"
                                    >
                                      {EVENT_ICONS[evt.type]}
                                    </Flex>
                                  </Flex>
                                ),
                              )}
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  ),
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ConfigProvider>
    </Drawer>
  );
};
export default GameProtocolSidePanel;
