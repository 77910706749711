import React, { FC, useLayoutEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SetViewCalendarEvent } from 'actions/calendar.actions';
import { ReactComponent as BlueDot } from 'assets/img/BlueDot.svg';
import { ReactComponent as GreenDot } from 'assets/img/GreenDot.svg';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { constructTimeWithZoneShift } from 'types/functions';
import { CalendarDayEventType, DateType, EventType } from 'types/types';
import './index.css';

interface DayBlockInterface {
  day: DateType;
  currentMonth: number;
  actualYear: number;
  dayHeight: number | undefined;
  todayDate: any;
  weeks: number;
  actualMonth: number;
  onClick?: any;
  setModalEvents: any;
  clearModalEvents: any;
  setReferencedDay: any;
  weeksShown: number;
}
const DayBlock: FC<DayBlockInterface> = ({
  day,
  currentMonth,
  dayHeight,
  todayDate,
  actualMonth,
  actualYear,
  onClick,
  setModalEvents,
  clearModalEvents,
  setReferencedDay,
  weeksShown,
}) => {
  const { activeDate, viewedEvent } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );
  const [dayEvents, setDayEvents] = useState<Array<CalendarDayEventType>>();
  const showEventsCount = dayHeight && dayHeight > 113 ? 3 : 2;
  const showAllEvents = () => {
    setModalEvents(dayEvents);
    setReferencedDay(currentDayRef.current.getBoundingClientRect());
    // dispatch(ClearViewCalendarEvent());
  };
  const events = useSelector(
    (state: AppStateType) => state.calendarReducer.events,
  );

  useLayoutEffect(() => {
    // console.log('dailyEvents Effect', events)
    const dailyEvents = events
      .filter(
        (evt: CalendarDayEventType) =>
          evt.date.day === day.date &&
          evt.date.month === day.month &&
          evt.date.year === day.year,
      )
      .sort((a, b) => {
        if (a.date.startHour !== b.date.startHour) {
          return a.date.startHour - b.date.startHour;
        }
        return a.date.startMinute - b.date.startMinute;
      });
    // console.log('dailyEvents Effect', dailyEvents)
    setDayEvents(dailyEvents);
  }, [events]);

  const eventsRefs = useRef<
    Array<CalendarDayEventType | HTMLDivElement | null>
  >([]);
  useLayoutEffect(() => {
    eventsRefs.current = [];
  }, [day]);

  const dispatch = useAppDispatch();
  const currentDayRef = useRef<any>(null);
  const handleIntraDayDoubleClick = (e: any) => {
    // console.log('double click inner')
    clearModalEvents();
    if (e.target !== currentDayRef.current) {
      if (
        dayEvents &&
        dayEvents?.length > showEventsCount &&
        (showMoreRef.current === e.target ||
          e.target.parentNode === showMoreRef.current)
      ) {
        showAllEvents();
      }
    } else {
      onClick(e);
    }
  };
  const handleIntraDayClick = (e: any) => {
    console.log();
    clearModalEvents();
    if (e.target !== currentDayRef.current) {
      if (
        dayEvents &&
        dayEvents?.length > showEventsCount &&
        (showMoreRef.current === e.target ||
          e.target.parentNode === showMoreRef.current)
      ) {
        showAllEvents();
      }
    } else {
      onClick(e);
    }
  };
  const showMoreRef = useRef<any>(null);
  const openEventForEdit = (evt: CalendarDayEventType) => {
    const element = document.getElementById(`calendar-event-id-${evt.id}`);
    dispatch(
      SetViewCalendarEvent(evt, {
        ...element?.getBoundingClientRect().toJSON(),
      }),
    );
  };
  const [t] = useTranslation();
  return (
    <>
      <div
        className={`flex-column dateBlock ${day.month === currentMonth ? 'currentMonthDay' : 'otherMonthDay'}
                ${activeDate?.date === day.date && activeDate.month === day.month && activeDate.year === day.year ? 'activeDate' : ''}`}
        ref={currentDayRef}
        style={{ height: `calc(100% / ${weeksShown}` }}
        onClick={(e) => {
          if (e.detail === 2) {
            handleIntraDayDoubleClick(e);
          } else {
            handleIntraDayClick(e);
          }
        }}
      >
        <div
          className={`dayNumber ${todayDate === day.date && day.month === actualMonth && day.year === actualYear ? 'currentDay' : ''}`}
        >
          {day.date}
        </div>
        {dayEvents
          ?.slice(0, showEventsCount)
          .map((evt: CalendarDayEventType, index) => {
            return (
              <div key={index}>
                <div
                  key={index}
                  ref={(el) => {
                    if (!eventsRefs.current.includes(el)) {
                      eventsRefs.current.push(el);
                    }
                  }}
                  className={`flex-row dayEventsContainer ${
                    evt.id === viewedEvent?.id ? 'viewedEvent' : ''
                  }`}
                  id={`calendar-event-id-${evt.id}`}
                  data-tooltip-id={'evtTooltipName'}
                  data-tooltip-content={evt.name}
                  data-tooltip-place="right"
                  onClick={() => {
                    openEventForEdit(evt);
                  }}
                >
                  {evt.type === EventType.game ? <GreenDot /> : <BlueDot />}
                  <div>{constructTimeWithZoneShift(evt)}</div>
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {evt.name}
                  </div>
                  <div className="gradientRight" />
                </div>
              </div>
            );
          })}
        {dayEvents && dayEvents?.length > showEventsCount && (
          <div
            ref={showMoreRef}
            className={'flex-row enabledClickable'}
            style={{ marginLeft: '4px', color: 'var(--new-main, #3A78F1)' }}
            onClick={() => showAllEvents()}
          >
            <a>
              {t('more')} {dayEvents?.length - showEventsCount}...
            </a>
          </div>
        )}
      </div>
    </>
  );
};
export default DayBlock;
