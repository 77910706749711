import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  tableMatches: [];
  teamStatsMatches: any; // консолидированые данные по сезонам/турниру/играм игрока для статистики
  appliedLastMatch: number | string | null;
  selectedSeason: any;
  selectedTournaments: string[]; // список id выбранных турниров в модалке статистики
  selectedRowKeys: string[] | undefined; // список id игр, выбранных в таблице статистики
  selectedMatchesStats: any[];

  appliedMatches: string[]; // список id игр, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  checkedSeasons: string[]; // список id выбранных сезонов в модалке статистики
  appliedSeasons: string[]; // список id сезонов, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  appliedTournaments: string[]; // список id турниров, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  selectedMatches: any[];
  teamMatchesStatsState: string;
  expandedSeasons: string[]; // список раскрытых сезонов в дереве модалки

  selectedLastMatch: number | string | null;
  teamSeasonsList: any[];
  isTeamLineupPopupOpen: boolean;
  teamLineupLoadingState: boolean;
};
const initialState: InitialStateType = {
  teamStatsMatches: [],
  tableMatches: [],
  appliedLastMatch: null,
  selectedMatchesStats: [],
  selectedSeason: null,
  selectedTournaments: [],
  selectedRowKeys: [],
  appliedMatches: [],
  checkedSeasons: [],
  appliedSeasons: [],
  appliedTournaments: [],
  selectedMatches: [],
  teamMatchesStatsState: 'loaded',
  expandedSeasons: [],
  selectedLastMatch: null,

  teamSeasonsList: [],
  isTeamLineupPopupOpen: false,
  teamLineupLoadingState: false,
};
export const teamLineupsReducer = createSlice({
  name: 'teamLineupsReducer',
  initialState: initialState,
  reducers: {
    setSelectedTeamLineupsSeason(state, action) {
      state.selectedSeason = action.payload;
    },
    setIsTeamLineupPopupOpen(state, action) {
      state.isTeamLineupPopupOpen = action.payload;
    },
    setSelectedLastMatch(state, action) {
      state.selectedLastMatch = action.payload;
    },
    setTableMatches(state, action) {
      state.tableMatches = action.payload;
    },
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setCheckedSeasons(state, action) {
      state.checkedSeasons = action.payload;
    },
    setSelectedTournaments(state, action) {
      state.selectedTournaments = action.payload;
    },
    setSelectedMatches(state, action) {
      state.selectedMatches = action.payload;
    },
    setExpandedSeasons(state, action) {
      state.expandedSeasons = action.payload;
    },
    setTeamStatsMatches(state, action) {
      state.teamStatsMatches = action.payload;
    },
    setSelectedMatchesStats(state, action) {
      state.selectedMatchesStats = action.payload;
    },
    setTeamLineupLoadingState(staet, action) {
      staet.teamLineupLoadingState = action.payload;
    },
  },
});
