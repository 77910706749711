import React from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';

const PeriodLabel = ({ name, isLight }: { name: string; isLight: boolean }) => {
  const [t] = useTranslation();
  if (name.slice(0, 1) === '1' || name.slice(0, 1) === '2') {
    return (
      <div className={`period-name ${isLight ? 'light' : ''}`}>
        {Array.from(name)[0]}
        <Flex
          align="flex-end"
          style={{
            fontSize: 6,
            lineHeight: '12px',
            fontFamily: 'SFProTextSemiBold',
          }}
        >
          {t('period_letter_short')}
        </Flex>
      </div>
    );
  }
  if (name.slice(-1) === '1' || name.slice(-1) === '2') {
    return (
      <div className={`period-name ${isLight ? 'light' : ''}`}>
        {Array.from(name)[0]}
        <sup style={{ fontSize: 7, lineHeight: '10px' }}>
          {t('added_period_letter_short')}
        </sup>
      </div>
    );
  }
  return <div className={`period-name ${isLight ? 'light' : ''}`}>{name}</div>;
};
export default PeriodLabel;
