/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Flex } from 'antd';

import TeamMetrics from './TeamMetrics';

const MatchStatsContainer = ({
  match,
  isExpanded,
}: {
  match: any;
  isExpanded: boolean;
}) => {
  return (
    <div
      style={{
        overflow: 'hidden',
      }}
      className={`${isExpanded ? 'expanded-match' : 'collapsed-match'}`}
    >
      <div
        style={{ height: 8, backgroundColor: '#F2F9FF', width: '100%' }}
      ></div>
      <Flex className={'match-skills-container'}>
        <TeamMetrics match={match} />
      </Flex>
    </div>
  );
};
export default MatchStatsContainer;
