/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { ConfigProvider, Flex, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import Comet from './Comet';
import CometFieldBlock from './CometFieldBlock';

const MapWithComets = ({
  fromFieldEventsDetails,
  toFieldEventsDetails,
  as_point,
  playerProfileMode,
}: {
  fromFieldEventsDetails: any;
  toFieldEventsDetails: any;
  as_point: boolean;
  playerProfileMode: string;
}) => {
  const { openedTeamPlayer } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });
  const fieldRef = useRef<any>(null);
  const {
    setDisplayedComets,
    setSelectedFromFieldsCells,
    setMatchedPointEvents,
  } = teamPlayerStatsSectionReducer.actions;
  const {
    multitoolEvents,
    displayedComets,
    selectedFromFieldCells,
    selectedToFieldCells,
    selectedStat,
    multitoolEventsLoading,
    selectedEventsOrComets,
  } = useSelector((state: AppStateType) => {
    return state.teamPlayerStatsSectionReducer;
  });
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [dragSelectedIds, setDragSelectedIds] = useState<number[]>([]);
  const FIELD_CELLS: any = [
    [{ id: 5 }, { id: 10 }, { id: 15 }, { id: 20 }, { id: 25 }, { id: 30 }],
    [{ id: 4 }, { id: 9 }, { id: 14 }, { id: 19 }, { id: 24 }, { id: 29 }],
    [{ id: 3 }, { id: 8 }, { id: 13 }, { id: 18 }, { id: 23 }, { id: 28 }],
    [{ id: 2 }, { id: 7 }, { id: 12 }, { id: 17 }, { id: 22 }, { id: 27 }],
    [{ id: 1 }, { id: 6 }, { id: 11 }, { id: 16 }, { id: 21 }, { id: 26 }],
  ];
  const FIELD_CELLS_FOOTZAL: any = [
    // [{ id: 5 }, { id: 10 }, { id: 15 }, { id: 20 }, { id: 25 }, { id: 30 }],
    // [{ id: 4 }, { id: 9 }, { id: 14 }, { id: 19 }, { id: 24 }, { id: 29 }],
    [{ id: 3 }, { id: 6 }, { id: 9 }, { id: 12 }],
    [{ id: 2 }, { id: 5 }, { id: 8 }, { id: 11 }],
    [{ id: 1 }, { id: 4 }, { id: 7 }, { id: 10 }],
  ];
  const [usedFieldCells, setUsedFieldCells] = useState<any>([]);
  useEffect(() => {
    if (openedTeamPlayer?.team?.sport === 2) {
      setUsedFieldCells(FIELD_CELLS_FOOTZAL);
    } else {
      setUsedFieldCells(FIELD_CELLS);
    }
  }, [openedTeamPlayer]);
  useEffect(() => {
    let final: any[] = [];
    if (selectedFromFieldCells.length > 0 && selectedToFieldCells.length > 0) {
      final = final = multitoolEvents
        .filter(
          (evt: any) =>
            selectedFromFieldCells
              .map((cellId: number) => fromFieldEventsDetails[cellId])
              .filter((cell: any) => cell?.events.includes(evt.id)).length,
        )
        .filter(
          (evt: any) =>
            selectedToFieldCells
              .map((cellId: number) => toFieldEventsDetails[cellId])
              .filter((cell: any) => cell?.events.includes(evt.id)).length,
        );
    } else if (selectedFromFieldCells.length || selectedToFieldCells.length) {
      if (selectedFromFieldCells) {
        final = multitoolEvents?.filter(
          (evt: any) =>
            selectedFromFieldCells
              .map((cellId: number) => fromFieldEventsDetails[cellId])
              .filter((cell: any) => cell?.events.includes(evt.id)).length,
        );
      }
      if (selectedToFieldCells) {
        final = [
          ...final,
          ...multitoolEvents
            .filter(
              (evt: any) =>
                selectedToFieldCells
                  .map((cellId: number) => toFieldEventsDetails[cellId])
                  .filter((cell: any) => cell?.events.includes(evt.id)).length,
            )
            .filter(
              (el) =>
                final.filter((elInner) => elInner.id === el.id).length === 0,
            ),
        ];
      }
    } else {
      final = multitoolEvents;
    }
    dispatch(setDisplayedComets(final));
  }, [selectedFromFieldCells, selectedToFieldCells, multitoolEvents]);
  const [bounds, setBounds] = useState({});
  useEffect(() => {
    if (fieldRef.current) {
      setBounds(fieldRef.current.getBoundingClientRect());
    }
  }, [selectedStat, playerProfileMode]);

  const [isSelecting, setIsSelecting] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [rect, setRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const toggleMultipleFieldCells = async (cellIds: number[]) => {
    const final: number[] = [];
    const forRemoval: number[] = [];
    cellIds.forEach((cellId) => {
      if (!selectedFromFieldCells.includes(cellId)) {
        final.push(cellId);
      } else {
        forRemoval.push(cellId);
      }
    });
    const updated = [
      ...final,
      ...selectedFromFieldCells.filter((el) => forRemoval.includes(el)),
    ];
    await dispatch(setSelectedFromFieldsCells(updated));
    const finalEventIds = multitoolEvents.filter(
      (evt: any) =>
        updated
          .map((cellId: number) => fromFieldEventsDetails[cellId])
          .filter(
            (cell: any) => cell && cell.events && cell.events.includes(evt.id),
          ).length,
    );
    await dispatch(setMatchedPointEvents(finalEventIds));
  };
  useEffect(() => {
    // Mouse move and mouse up listeners on window, so we can track user’s drag
    // even if they move off the component bounds
    const handleMouseMove = (e: any) => {
      if (!isSelecting) {
        return;
      }
      let currentX = e.clientX - fieldRef.current.getBoundingClientRect().x;
      if (currentX > fieldRef.current.getBoundingClientRect().width) {
        currentX = fieldRef.current.getBoundingClientRect().width + 1;
      }
      let currentY = e.clientY - fieldRef.current.getBoundingClientRect().y;
      if (currentY > fieldRef.current.getBoundingClientRect().height) {
        currentY = fieldRef.current.getBoundingClientRect().height + 1;
      }

      const newX = Math.min(startPos.x, Math.max(currentX, -1));
      const newY = Math.min(startPos.y, currentY >= -1 ? currentY : -1);
      const newWidth = Math.abs(Math.max(currentX, -1) - startPos.x);
      const newHeight =
        currentY >= -1 ? Math.abs(currentY - startPos.y) : startPos.y;

      setRect({ x: newX, y: newY, width: newWidth, height: newHeight });
    };

    const handleMouseUp = async (e: any) => {
      e.stopPropagation();
      if (isSelecting) {
        if (rect.width && rect.height) {
          await toggleMultipleFieldCells(dragSelectedIds);
        }
        setDragSelectedIds([]);
        setStartPos({ x: 0, y: 0 });
        setRect({ x: 0, y: 0, width: 0, height: 0 });
        setIsSelecting(false);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isSelecting, startPos, dragSelectedIds]);

  const handleMouseDown = (e: any) => {
    // Only start selection if, for example, left button is pressed
    if (e.button !== 0) {
      return;
    }
    e.stopPropagation();
    setIsSelecting(true);
    setStartPos({
      x: e.clientX - fieldRef.current.getBoundingClientRect().x,
      y: e.clientY - fieldRef.current.getBoundingClientRect().y,
    });
    setRect({
      x: e.clientX - fieldRef.current.getBoundingClientRect().x,
      y: e.clientY - fieldRef.current.getBoundingClientRect().y,
      width: 0,
      height: 0,
    });
  };
  return (
    <Flex>
      <Flex vertical gap={6} style={{ width: '100%' }}>
        <div
          ref={fieldRef}
          className={`${openedTeamPlayer?.team?.sport === 2 ? 'field-footzal-class' : 'field-class'} ${multitoolEventsLoading ? '' : ''}`}
          style={{ position: 'relative' }}
          onMouseDown={handleMouseDown}
        >
          <div
            style={{
              position: 'absolute',
              top: rect.y,
              left: rect.x,
              width: rect.width,
              height: rect.height,
              backgroundColor: 'rgba(69, 151, 248, 0.1)',
              border: rect.width ? '1px solid #4597F8' : 'none',
              pointerEvents: 'none',
            }}
          />
          {usedFieldCells.map((row: any, index: any) => {
            return (
              <Row
                key={`comets-${index}`}
                style={{
                  height:
                    openedTeamPlayer?.team?.sport === 2 ? '33.33%' : '20%',
                }}
              >
                {row.map((cell: any) => {
                  return (
                    <CometFieldBlock
                      key={`comets-${cell.id}`}
                      cell={cell}
                      fromFieldEventsDetails={fromFieldEventsDetails}
                      as_point={as_point}
                      rect={rect}
                      fieldRef={fieldRef}
                      isSelecting={isSelecting}
                      setDragSelectedIds={setDragSelectedIds}
                      widthSpan={openedTeamPlayer?.team?.sport === 2 ? 6 : 4}
                    />
                  );
                })}
              </Row>
            );
          })}
          <Flex
            style={{
              position: 'absolute',
              zIndex: 20,
              top: 'calc(50% - 15px)',
              left: 'calc(50% - 25px)',
            }}
          >
            <ConfigProvider>
              <Spin
                spinning={multitoolEventsLoading}
                // spinning={true}
                size="large"
                tip={t('Loading')}
              >
                <div style={{ padding: '25px' }}></div>
              </Spin>
            </ConfigProvider>
          </Flex>
          {as_point
            ? multitoolEvents.map((comet: any) => (
                <Comet
                  key={comet.id}
                  comet={comet}
                  bounds={fieldRef}
                  asPoint={as_point}
                  selectionRectangle={rect}
                  isSelectedState={selectedEventsOrComets.includes(comet.id)}
                  selectedCount={selectedEventsOrComets.length}
                  playerProfileMode={playerProfileMode}
                />
              ))
            : displayedComets.map((comet: any) => (
                <Comet
                  key={comet.id}
                  comet={comet}
                  bounds={fieldRef}
                  asPoint={as_point}
                  selectionRectangle={rect}
                  isSelectedState={selectedEventsOrComets.includes(comet.id)}
                  selectedCount={selectedEventsOrComets.length}
                  playerProfileMode={playerProfileMode}
                />
              ))}
        </div>
      </Flex>
    </Flex>
  );
};
export default MapWithComets;
