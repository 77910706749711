/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';

import { Badge, ConfigProvider, Divider, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AC_SetPlayerCommandAction,
  AC_SetPlayerHotkeysAllowedAction,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { AddEpisodesToPlaylistAction } from 'actions/playlistV2.async.actions';
import { ReactComponent as NarrowScreenMode } from 'assets/img/icons/faCloseWidescreenMode.svg';
import { ReactComponent as FullScreeExpand } from 'assets/img/icons/faExpandFullScreenIcon.svg';
import { ReactComponent as NewEpisodeIcon } from 'assets/img/icons/faNewEpisode.svg';
import { ReactComponent as Reels } from 'assets/img/icons/faReelsIcon.svg';
// import { ReactComponent as Scissors } from 'assets/img/icons/faScisorsIcon.svg';
import { ReactComponent as SettingsGear } from 'assets/img/icons/faSettingsGear.svg';
import { ReactComponent as SoundIcon } from 'assets/img/icons/faSoundIcon.svg';
import { ReactComponent as SoundZeroIcon } from 'assets/img/icons/faSoundZeroIcon.svg';
import { ReactComponent as AddToPlaylistIcon } from 'assets/img/icons/faStarWithPlusGrey.svg';
import './index.css';
// import EditorIconContext from 'components/contexts/EditorIconContext';
import VideoPlayerSettingsContext from 'components/contexts/VideoPlayerSettingsContext';
import VideoSizeSelectorContext from 'components/contexts/VideoSizeSelectorContext';
import VideoSoundContext from 'components/contexts/VideoSoundControllerContext';
import VideoSpeedContext from 'components/contexts/VideoSpeedContext';
import SelectPlaylistPopoverContent from 'components/modals/SelectPlaylistPopoverContent';
import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';
import { useAppDispatch } from 'store';
import { PlayCommandType } from 'types/types';
import { initTimer } from 'utils/functions';

interface HelperPlayerButtonsInterface {
  isNarrowScreenMode: boolean;
  toggleFullScreen: any;
  playbackRate: any;
  setPlaybackRate: any;
  volume: number;
  setVolume: any;
  setIsMuted: any;
  playerState: any;
  setRewindSpeed: any;
  rewindSpeed: any;
}

const HelperPlayerButtons: FC<HelperPlayerButtonsInterface> = ({
  isNarrowScreenMode,
  toggleFullScreen,
  playbackRate,
  setPlaybackRate,
  volume,
  setVolume,
  setIsMuted,
  playerState,
  setRewindSpeed,
  rewindSpeed,
}) => {
  const [savedVolume, setSavedVolume] = useState(0.5);
  const setVolumeCallback = (newVolume: number) => {
    setIsMuted(false);
    setVolume(newVolume);
  };
  const { setAddToPlaylistPopoverIsOpen } = playlistReducerV2.actions;
  const { addToPlaylistPopoverIsOpen } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { reelsModeOn } = useSelector((state: AppStateType) => {
    return state.reelsReducer;
  });
  const { toggleWideScreenMode } = playerReducerV2.actions;
  const dispatch = useAppDispatch();
  const {
    createEpisodeMode,
    videosListEditMode,
    playedEpisode,
    playedVideoSet,
  } = useSelector((state: AppStateType) => {
    return state.playerReducerV2;
  });
  const { activeVideo } = useSelector((state: AppStateType) => {
    return state.playerReducer;
  });
  const { currentUser } = useSelector((state: AppStateType) => {
    return state.authReducer;
  });
  const { toggleReelsMode, resetReelsState } = reelsReducer.actions;
  const { setCreateEpisodeMode } = playerReducerV2.actions;
  const {
    setEditedEpisodeRange,
    setEditedEpisodeFileRange,
    setVideoListEditMode,
    resetEditMode,
  } = playerReducerV2.actions;
  const { isLoading } = useSelector((state: AppStateType) => {
    return state.playerReducer;
  });
  const closeCallback = () => {
    dispatch(setCreateEpisodeMode(false));
    dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    dispatch(resetEditMode());
    dispatch(AC_SetVisibleRangeAction([]));
    dispatch(setAddToPlaylistPopoverIsOpen(false));
  };
  const [t] = useTranslation();

  const addEpisodesToPlaylistCallback = (playlists: any[]) => {
    dispatch(
      AddEpisodesToPlaylistAction(playlists, [activeVideo?.id || ''], t),
    );
  };
  const [badgeValue, setBadgeValue] = useState('');
  useEffect(() => {
    // console.log(playedEpisode);
    // console.log(playedEpisode?.video.meta.width);
    // console.log(playedEpisode?.video.meta.width >= 1080);
    if (playedEpisode && playedEpisode.video?.meta?.width) {
      if (playedEpisode.video.meta.width >= 1080) {
        setBadgeValue('FHD');
      } else if (playedEpisode.video.meta.width >= 720) {
        setBadgeValue('HD');
      } else {
        setBadgeValue('SD');
      }
    } else {
      setBadgeValue('');
    }
  }, [playedEpisode]);
  const turnEditMode = () => {
    const episode = playedEpisode;
    if (episode && episode.episode_type !== 'period') {
      const eventId = episode.id;
      const period = playedVideoSet?.periods?.filter(
        (period: any) => period.name === playedEpisode?.period_name,
      )[0];
      if (!videosListEditMode) {
        const timerStart = initTimer(
          episode,
          'started_at',
          period,
          true,
          false,
        );
        const timerFinish = initTimer(
          episode,
          'finished_at',
          period,
          true,
          false,
        );
        const range = [timerStart, timerFinish];

        const fileRange = [
          initTimer(episode, 'started_at', period, false, true),
          initTimer(episode, 'finished_at', period, false, true),
        ];
        dispatch(setEditedEpisodeRange(range));
        dispatch(setEditedEpisodeFileRange(fileRange));
        dispatch(setVideoListEditMode(eventId));
        dispatch(AC_SetPlayerCommandAction(PlayCommandType.scrollTo));
      }
    }
  };
  return (
    <div className={'flex-row ai-c'} style={{ marginRight: '0px' }}>
      {playedEpisode?.episode_type !== 'period' &&
        currentUser?.custom_permissions.includes('can_cut_reels') && (
          <div
            className={`enabledClickable video-border-edit-icon activeButtonHover dark fill ${reelsModeOn ? 'activeButtonBottom fill' : ''}`}
          >
            <Reels
              width={34}
              height={34}
              style={{ padding: 5 }}
              onClick={() => {
                if (playerState && !isLoading) {
                  dispatch(toggleReelsMode());
                  if (reelsModeOn) {
                    dispatch(resetEditMode());
                    dispatch(AC_SetVisibleRangeAction([]));
                    dispatch(resetReelsState());
                  } else {
                    turnEditMode();
                  }
                  if (createEpisodeMode) {
                    dispatch(setCreateEpisodeMode(false));
                  }
                }
              }}
              className={'enabledClickable '}
            />
          </div>
        )}
      {playedEpisode?.episode_type !== 'period' && (
        <SelectPlaylistPopoverContent
          offset={[18, -30]}
          hideArrow={true}
          isOpen={addToPlaylistPopoverIsOpen}
          setIsOpen={setAddToPlaylistPopoverIsOpen}
          passPlaylist={true}
          wrappedComponent={
            <div
              className={
                'enabledClickable video-border-edit-icon activeButtonHover dark'
              }
              onClick={() => {
                dispatch(
                  setAddToPlaylistPopoverIsOpen(!addToPlaylistPopoverIsOpen),
                );
                // dispatch(
                //   setEpisodeIdsToAddToPlaylist({
                //     episodeIds: [playedEpisode?.id],
                //   }),
                // );
              }}
            >
              <AddToPlaylistIcon />
            </div>
          }
          closeAddEpisodeModeCallback={closeCallback}
          addUserEpisodeToPlaylist={addEpisodesToPlaylistCallback}
        />
      )}
      <div
        className={`enabledClickable activeButtonHover dark video-border-edit-icon ${createEpisodeMode ? 'activeButtonBottom' : ''}`}
        onClick={() => {
          if (
            !(activeVideo === null || !!videosListEditMode || createEpisodeMode)
          ) {
            dispatch(setCreateEpisodeMode(true));
          }
        }}
      >
        <NewEpisodeIcon />
      </div>
      <Divider
        type="vertical"
        style={{
          height: 24,
          marginLeft: 8,
          marginRight: 8,
          width: 1,
          background: '#424242',
        }}
      />
      <VideoSoundContext setVolumeCallback={setVolumeCallback} volume={volume}>
        <div
          onClick={() => {
            if (volume) {
              setSavedVolume(volume);
              setVolume(0);
              setIsMuted(true);
            } else {
              setIsMuted(false);
              setVolume(savedVolume);
            }
          }}
          className={`enabledClickable video-border-edit-icon activeButtonHover dark ${volume > 0 ? '' : 'fill'}`}
          style={{ width: 40, height: 40 }}
        >
          {volume > 0 ? <SoundIcon /> : <SoundZeroIcon />}
        </div>
      </VideoSoundContext>
      <VideoPlayerSettingsContext
        speedSetCallback={setPlaybackRate}
        rewindSpeedSetCallback={setRewindSpeed}
        speedValue={playbackRate}
        rewindValue={rewindSpeed}
      >
        <div
          className={
            'enabledClickable flex-row j-ce ai-c video-border-edit-icon activeButtonHover dark'
          }
        >
          <SettingsGear />
          {badgeValue && (
            <Space style={{ position: 'absolute' }}>
              <ConfigProvider
                theme={{
                  token: {
                    colorBorderBg: 'var(--colorBgContainer, #141414)',
                    paddingXS: 4,
                    lineHeight: 20,
                  },
                  components: {
                    Badge: {
                      textFontSize: 12,
                      indicatorHeightSM: 20,
                    },
                  },
                }}
              >
                <Badge
                  offset={[15, -22]}
                  size="small"
                  overflowCount={99}
                  color={' #15417E'}
                  count={badgeValue}
                  className="fhd-badge"
                ></Badge>
              </ConfigProvider>
            </Space>
          )}
        </div>
      </VideoPlayerSettingsContext>
      <div
        // className={`${false ? 'disabledClickable' : 'enabledClickable'} flex-row j-ce`}
        className={
          'enabledClickable video-border-edit-icon activeButtonHover dark'
        }
        style={{ width: 40 }}
        onClick={() => {
          toggleFullScreen((prev: boolean) => !prev);
          dispatch(toggleWideScreenMode());
          dispatch(setAddToPlaylistPopoverIsOpen(false));
        }}
      >
        {isNarrowScreenMode ? <NarrowScreenMode /> : <FullScreeExpand />}
      </div>
    </div>
  );
};
export default HelperPlayerButtons;
