import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  ConfigProvider,
  Flex,
  Modal,
  Progress,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { DownloadsAPI } from 'api/downloads';
import { NotificationsAPI } from 'api/notifications';
import { ReactComponent as BasketIcon } from 'assets/img/icons/faBasketIcon.svg';
import { ReactComponent as DownloadAsReelsIcon } from 'assets/img/icons/faDownloadAsReelsIcon.svg';
import { ReactComponent as DownloadHighlightsIcon } from 'assets/img/icons/faDownloadHighlightsIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/faDownloadIcon.svg';
import { ReactComponent as MergeVideosIcon } from 'assets/img/icons/faMergeVideosIcon.svg';
import { ReactComponent as RefreshIcon } from 'assets/img/icons/faRefreshIcon.svg';
import { ReactComponent as SeparateVideosIcon } from 'assets/img/icons/faSeparateVideosIcon.svg';
import { AppStateType } from 'reducers';
import { downloadsReducer } from 'reducers/downloads.reducer';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { useAppDispatch } from 'store';
import { formatMillisecondsToTime } from 'types/functions';
import { Download, NotificationType } from 'types/types';

import DownloadMassActionsController from './DownloadMassActionsController';
import { ReactComponent as ExclamationCircle } from '../../assets/img/icons/faExclamationCircle.svg';

import './index.css';

const ConfirmDeleteModal = ({
  visible,
  closeCallback,
  confirmCallback,
}: {
  visible: boolean;
  closeCallback: any;
  confirmCallback: any;
}) => {
  const [t] = useTranslation();
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: 'var(--colorBgLayout)',
            headerBg: 'var(--colorBgLayout)',
            titleColor: 'var(--colorText)',
            colorText: 'var(--colorText)',
            // paddingContentHorizontal: 12,
            borderRadiusLG: 16,
            paddingContentVerticalSM: 32,
            paddingContentHorizontalLG: 32,
          },
        },
      }}
    >
      <Modal
        className="base-confirm-modal"
        open={visible}
        onCancel={closeCallback}
        onOk={confirmCallback}
        closeIcon={null}
        width={400}
        footer={null}
      >
        <div className="flex-row f-ga-16">
          <div>
            <ExclamationCircle />
          </div>
          <div className="flex-column f-ga-8">
            <div className="modal-title-dark">{t('Delete download?')}</div>
            <div className="modal-content-dark">
              {t('Are you sure you want to delete this download?')}
            </div>
            <div className="flex-row f-ga-8 mt16">
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      defaultBg: 'var(--colorBgContainer)',
                      colorText: 'var(--colorText)',
                      colorBorder: 'var(--colorBorder)',
                      defaultHoverBg: 'var(--colorBgContainer)',
                      defaultActiveBg: 'var(--colorBgContainer)',
                    },
                  },
                }}
              >
                <Button
                  style={{ flex: 1 }}
                  type="default"
                  onClick={closeCallback}
                >
                  {t('Cancel')}
                </Button>
              </ConfigProvider>
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      colorTextDisabled: 'var(--colorTextDisabled)',
                      borderColorDisabled: 'var(--colorBorder)',
                    },
                  },
                }}
              >
                <Button
                  style={{ flex: 1 }}
                  type="primary"
                  onClick={confirmCallback}
                >
                  {t('Delete')}
                </Button>
              </ConfigProvider>
            </div>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

const VideoDownloadsController = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { downloads } = useSelector(
    (state: AppStateType) => state.dowloadsReducer,
  );
  const dispatch = useAppDispatch();
  const { setDownloads, removeDownloads, updateDownloadState } =
    downloadsReducer.actions;
  const params = useParams();
  const navigate = useNavigate();
  const { notificatons } = useSelector(
    (state: AppStateType) => state.notificationsReducer,
  );
  const { markNotificationAsRead } = notificationsReducer.actions;
  useEffect(() => {
    if (notificatons && notificatons.length) {
      const unreadDownloadsNotifications = notificatons.filter(
        (notification: NotificationType) => !notification.is_read,
      );
      if (unreadDownloadsNotifications.length > 0) {
        unreadDownloadsNotifications.forEach((notification: NotificationType) =>
          NotificationsAPI.readNotification(notification.id).then(),
        );
        dispatch(
          markNotificationAsRead({
            ids: unreadDownloadsNotifications.map(
              (notification) => notification.id,
            ),
          }),
        );
      }
    }
  }, [notificatons]);
  const x = async () => {
    const downloadsList = await DownloadsAPI.getDownloads();
    const downloadLink = params.downloadId;
    if (downloadLink) {
      const d = downloadsList.data.results.filter(
        (download: any) =>
          download.id === downloadLink &&
          ['done', 'link_sent'].includes(download.status),
      )[0];
      if (d) {
        let archivePath = d.resulting_archives[0];
        if (!archivePath.endsWith('.zip')) {
          archivePath += '.zip';
        }
        window.open(
          `https://dev.sota.id/download/files/${archivePath}`,
          '_blank',
        ); // TODO настроить nginx на проде
        navigate('/downloads', { replace: true });
      }
    }
    dispatch(setDownloads(downloadsList.data.results));
  };
  useEffect(() => {
    x().then();
  }, []);
  const pollingIntervals = useRef<any>({});
  const globalPollingIntervals = useRef<any>({});
  const getDownloadProgress = async (downloadId: string) => {
    const statusResponse = await DownloadsAPI.getDownloadStatus(downloadId);
    if (statusResponse.data?.status) {
      // console.log('called interval');
      await dispatch(
        updateDownloadState({
          id: downloadId,
          ...statusResponse.data,
        }),
      );
    }
    if (statusResponse.data?.status === 'done') {
      const downloadsList = await DownloadsAPI.getDownloads();
      dispatch(setDownloads(downloadsList.data.results));
    }
  };
  useEffect(() => {
    downloads.forEach((download: Download) => {
      if (download.status === 'processing') {
        // console.log('added interval');
        pollingIntervals.current[download.id] = setInterval(
          async () => await getDownloadProgress(download.id),
          1500,
        );
      } else if (Object.keys(pollingIntervals.current).includes(download.id)) {
        // console.log('removed interval');
        clearInterval(pollingIntervals.current[download.id]);
        delete pollingIntervals.current[download.id];
      }
    });
    if (
      downloads.filter((download: Download) =>
        ['in_queue', 'new'].includes(download.status),
      ).length > 0
    ) {
      globalPollingIntervals.current = setInterval(async () => await x(), 1500);
    }
    return () => {
      Object.keys(pollingIntervals.current).forEach((key: string) => {
        // console.log('return clear interval');
        clearInterval(pollingIntervals.current[key]);
      });
      if (globalPollingIntervals.current) {
        clearInterval(globalPollingIntervals.current);
      }
    };
  }, [downloads, pollingIntervals]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleRestart = async (id: string) => {
    await DownloadsAPI.restartDownloads([id]);
    await dispatch(
      updateDownloadState({
        id: id,
        status: 'new',
        progress: 0,
      }),
    );
  };
  const handleDownload = (ids: string[]) => {
    ids.forEach((id: string) => {
      const d = downloads.filter(
        (download) =>
          download.id === id && ['done', 'link_sent'].includes(download.status),
      )[0];
      if (d) {
        let archivePath = d.resulting_archives[0];
        if (
          !archivePath.endsWith('.zip') &&
          !archivePath.endsWith('.mp4') &&
          !archivePath.includes('.mp4')
        ) {
          archivePath += '.zip';
        }
        if (archivePath.includes('.zip') || !d.is_full_match) {
          window.open(
            `https://dev.sota.id/download/files/${archivePath}`,
            '_blank',
          ); // TODO настроить nginx на проде
        } else {
          DownloadsAPI.getDirectLink(id).then((response) => {
            window.open(response.data.link, '_blank');
          });
        }
        // window.open(`${API_ENDPOINT}files/${archivePath}`, '_blank');
        // window.open(
        //   `https://dev.sota.id/files/${ENV}/${archivePath}`,
        //   '_blank',
        // );
      }
    });
  };
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderProgressColumn = (value: any, record: any, index: number) => {
    if (record.status === 'processing') {
      return (
        <Flex className="flex-row f-ga-16" align="center">
          <Tooltip placement={'top'} title={`${Math.round(record.progress)}%`}>
            <Progress
              status="active"
              percent={record.progress || 0}
              showInfo={false}
              strokeColor="var(--colorPrimaryBase, rgba(22, 104, 220, 1))"
              trailColor="var(--colorSplit, rgba(255, 255, 255, 0.06))"
              style={{
                width: 120,
              }}
            />
          </Tooltip>
          <div
            className="delete-action-icon-container ai-c flex-row j-ce enabledClickable"
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmDelete(true);
              setRecordToDelete(record.id);
            }}
          >
            <BasketIcon fill={'var(--colorIcon)'} />
          </div>
        </Flex>
      );
    }
    const STATUS_MAPPER: any = {
      new: 'in queue',
      in_queue: 'in queue',
      done: 'done',
      error: 'error',
      out_of_quota: 'out of quota',
      uploading: 'uploading',
      expired: 'expired',
      link_sent: 'done',
    };
    const TAG_COLORS: any = {
      'in queue': 'var(--blue-4, rgba(21, 65, 126, 1))',
      done: 'var(--green-3, rgba(39, 73, 22, 1))',
      error: 'var(--red-3, rgba(88, 24, 28, 1))',
      'out of quota': 'var(--red-3, rgba(88, 24, 28, 1))',
      uploading: 'var(--blue-4, rgba(21, 65, 126, 1))',
      expired: 'var(--colorBgContainerDisabled, rgba(255, 255, 255, 0.08))',
    };
    const TAG_TEXT_COLORS: any = {
      'in queue': 'var(--blue-8, rgba(101, 169, 243, 1))',
      done: 'var(--green-8, rgba(143, 212, 96, 1))',
      error: 'var(--red-8, rgba(243, 115, 112, 1))',
      'out of quota': 'var(--red-8, rgba(243, 115, 112, 1))',
      uploading: 'var(--blue-8, rgba(101, 169, 243, 1))',
      expired: 'var(--blue-8, rgba(101, 169, 243, 1))',
    };
    const refreshHandler = (record: any) => {
      handleRestart(record.id);
    };
    const downloadHandler = (record: any) => {
      !['uploading', 'in queue'].includes(STATUS_MAPPER[record.status]) &&
        handleDownload([record.id]);
    };
    return (
      <div className="flex-row f-ga-16">
        <div className="flex-row j-sb ai-c f-ga-16" style={{ width: 120 }}>
          <ConfigProvider
            theme={{
              token: {
                borderRadiusSM: 17,
                paddingXXS: 8,
                fontSize: 12,
                colorTextLightSolid:
                  TAG_TEXT_COLORS[STATUS_MAPPER[record.status]],
              },
              components: {
                Tag: {
                  defaultColor: TAG_TEXT_COLORS[STATUS_MAPPER[record.status]],
                  borderRadius: 17,
                },
              },
            }}
          >
            <Tag
              bordered={false}
              color={TAG_COLORS[STATUS_MAPPER[record.status]]}
              style={{
                width: 76,
                textAlign: 'center',
              }}
            >
              {t(STATUS_MAPPER[record.status])}
            </Tag>
          </ConfigProvider>
          {['uploading', 'in queue'].includes(STATUS_MAPPER[record.status]) ? (
            <></>
          ) : (
            <div
              className={
                'download-action-icon-container ai-c flex-row j-ce ' +
                `${['uploading', 'in queue'].includes(STATUS_MAPPER[record.status]) ? 'disabledClickable' : 'enabledClickable'}`
              }
              onClick={(e) => {
                e.stopPropagation();
                ['error', 'expired', 'out_of_quota'].includes(record.status)
                  ? refreshHandler(record)
                  : downloadHandler(record);
              }}
            >
              {['error', 'expired', 'out_of_quota'].includes(record.status) ? (
                <RefreshIcon />
              ) : ['uploading', 'in queue'].includes(
                  STATUS_MAPPER[record.status],
                ) ? (
                <></>
              ) : (
                <DownloadIcon
                  className={`${['uploading', 'in queue'].includes(STATUS_MAPPER[record.status]) ? 'disabledClickable' : ''}`}
                />
              )}
            </div>
          )}
        </div>
        <div
          className="delete-action-icon-container ai-c flex-row j-ce enabledClickable"
          onClick={(e) => {
            e.stopPropagation();
            setShowConfirmDelete(true);
            setRecordToDelete(record.id);
          }}
        >
          <BasketIcon fill={'var(--colorIcon)'} />
        </div>
      </div>
    );
  };
  const handleDelete = async (id: string) => {
    await DownloadsAPI.deleteDownloads([id]);
    dispatch(removeDownloads({ ids: [id] }));
  };
  const massDelete = async (ids: string[]) => {
    await DownloadsAPI.deleteDownloads(ids);
    dispatch(removeDownloads({ ids: ids }));
  };
  const renderDownloadSettings = (record: any) => {
    return (
      <Flex justify="space-between">
        <div>{record.name}</div>
        <Flex justify="flex-end" gap={8}>
          {record.add_highlights && (
            <Tooltip title={t('highlights')}>
              <DownloadHighlightsIcon />
            </Tooltip>
          )}
          {record.as_reels && (
            <Tooltip title={t('reels')}>
              <DownloadAsReelsIcon />
            </Tooltip>
          )}
          {record.merge_videos &&
            (record.qty > 1 ? (
              <Tooltip
                title={record.qty === 1 ? t('one episode') : t('single file')}
              >
                <MergeVideosIcon />
              </Tooltip>
            ) : (
              <></>
            ))}
          {!record.merge_videos &&
            (record.qty > 1 ? (
              <Tooltip
                title={
                  record.qty === 1 ? t('one episode') : t('separate files')
                }
              >
                <SeparateVideosIcon />
              </Tooltip>
            ) : (
              <></>
            ))}
        </Flex>
      </Flex>
    );
  };
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState('');
  return (
    <div className="downloads-container">
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'SFProTextRegular',
            fontSize: 14,
            colorBgContainer: 'var(--colorBgLayout, rgba(41, 45, 50, 1))',
            colorBorder: 'var(--colorBorder, rgba(66, 66, 66, 1))',
            lineWidth: 1,
          },
          components: {
            Table: {
              headerBg: 'var(--colorBgContainer)',
              headerColor: 'var(--colorText)',
              // headerSplitColor: 'transparent',
              headerSplitColor: 'rgba(255, 255, 255, 0.1)',
              colorBgContainer: 'var(--colorBgContainer)',
              headerBorderRadius: 0,
              borderColor: 'none',
              paddingXS: 16,
              rowHoverBg: 'var(--colorBgLayout)',
              rowSelectedBg: 'var(--colorBgLayout)',
              rowSelectedHoverBg: 'var(--colorBgElevated)',
              cellPaddingBlock: 16,
              cellPaddingInline: 16,
            },
          },
        }}
      >
        <Table
          rowSelection={rowSelection}
          dataSource={downloads}
          className={'downloads-table'}
          rowClassName={'downloads-table-row'}
          pagination={false}
          bordered={false}
          style={{
            marginTop: -5,
          }}
        >
          <Column
            title={t('Name')}
            dataIndex={'name'}
            key={'name'}
            className="download-name"
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={(value: any, record: any, index: number) => {
              return renderDownloadSettings(record);
            }}
          />
          <Column
            title={t('Episodes')}
            width={90}
            dataIndex={'qty'}
            key={'qty'}
          />
          <Column
            title={t('Duration downloads')}
            dataIndex={'duration'}
            width={90}
            key={'duration'}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={(value: any, record: any, index: number) => {
              return <>{formatMillisecondsToTime(value * 1000)}</>;
            }}
          />
          <Column
            title={t('Created date')}
            dataIndex={'created_at'}
            width={155}
            key={'created_date'}
          />
          <Column
            dataIndex={'status'}
            key={'status'}
            width={'120px'}
            render={renderProgressColumn}
          />
        </Table>
      </ConfigProvider>
      <DownloadMassActionsController
        selectedDownloads={selectedRowKeys}
        massDeleteCallback={massDelete}
        massDownloadCallback={handleDownload}
      />
      <ConfirmDeleteModal
        visible={showConfirmDelete}
        confirmCallback={() => {
          handleDelete(recordToDelete).then();
          setShowConfirmDelete(false);
          setRecordToDelete('');
        }}
        closeCallback={() => {
          setRecordToDelete('');
          setShowConfirmDelete(false);
        }}
      />
    </div>
  );
};
export default VideoDownloadsController;
