import React from 'react';

import { Flex, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';

const AverageStatValueTag = ({
  stat,
  selectedMetricFilters,
  selectedPositions,
  loadingPercentiles,
  timeOnField,
  timeOnFieldByPosition,
  overBaseMetric,
  hideValue = false,
}: {
  stat: any;
  selectedMetricFilters: any;
  selectedPositions: string | null;
  loadingPercentiles: boolean | null;
  timeOnField: number;
  timeOnFieldByPosition: any;
  overBaseMetric: string | null;
  hideValue?: boolean;
}) => {
  const [t] = useTranslation();
  const getPercentValue = () => {
    if (hideValue) {
      return '';
    }
    if (overBaseMetric) {
      return stat.details[`${stat.key}${overBaseMetric}`].per90;
    }
    if (!selectedPositions) {
      if (selectedMetricFilters.includes(`${stat.key}_success`)) {
        return stat.details[`${stat.key}_success`].per90;
      }
      if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
        return stat.details[`${stat.key}_fail`].per90;
      }
    }
    if (selectedPositions) {
      if (
        stat.by_position &&
        stat.by_position[selectedPositions] &&
        stat.by_position[selectedPositions]?.value !== undefined &&
        stat.by_position[selectedPositions]?.value !== null
      ) {
        if (
          timeOnFieldByPosition !== undefined &&
          timeOnFieldByPosition !== null
        ) {
          if (selectedMetricFilters.includes(`${stat.key}_success`)) {
            if (stat.details[`${stat.key}_success`]?.by_position) {
              const avg =
                ((stat.details[`${stat.key}_success`]?.by_position[
                  selectedPositions
                ]?.value || 0) *
                  90) /
                Math.floor(timeOnFieldByPosition / 1000 / 60);
              return avg ? avg.toFixed(1) : avg;
            }
            return 0;
          }
          if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
            if (stat.details[`${stat.key}_fail`]?.by_position) {
              const avg =
                ((stat.details[`${stat.key}_fail`]?.by_position[
                  selectedPositions
                ]?.value || 0) *
                  90) /
                Math.floor(timeOnFieldByPosition / 1000 / 60);
              return avg ? avg.toFixed(1) : avg;
            }
            return 0;
          }
          return (
            ((stat.by_position[selectedPositions]?.value || 0) * 90) /
            Math.floor(timeOnFieldByPosition / 1000 / 60)
          ).toFixed(1);
        }
        const avg =
          ((stat.by_position[selectedPositions]?.value || 0) * 90) /
          timeOnField;
        return avg ? avg.toFixed(1) : avg;
      }
      return <>0</>;
    }
    return stat.per90;
  };
  const getPthValue = () => {
    if (hideValue) {
      return '';
    }
    if (stat.pth) {
      if (selectedMetricFilters.includes(`${stat.key}_success`)) {
        if (stat.pth.pth_per_90_success !== undefined) {
          return stat.pth.pth_per_90_success + 'pth';
        }
        return '';
      }
      if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
        return '';
      }
    }
    return stat.pth.pth_per_90 + 'pth';
  };
  return (
    <Flex
      className={`${stat?.per90 === undefined ? 'na-stat' : stat.stat?.per90 > 0 ? 'average-stat-value-container' : 'stat-value-container-zero'}`}
      style={{ width: 56, minHeight: 38 }}
      justify="flex-start"
    >
      <Flex
        className="stat-average"
        vertical
        style={{ width: '100%' }}
        flex={1}
        align="center"
        justify={stat?.per90 === undefined ? 'center' : 'flex-start'}
      >
        {stat?.per90 === undefined ? (
          <span className="disabled-stat-color">
            {hideValue ? '' : t('n/a')}
          </span>
        ) : stat.per90 > 0 ? (
          <span className="stat-average-value">{getPercentValue()}</span>
        ) : (
          <span className="stat-average-value">{hideValue ? '' : 0}</span>
        )}
        {loadingPercentiles ? (
          <span
            style={{
              textTransform: 'none',
              height: 12,
            }}
          >
            <Skeleton.Button
              active
              shape={'round'}
              style={{ minWidth: 40, width: 40, height: 11 }}
            ></Skeleton.Button>
          </span>
        ) : (
          stat?.pth !== null &&
          stat?.pth !== undefined && (
            <span className={'stat-percent-value'}>{getPthValue()}</span>
          )
        )}
      </Flex>
    </Flex>
  );
};
export default AverageStatValueTag;
