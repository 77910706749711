import React, { useEffect, useRef, useState } from 'react';

import { ConfigProvider, Flex, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

import FieldBlock from './FieldBlock';

const HorizontalMapWithBlocks = ({
  resetCallback,
  type,
  fieldEventsDetails,
  toggleFieldCell,
  selectedFieldCells,
  selectedFromFieldCells,
  selectedToFieldCells,
  toggleMultipleFieldCells,
  playerProfileMode,
  scrollValue,
}: {
  resetCallback: any;
  type: string;
  fieldEventsDetails: any;
  toggleFieldCell: any;
  selectedFieldCells: any;
  selectedFromFieldCells: any;
  selectedToFieldCells: any;
  toggleMultipleFieldCells: any;
  playerProfileMode: any;
  scrollValue: number;
}) => {
  const [rect, setRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [isSelecting, setIsSelecting] = useState(false);
  const fieldRef = useRef<any>(null);
  const [t] = useTranslation();
  const [dragSelectedIds, setDragSelectedIds] = useState<number[]>([]);
  const { multitoolEventsLoading } = useSelector((state: AppStateType) => {
    return state.teamPlayerStatsSectionReducer;
  });
  const { openedTeamPlayer } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });
  const FIELD_CELLS_FROM = [
    [
      { id: 5, value: undefined },
      { id: 10, value: 10 },
      { id: 15, value: 10 },
      { id: 20, value: undefined },
      { id: 25, value: 10 },
      { id: 30, value: 10 },
    ],
    [
      { id: 4, value: 10 },
      { id: 9, value: 10 },
      { id: 14, value: undefined },
      { id: 19, value: 10 },
      { id: 24, value: 10 },
      { id: 29, value: 10 },
    ],
    [
      { id: 3, value: 10 },
      { id: 8, value: 10 },
      { id: 13, value: 10 },
      { id: 18, value: 10 },
      { id: 23, value: 10 },
      { id: 28, value: 10 },
    ],
    [
      { id: 2, value: 10 },
      { id: 7, value: undefined },
      { id: 12, value: 10 },
      { id: 17, value: 10 },
      { id: 22, value: 10 },
      { id: 27, value: 10 },
    ],
    [
      { id: 1, value: 10 },
      { id: 6, value: 10 },
      { id: 11, value: 10 },
      { id: 16, value: 10 },
      { id: 21, value: undefined },
      { id: 26, value: 10 },
    ],
  ];
  const FIELD_CELLS_FROM_FOOTZAL: any = [
    [{ id: 3 }, { id: 6 }, { id: 9 }, { id: 12 }],
    [{ id: 2 }, { id: 5 }, { id: 8 }, { id: 11 }],
    [{ id: 1 }, { id: 4 }, { id: 7 }, { id: 10 }],
  ];
  const [usedFieldCells, setUsedFieldCells] = useState<any>([]);
  useEffect(() => {
    if (openedTeamPlayer?.team?.sport === 2) {
      setUsedFieldCells(FIELD_CELLS_FROM_FOOTZAL);
    } else {
      setUsedFieldCells(FIELD_CELLS_FROM);
    }
  }, [openedTeamPlayer]);
  useEffect(() => {
    // Mouse move and mouse up listeners on window, so we can track user’s drag
    // even if they move off the component bounds
    const handleMouseMove = (e: any) => {
      if (!isSelecting) {
        return;
      }
      let currentX = e.clientX - fieldRef.current.getBoundingClientRect().x;
      if (currentX > fieldRef.current.getBoundingClientRect().width) {
        currentX = fieldRef.current.getBoundingClientRect().width;
      }
      let currentY = e.clientY - fieldRef.current.getBoundingClientRect().y;
      if (currentY > fieldRef.current.getBoundingClientRect().height) {
        currentY = fieldRef.current.getBoundingClientRect().height;
      }
      const newX = Math.min(startPos.x, Math.max(currentX, 0));
      const newY = Math.min(startPos.y, currentY >= 0 ? currentY : 0);
      const newWidth = Math.abs(Math.max(currentX, 0) - startPos.x);
      const newHeight =
        currentY >= 0 ? Math.abs(currentY - startPos.y) : startPos.y;
      setRect({ x: newX, y: newY, width: newWidth, height: newHeight });
      // console.log({ x: newX, y: newY, width: newWidth, height: newHeight });
    };

    const handleMouseUp = () => {
      if (isSelecting) {
        setIsSelecting(false);
        if (rect.width && rect.height) {
          toggleMultipleFieldCells(dragSelectedIds);
          setDragSelectedIds([]);
        }
        setStartPos({ x: 0, y: 0 });
        setRect({ x: 0, y: 0, width: 0, height: 0 });
        // If you want to process the selection at this point, do it here
        // For example, checking which items are inside the rect
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isSelecting, startPos, dragSelectedIds, fieldRef.current, scrollValue]);

  const handleMouseDown = (e: any) => {
    // Only start selection if, for example, left button is pressed
    if (e.button !== 0) {
      return;
    }
    setIsSelecting(true);
    setStartPos({
      x: e.clientX - fieldRef.current.getBoundingClientRect().x,
      y: e.clientY - fieldRef.current.getBoundingClientRect().y,
    });
    setRect({
      x: e.clientX - fieldRef.current.getBoundingClientRect().x,
      y: e.clientY - fieldRef.current.getBoundingClientRect().y,
      width: 0,
      height: 0,
    });
  };
  return (
    <Flex
      vertical
      gap={6}
      style={{
        width: '49%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Flex
        style={{
          position: 'absolute',
          zIndex: 20,
          top: 'calc(50% - 30px)',
          left: 'calc(50% - 25px)',
        }}
      >
        <ConfigProvider>
          <Spin
            spinning={multitoolEventsLoading}
            size="large"
            tip={t('Loading')}
          >
            <div style={{ padding: '25px' }}></div>
          </Spin>
        </ConfigProvider>
      </Flex>
      <div
        className={`${openedTeamPlayer?.team?.sport === 2 ? 'field-footzal-class' : 'field-class'} no-select`}
        style={{ overflow: 'hidden' }}
        ref={fieldRef}
        onMouseDown={handleMouseDown}
      >
        <div
          style={{
            position: 'absolute',
            top: rect.y,
            left: rect.x,
            width: rect.width,
            height: rect.height,
            backgroundColor: 'rgba(69, 151, 248, 0.1)',
            border: rect.width ? '1px solid #4597F8' : 'none',
            pointerEvents: 'none',
          }}
        />
        {usedFieldCells.map((row: any, index: number) => {
          return (
            <Row
              key={`${type}-row-${index}`}
              style={{
                height: openedTeamPlayer?.team?.sport === 2 ? '33.33%' : '20%',
              }}
            >
              {row.map((cell: any) => {
                return (
                  <FieldBlock
                    key={`${type}-row-${cell.id}`}
                    cell={cell}
                    fieldEventsDetails={fieldEventsDetails}
                    toggleFieldCell={toggleFieldCell}
                    selectedToFieldCells={selectedToFieldCells}
                    selectedFromFieldCells={selectedFromFieldCells}
                    type={type}
                    selectionRect={rect}
                    fieldRef={fieldRef}
                    setDragSelectedIds={setDragSelectedIds}
                    playerProfileMode={playerProfileMode}
                    widthSpan={openedTeamPlayer?.team?.sport === 2 ? 6 : 4}
                    scrollValue={scrollValue}
                  />
                );
              })}
            </Row>
          );
        })}
      </div>
      <Flex justify="space-between" align="center">
        <div className="dark-text">
          {type === 'from' ? t('Source zone') : t('Destination zone')}
        </div>
        {selectedFieldCells.length > 0 && (
          <div
            style={{
              color: 'rgba(22, 119, 255, 1)',
            }}
            className="enabledClickable"
            onClick={resetCallback}
          >
            {t('Reset')}
          </div>
        )}
      </Flex>
    </Flex>
  );
};
export default HorizontalMapWithBlocks;
