import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  metricsRegistry: {
    match: any[];
    stats: any[];
  };
};
const initialState: InitialStateType = {
  metricsRegistry: {
    match: [],
    stats: [],
  },
};

export const metricsReducer = createSlice({
  name: 'metricsReducer',
  initialState: initialState,
  reducers: {
    setMetricsRegistry(state, action) {
      state.metricsRegistry = action.payload;
    },
  },
});
