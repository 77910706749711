import React, { useEffect, useState } from 'react';

import { ConfigProvider, Divider, Flex, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeStatsPlaceholders } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import StatisticsDetailsMultitoolBlock from '../StatisticsDetailsMultitoolBlock';

import './index.css';

const MetricsSelector = ({
  metricsControllerRef,
}: {
  metricsControllerRef: any;
}) => {
  const { openedTeamPlayer } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });
  const { setTeamPlayerStats_stats } = teamPlayerStatsSectionReducer.actions;
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { metricsRegistry } = useSelector((state: AppStateType) => {
    return state.metricsReducer;
  });
  useEffect(() => {
    openedTeamPlayer &&
      metricsRegistry.stats.length &&
      dispatch(
        setTeamPlayerStats_stats(
          makeStatsPlaceholders(t, openedTeamPlayer.team.id, metricsRegistry),
        ),
      );
  }, [openedTeamPlayer, metricsRegistry]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expandedQty, setExpandedQty] = useState<number>(0);
  const [metricsState, setMetricsState] = useState(t('Statistics'));

  return (
    <Flex
      gap={0}
      vertical
      justify="flex-start"
      style={{
        background: 'white',
        borderRadius: 12,
        maxHeight: 'calc(100vh - 96px - 56px - 12px)', // высота блока - игрок - шапка - отступ внизу
        // maxHeight: 'calc(100vh - 96px - 56px - 12px)', // высота блока - игрок - шапка - отступ внизу
        height: `calc(${(metricsRegistry.stats.length + expandedQty) * 42 + 7 + 4 + 5 + 34 + 40}px)`,
        // minHeight: `calc(${(metricsRegistry.stats.length + expandedQty) * 42 + 7 + 4 + 5 + 34 + 40}px)`,
        overflowY: 'hidden',
      }}
      className="player-multitool-stats-wrapper"
    >
      <Flex
        align="center"
        justify="center"
        style={{ marginLeft: 8, marginRight: 8, maxHeight: 48, marginTop: 8 }}
      >
        <ConfigProvider
          theme={{
            components: {
              Segmented: {
                trackBg: 'var(--segmentedGroupBg)',
                // itemColor: 'red',
              },
            },
            token: {
              lineHeight: 22,
            },
          }}
        >
          <Segmented
            style={{ width: '100%' }}
            size="middle"
            color="black"
            block
            height={32}
            value={metricsState}
            options={[t('Statistics'), t('Context')]}
            onChange={(value) => {
              setMetricsState(value); // string
            }}
          />
        </ConfigProvider>
      </Flex>
      <Flex
        style={{
          paddingLeft: 16,
          paddingRight: 8,
          paddingTop: 12,
        }}
        gap={0}
      >
        <Flex flex={1} className="stats-name">
          {t('Actions')}
        </Flex>
        <Flex gap={8}>
          <div
            className="stats-header"
            style={{
              minWidth: 130,
              flex:
                openedTeamPlayer && openedTeamPlayer.team.sport === 2
                  ? 'unset'
                  : 1,
              textAlign: 'start',
            }}
          >
            {t('Total')}
          </div>
          {openedTeamPlayer && openedTeamPlayer.team.sport !== 2 && (
            <div className="stats-header" style={{ width: 56 }}>
              {t('90 min')}
            </div>
          )}
        </Flex>
      </Flex>
      <Divider style={{ marginBottom: 0, marginTop: 4 }} />
      <StatisticsDetailsMultitoolBlock
        setExpandedQty={setExpandedQty}
        rightMargin={4}
        metricsControllerRef={metricsControllerRef}
      />
    </Flex>
  );
};
export default MetricsSelector;
