/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';

import { Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { OnProgressProps } from 'react-player/types/base';
import { useSelector } from 'react-redux';

import {
  AC_SetAllowPlayFirstAction,
  AC_SetFilteredVideosAction,
  AC_SetIsPlayingVideoAction,
} from 'actions/player.acitons';
import { ReactComponent as ForwardIcon } from 'assets/img/icons/faForwardIcon.svg';
import { ReactComponent as LeftVideoBorderPush } from 'assets/img/icons/faLeftVideoBorderPush.svg';
import { ReactComponent as NextKeyFrameIcon } from 'assets/img/icons/faNextKeyFrameIcon.svg';
import { ReactComponent as PlayIcon } from 'assets/img/icons/faPlayIcon.svg';
import { ReactComponent as PreviousKeyFrameIcon } from 'assets/img/icons/faPreviousKeyFrameIcon.svg';
import { ReactComponent as RepeatIcon1 } from 'assets/img/icons/faRepeatIcon.svg';
import { ReactComponent as RepeatIcon10 } from 'assets/img/icons/faRepeatIcon10.svg';
import { ReactComponent as RepeatIcon2 } from 'assets/img/icons/faRepeatIcon2.svg';
import { ReactComponent as RepeatIcon3 } from 'assets/img/icons/faRepeatIcon3.svg';
import { ReactComponent as RepeatIcon4 } from 'assets/img/icons/faRepeatIcon4.svg';
import { ReactComponent as RepeatIcon5 } from 'assets/img/icons/faRepeatIcon5.svg';
import { ReactComponent as RewindIcon } from 'assets/img/icons/faRewindIcon.svg';
import { ReactComponent as RightVideoBorderPush } from 'assets/img/icons/faRightVideoBorderPush.svg';
import { ReactComponent as PauseIcon } from 'assets/img/PauseIcon.svg';
import { AppStateType } from 'reducers';
import { hotkeysReducer } from 'reducers/hotkeys.reducer';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { useAppDispatch } from 'store';
import { WINDOW_MODE_WIDTH, WINDOW_MODE_WIDTH_MS } from 'types/constants';
import {
  filterAndIndex,
  formatMillisecondsToTime,
  // formattedTimeSeconds,
} from 'types/functions';
import { EpisodeTypes } from 'types/types';
import { detectOS } from 'utils/functions';

import HelperPlayerButtons from './HelperPlayerButtons';
// import RewindSpeedIndex from '../../contexts/RewindSpeedContext';
import { editEpisodeTimeline } from './videoTimelineEditor';
import VideoTimelineZoomControl from '../VideoTimelineZoomControl';

import './index.css';

interface Interface {
  refPlayer: any;
  playerState: OnProgressProps | undefined;
  // handleVideoScroll: (milliseconds: number) => void;
  isNarrowScreenMode: boolean;
  toggleFullScreen: any;
  setPlaybackRate: any;
  playbackRate: number;
  volume: number;
  setVolume: any;
  rewindSpeed: number;
  setRewindSpeed: any;
  setIsMuted: any;
  to: number;
  from: number;
}
const VideoPlayerButtonsController: FC<Interface> = ({
  refPlayer,
  playerState,
  isNarrowScreenMode,
  toggleFullScreen,
  playbackRate,
  setPlaybackRate,
  volume,
  setVolume,
  rewindSpeed,
  setRewindSpeed,
  setIsMuted,
  to,
  from,
}) => {
  const {
    activeVideo,
    playerMode,
    isPlaying,
    isLoading,
    editedEpisodeRange,
    filteredVideos,
  } = useSelector((state: AppStateType) => {
    return state.playerReducer;
  });
  const { playedEpisode, playedVideoSet } = useSelector(
    (state: AppStateType) => {
      return state.playerReducerV2;
    },
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [period, setPeriod] = useState(
    playedVideoSet?.periods?.filter(
      (period: any) => period.name === playedEpisode?.period_name,
    )[0],
  );
  useEffect(() => {
    setPeriod(
      playedVideoSet?.periods?.filter(
        (period: any) => period.name === playedEpisode?.period_name,
      )[0],
    );
  }, [playedEpisode, playedVideoSet]);
  const { setCreateEpisodeMode } = playerReducerV2.actions;
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { reelsModeOn, keyframes, toggleFrameState } = useSelector(
    (state: AppStateType) => {
      return state.reelsReducer;
    },
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const REWIND_INDEX_ICONS: any = {
    1: <RepeatIcon1 />,
    2: <RepeatIcon2 />,
    3: <RepeatIcon3 />,
    4: <RepeatIcon4 />,
    5: <RepeatIcon5 />,
    10: <RepeatIcon10 />,
  };
  const { setPlayedEpsiodeAction } = playerReducerV2.actions;
  const { showNotification } = notificationsReducer.actions;

  const getCurrentTimerPosition = () => {
    let currentTime = 0;
    let episodeLength = Number(getCurrentVideoDuration(false)) / 1000;
    if (playerState?.playedSeconds !== undefined && playedEpisode) {
      let timer;
      let offset = 0;
      let windowTime = WINDOW_MODE_WIDTH;
      if (playedEpisode && playedEpisode.episode_type === EpisodeTypes.period) {
        windowTime = 0;
      }
      if (playedEpisode?.user_timer) {
        timer = playedEpisode.user_timer;
      } else if (playedEpisode.episode_type === EpisodeTypes.period) {
        timer = playedEpisode.game_timer;
        offset = windowTime;
      } else {
        timer = playedEpisode.file_timer;
        offset = windowTime;
      }
      if (editedEpisodeRange.length > 0) {
        currentTime = playerState?.playedSeconds - from / 1000 - 0;
        episodeLength = (to - from) / 1000;
      } else {
        if (playedEpisode) {
          if (playedEpisode.episode_type === EpisodeTypes.period) {
            const fileStartTime = Math.max(
              playedEpisode.game_timer?.started_at - windowTime * 1000,
              period?.game_start,
            );
            currentTime =
              playerState.playedSeconds -
              (playedEpisode?.period_offset || 0) / 1000 +
              fileStartTime / 1000;
          } else {
            const fileStartTime = Math.max(
              timer?.started_at - windowTime * 1000,
              period?.started_at,
            );
            currentTime = playerState.playedSeconds - fileStartTime / 1000;
          }
        }
      }
    }
    if (Number.isNaN(currentTime)) {
      return '';
    }
    if (currentTime >= 0) {
      return formatMillisecondsToTime(currentTime * 1000, true);
    }
    return formatMillisecondsToTime(0, true);
  };
  const getCurrentVideoDuration = (formatOutput = true) => {
    if (playedEpisode?.user_timer) {
      return formatMillisecondsToTime(
        playedEpisode?.user_timer.finished_at -
          playedEpisode?.user_timer.started_at,
        true,
      );
    } else {
      let timer;
      let fileStartTime;
      let fileEndTime;
      if (playedEpisode && playedEpisode.episode_type === EpisodeTypes.period) {
        timer = playedEpisode.game_timer;
        fileStartTime = 0;
        fileEndTime = period?.game_finish;
      } else {
        timer = playedEpisode?.game_timer;
        fileStartTime = Math.max(
          timer?.started_at - WINDOW_MODE_WIDTH_MS,
          period?.game_start,
        );
        fileEndTime = Math.min(
          timer?.finished_at + WINDOW_MODE_WIDTH_MS,
          period?.game_finish,
        );
      }
      if (Number.isNaN(fileEndTime) || Number.isNaN(fileStartTime)) {
        return '';
      }
      if (formatOutput) {
        return formatMillisecondsToTime(fileEndTime - fileStartTime, true);
      }
      return fileEndTime - fileStartTime;
    }
  };
  const { toggleNextEpisode, togglePreviousEpisode } = hotkeysReducer.actions;
  const { openedPlaylistId, openedPlaylist } = useSelector(
    (state: AppStateType) => {
      return state.playlistReducerV2;
    },
  );
  const [playIcon, setPlayIcon] = useState(<></>);
  useEffect(() => {
    if (isPlaying || isLoading) {
      setPlayIcon(<PauseIcon className={'playerButton'} />);
    } else {
      setPlayIcon(<PlayIcon />);
    }
  }, [isLoading, isPlaying]);
  const [playPreviousTooltipVisible, setPlayPreviousTooltipVisible] =
    useState(false);
  const [playNextTooltipVisible, setPlayNextTooltipVisible] = useState(false);
  const handleMouseEnter = (setter: any) => {
    setter(true);
    setTimeout(() => {
      setter(false);
    }, 1500);
  };
  const handleMouseLeave = (setter: any) => {
    setter(false);
  };
  const { resetReelsState, setToggleFrameState } = reelsReducer.actions;
  const { resetEditMode } = playerReducerV2.actions;

  const nextEpsiodeButtonHandler = (callback: any) => {
    dispatch(resetReelsState());
    if (editedEpisodeRange.length > 0) {
      dispatch(resetEditMode());
    }
    dispatch(callback(true));
    dispatch(setCreateEpisodeMode(false));
  };
  const goToPreviousFrame = () => {
    if (keyframes.length > 0 && playerState?.playedSeconds !== undefined) {
      const previousFrames = [...keyframes]
        .sort((a, b) => b.time - a.time)
        .filter(
          (keyframe) => keyframe.time < playerState?.playedSeconds * 1000,
        );
      if (previousFrames.length > 0) {
        refPlayer?.current?.seekTo(previousFrames[0].time / 1000);
      }
    }
  };
  const goToNextFrame = () => {
    if (keyframes.length > 0 && playerState?.playedSeconds !== undefined) {
      const previousFrames = [...keyframes]
        .sort((a, b) => a.time - b.time)
        .filter(
          (keyframe) => keyframe.time > playerState?.playedSeconds * 1000,
        );
      if (previousFrames.length > 0) {
        refPlayer?.current?.seekTo(previousFrames[0].time / 1000);
      }
    }
  };
  useEffect(() => {
    if (toggleFrameState === 'next') {
      goToNextFrame();
      dispatch(setToggleFrameState(''));
    } else if (toggleFrameState === 'previous') {
      goToPreviousFrame();
      dispatch(setToggleFrameState(''));
    }
  }, [toggleFrameState]);
  const { AC_setOpenedPlaylist } = playlistReducerV2.actions;
  const editTimeLine = (timeShift: number) => {
    let seekStartTime;
    if (!openedPlaylistId && !openedPlaylist) {
      const { updatedFilteredVideos, editedPlayedEpisode, startTime } =
        editEpisodeTimeline(
          filteredVideos,
          playedVideoSet,
          playedEpisode,
          timeShift,
        );
      dispatch(AC_SetFilteredVideosAction(updatedFilteredVideos));
      dispatch(setPlayedEpsiodeAction(editedPlayedEpisode));
      dispatch(AC_SetAllowPlayFirstAction(false));
      seekStartTime = startTime;
    } else if (openedPlaylist) {
      const { updatedFilteredVideos, editedPlayedEpisode, startTime } =
        editEpisodeTimeline(
          openedPlaylist.playlist_events,
          playedVideoSet,
          playedEpisode,
          timeShift,
        );
      const updatedPlaylists = {
        ...openedPlaylist,
        playlist_events: updatedFilteredVideos,
      };
      // TODO TRANSFER TO IMMER
      dispatch(AC_setOpenedPlaylist(updatedPlaylists));
      dispatch(AC_SetAllowPlayFirstAction(false));
      dispatch(resetEditMode());
      dispatch(setPlayedEpsiodeAction(editedPlayedEpisode));
      seekStartTime = startTime;
    }
    if (timeShift < 0) {
      refPlayer?.current?.seekTo(seekStartTime / 1000, 'seconds');
    }
  };
  return (
    <div
      className={'flex-row j-sb ai-c'}
      style={{ width: '100%', height: 40, marginBottom: 6 }}
    >
      {activeVideo ? (
        <>
          <Flex>
            <div
              className={'cameraCheckWindow_Timer flex-row f-ga-6 j-fs ai-c'}
            >
              <span
                style={{
                  width: getCurrentTimerPosition().length > 8 ? 78 : 70,
                }}
              >
                {getCurrentTimerPosition()}
              </span>
              <span style={{ textAlign: 'center', color: '#424242' }}>|</span>
              <span>{getCurrentVideoDuration()}</span>
            </div>
            {playedEpisode?.episode_type !== 'period' && (
              <Flex>
                <div
                  className="video-border-edit-icon enabledClickable"
                  onClick={() => {
                    editTimeLine(-5);
                  }}
                >
                  <LeftVideoBorderPush />
                </div>
                <div
                  className="video-border-edit-icon enabledClickable"
                  onClick={() => {
                    editTimeLine(5);
                  }}
                >
                  <RightVideoBorderPush />
                </div>
              </Flex>
            )}
          </Flex>
          <div className={'flex-row f-ga-4 ai-c '}>
            {reelsModeOn && (
              <span
                className={'enabledClickable flex-column flex-row j-ce'}
                onMouseEnter={() =>
                  handleMouseEnter(setPlayPreviousTooltipVisible)
                }
                onMouseLeave={() =>
                  handleMouseLeave(setPlayPreviousTooltipVisible)
                }
                onClick={goToPreviousFrame}
              >
                <Tooltip
                  open={playPreviousTooltipVisible}
                  title={t(
                    `Previous keyframe ${detectOS() === 'MacOS' ? 'CMD' : 'CTRL'} + <-`,
                  )}
                  align={{ offset: [10, -35] }}
                >
                  <PreviousKeyFrameIcon />
                </Tooltip>
              </span>
            )}
            {!reelsModeOn && (
              <>
                <div
                  className="video-border-edit-icon enabledClickable"
                  style={{ width: 40 }}
                >
                  <Tooltip
                    open={playPreviousTooltipVisible}
                    title={t(
                      `Previous episode ${detectOS() === 'MacOS' ? 'CMD' : 'CTRL'} + <-`,
                    )}
                    align={{ offset: [10, -35] }}
                  >
                    <RewindIcon
                      onMouseEnter={() =>
                        handleMouseEnter(setPlayPreviousTooltipVisible)
                      }
                      onMouseLeave={() =>
                        handleMouseLeave(setPlayPreviousTooltipVisible)
                      }
                      onClick={() =>
                        nextEpsiodeButtonHandler(togglePreviousEpisode)
                      }
                    />
                  </Tooltip>
                </div>
              </>
            )}
            <div
              className={'enabledClickable video-border-edit-icon'}
              onClick={() => dispatch(AC_SetIsPlayingVideoAction(!isPlaying))}
            >
              {playIcon}
            </div>
            {reelsModeOn && (
              <span
                className={'enabledClickable flex-column flex-row j-ce'}
                onMouseEnter={() => handleMouseEnter(setPlayNextTooltipVisible)}
                onMouseLeave={() => handleMouseLeave(setPlayNextTooltipVisible)}
                onClick={goToNextFrame}
              >
                <Tooltip
                  align={{ offset: [10, -35] }}
                  open={playNextTooltipVisible}
                  style={{ width: 200 }}
                  title={t(
                    `Next keyframe ${detectOS() === 'MacOS' ? 'CMD' : 'CTRL'} + ->`,
                  )}
                >
                  <NextKeyFrameIcon />
                </Tooltip>
              </span>
            )}
            {!reelsModeOn && (
              <>
                <div
                  className="video-border-edit-icon enabledClickable"
                  style={{ width: 40 }}
                >
                  <Tooltip
                    align={{ offset: [10, -35] }}
                    open={playNextTooltipVisible}
                    title={t(
                      `Next episode ${detectOS() === 'MacOS' ? 'CMD' : 'CTRL'} + ->`,
                    )}
                  >
                    <ForwardIcon
                      onMouseEnter={() =>
                        handleMouseEnter(setPlayNextTooltipVisible)
                      }
                      onMouseLeave={() =>
                        handleMouseLeave(setPlayNextTooltipVisible)
                      }
                      onClick={() =>
                        nextEpsiodeButtonHandler(toggleNextEpisode)
                      }
                    />
                  </Tooltip>
                </div>
              </>
            )}
          </div>
          {playerMode === '' ? (
            <VideoTimelineZoomControl />
          ) : (
            <HelperPlayerButtons
              playbackRate={playbackRate}
              setPlaybackRate={setPlaybackRate}
              toggleFullScreen={toggleFullScreen}
              isNarrowScreenMode={isNarrowScreenMode}
              volume={volume}
              setVolume={setVolume}
              setIsMuted={setIsMuted}
              playerState={playerState}
              setRewindSpeed={setRewindSpeed}
              rewindSpeed={rewindSpeed}
            />
          )}
        </>
      ) : (
        <div style={{ height: '27px' }} />
      )}
    </div>
  );
};
export default VideoPlayerButtonsController;
