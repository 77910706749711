export const makeStatsPlaceholders = (
  t: any,
  sportId: number,
  metricsRegistry?: any,
) => {
  const SPORT_SPECIFIC_LABELS: any = {
    2: {
      goal_kick: t('Goal clearance'),
      out: t('Kick-ins'),
    },
  };
  const currentLabels = SPORT_SPECIFIC_LABELS[sportId];
  if (!metricsRegistry || metricsRegistry.stats.length === 0) {
    return [];
    // return [
    //   {
    //     label: t('Goals'),
    //     key: 'goal',
    //     stat: 'loading',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Assists'),
    //     key: 'goal_pass',
    //     stat: 'loading',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: false,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Shots'),
    //     key: 'shot',
    //     stat: 'loading',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Passes'),
    //     key: 'pass',
    //     stat: 'loading',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: false,
    //     ordering: 0,
    //     children: [
    //       {
    //         label: t('forward__'),
    //         stat: 'loading',
    //         key: 'pass_forward',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('progressive'),
    //         stat: 'loading',
    //         key: 'pass_progressive',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('key'),
    //         stat: 'loading',
    //         key: 'key_pass',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('into 3/3'),
    //         stat: 'loading',
    //         key: 'pass_to_3rd',
    //         for_sport: [],
    //         excluded_sports: [2],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('into 4/4'),
    //         stat: 'loading',
    //         key: 'pass_to_4th',
    //         for_sport: [2],
    //         excluded_sports: [],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('into pen. area'),
    //         stat: 'loading',
    //         key: 'pass_to_box',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('crosses'),
    //         stat: 'loading',
    //         key: 'pass_cross',
    //         for_sport: [],
    //         excluded_sports: [2],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('long'),
    //         stat: 'loading',
    //         key: 'pass_long',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //     ],
    //   },
    //   {
    //     label: t('Dribbles'),
    //     stat: 'loading',
    //     key: 'dribble',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Tackles'),
    //     stat: 'loading',
    //     key: 'tackle',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Tackles 1v1'),
    //     stat: 'loading',
    //     key: 'tackle1-1',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Interceptions'),
    //     stat: 'loading',
    //     key: 'interception',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('2nd balls'),
    //     stat: 'loading',
    //     key: 'recovery',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   // {
    //   //   label: t('Shots blocked'),
    //   //   stat: 'init',
    //   //   key: 'shots_blocked',
    //   //   for_sport: [],
    //   //   excluded_sports: [],
    //   // },
    //   {
    //     label: t('Shots blocked (opponent)'),
    //     stat: 'loading',
    //     key: 'shots_blocked_opponent',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Duels'),
    //     stat: 'loading',
    //     key: 'duel',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //     children: [
    //       {
    //         label: t('ground defensive'),
    //         stat: 'loading',
    //         key: 'ground_duel_defence',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: true,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('aerial defensive'),
    //         stat: 'loading',
    //         key: 'aerial_duel_defence',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: true,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('ground offensive'),
    //         stat: 'loading',
    //         key: 'ground_duel_offence',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: true,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('aerial offensive'),
    //         stat: 'loading',
    //         key: 'aerial_duel_offence',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: true,
    //         ordering: 0,
    //       },
    //     ],
    //   },
    //   {
    //     label: t('Losses'),
    //     stat: 'loading',
    //     key: 'turnover',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     disabled: true,
    //     ordering: 0,
    //     children: [
    //       {
    //         label: t('inaccurate passes'),
    //         stat: 'loading',
    //         key: 'turnover_pass_inac',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: false,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('bad 1st touches'),
    //         stat: 'loading',
    //         key: 'turnover_receipt_fail',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: true,
    //         ordering: 0,
    //       },
    //       {
    //         label: t('unsuccessful dribbles'),
    //         stat: 'loading',
    //         key: 'turnover_dribble_fail',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: true,
    //         ordering: 0,
    //       },
    //       {
    //         // eslint-disable-next-line quotes
    //         label: t("opponent's tackles"),
    //         stat: 'loading',
    //         key: 'turnover_tackled',
    //         for_sport: [],
    //         excluded_sports: [],
    //         as_point: true,
    //         ordering: 0,
    //       },
    //       // <ConfigProvider
    //       //   theme={{
    //       //     token: {
    //       //       marginLG: 0,
    //       //     },
    //       //   }}
    //       // >
    //       //   <Divider />
    //       // </ConfigProvider>,
    //     ],
    //   },
    //   {
    //     label: t('Corners'),
    //     stat: 'loading',
    //     key: 'corner',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: false,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Direct free kicks'),
    //     stat: 'loading',
    //     key: 'freekick_shot',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Indirect free kicks'),
    //     stat: 'loading',
    //     key: 'freekick_pass',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: false,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Penalties'),
    //     stat: 'loading',
    //     key: 'penalty',
    //     excluded_sports: [],
    //     for_sport: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Double penalties'),
    //     stat: 'loading',
    //     key: 'double_penalty',
    //     for_sport: [2],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: currentLabels
    //       ? currentLabels['out'] || t('Throw-ins')
    //       : t('Throw-ins'),
    //     stat: 'loading',
    //     key: 'out',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: false,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Mistakes'),
    //     stat: 'loading',
    //     key: 'error',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Fouls'),
    //     stat: 'loading',
    //     key: 'foul',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('Fouls suffered'),
    //     stat: 'loading',
    //     key: 'foul_taken',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('YC'),
    //     stat: 'loading',
    //     key: 'yellow_cards',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    //   {
    //     label: t('RC'),
    //     stat: 'loading',
    //     key: 'red_cards',
    //     for_sport: [],
    //     excluded_sports: [],
    //     as_point: true,
    //     ordering: 0,
    //   },
    // ];
  }
  return metricsRegistry.stats.map((stat: any) => {
    let label;
    if (stat.key === 'out') {
      if (currentLabels) {
        label = currentLabels['out'];
      } else {
        label = t(stat.name);
      }
    } else if (stat.key === 'goal_kick') {
      if (currentLabels) {
        label = currentLabels['goal_kick'];
      } else {
        label = t(stat.name);
      }
    } else {
      label = t(stat.name);
    }
    if (stat.children?.length) {
      return {
        ...stat,
        label: label,
        stat: 'loading',
        children: stat.children.map((child: any) => {
          return {
            ...child,
            label: t(child.name_short),
            stat: 'loading',
          };
        }),
      };
    } else {
      return {
        ...stat,
        label: label,
        stat: 'loading',
      };
    }
  });
};

export const makeLink = (
  matches: any[],
  team?: any,
  stats?: any[],
  part?: any,
  selectedSeason?: any,
  playerId?: any,
  role?: string | null,
  opposite = false,
) => {
  let link = '/player?';
  let added = false;
  matches.forEach((match) => {
    if (match.id) {
      link = `${link}${added ? '&' : ''}eventId=${match.id}`;
      added = true;
    }
  });
  if (team) {
    if (part === 'highlights') {
      link = `${link}${added ? '&' : ''}teamId=${team?.id}`;
      added = true;
    } else {
      link = `${link}${added ? '&' : ''}teamId=${team?.id}&tid=${team?.id}`;
      added = true;
    }
  }
  if (role) {
    link = `${link}${added ? '&' : ''}role=${role}`;
    added = true;
  }
  if (opposite) {
    link = `${link}${added ? '&' : ''}o=${opposite}`;
    added = true;
  }
  if (selectedSeason) {
    link = `${link}${added ? '&' : ''}s=${selectedSeason}`;
  }
  added = true;
  if (stats?.length) {
    stats.forEach((stat: any) => {
      link = `${link}${added ? '&' : ''}m=${stat.key}`;
      added = true;
    });
  }
  if (playerId) {
    link = `${link}&pl=${playerId}`;
  }
  // if (matches.length) {
  //   console.log('matches');
  //   console.log(matches);
  //   dispatch(
  //     AC_setIntermediateActivitiesAction(
  //       matches.map((match) => {
  //         if (match.id !== null) {
  //           return match.id;
  //         }
  //       }) || [],
  //     ),
  //   );
  // }
  // dispatch(
  //   setStatsCustomGameList(
  //     matches.map((match) => {
  //       if (match.id !== null) {
  //         return match.id;
  //       }
  //     }),
  //   ),
  // );
  return link;
};
