import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { updatePlaylistAction } from 'actions/playlistV2.async.actions';
import { PlaylistsAPI } from 'api/playlists';
import { ReactComponent as CheckboxFilled } from 'assets/img/icons/faCheckboxFilled.svg';
import { ReactComponent as CloseCross } from 'assets/img/icons/faCloseCrossWhite.svg';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { NEW_PLAYLIST_TEXT } from 'types/constants';
import { generateUUID } from 'types/crypto';

import { AC_SetPlayerHotkeysAllowedAction } from '../../../actions/player.acitons';
import { useAppDispatch } from '../../../store';

export const RenamePlaylistForm = (props: any) => {
  const inputRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const [renamePlaylistPlaceholder, setRenamePlaylistPlaceholder] = useState(
    t(NEW_PLAYLIST_TEXT),
  );
  const { showNotification } = notificationsReducer.actions;
  const { AC_setOpenedPlaylist } = playlistReducerV2.actions;
  useEffect(() => {
    const language = localStorage.getItem('i18nextLng') || 'en';
    PlaylistsAPI.getSuggestedPlaylistName(language).then((response: any) => {
      setRenamePlaylistPlaceholder(response.data.name);
    });
  }, []);
  const { openedPlaylist } = useSelector((state: AppStateType) => {
    return {
      openedPlaylist: state.playlistReducerV2.openedPlaylist,
    };
  });
  const renamePlaylistHandler = () => {
    dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    if (openedPlaylist) {
      PlaylistsAPI.updatePlaylist(
        openedPlaylist?.id,
        props.newPlaylistName || renamePlaylistPlaceholder,
      ).then((result) => {
        if (result.status === 'error') {
          props.setInputStatus('error');
          dispatch(
            showNotification({
              notificationParameters: [
                {
                  id: generateUUID(),
                  text: t('Playlist with this name already exists'),
                  type: 'error',
                },
              ],
            }),
          );
        } else {
          dispatch(updatePlaylistAction());
          props.setRenameCurrentPlaylist(false);
          dispatch(
            AC_setOpenedPlaylist({
              ...openedPlaylist,
              name: props.newPlaylistName || renamePlaylistPlaceholder,
            }),
          );
          dispatch(
            showNotification({
              notificationParameters: [
                {
                  id: generateUUID(),
                  text: t('Playlist was changed'),
                },
              ],
            }),
          );
        }
      });
    } else {
      props.setInputStatus('error');
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: 'var(--colorTextPlaceholder)',
          fontFamily: 'SFProTextRegular',
          fontSize: 14,
        },
      }}
    >
      <Input
        required={true}
        ref={inputRef}
        status={props.inputStatus}
        className="new-folder-input"
        type="text"
        name="newFolderName"
        onChange={(event: any) => {
          props.setInputStatus('');
          props.setNewPlaylistName(event.target.value);
        }}
        autoFocus={true}
        maxLength={100}
        placeholder={renamePlaylistPlaceholder}
        defaultValue={props.openedPlaylist?.name}
        style={{
          background: 'var(--colorBgContainer)',
          color: 'var(--colorText)',
        }}
      ></Input>
      <Button
        style={{
          border: '1px solid var(--colorIcon)',
          background: 'transparent',
          height: 24,
          width: 32,
          lineHeight: 0,
        }}
        className="bnt-line-height"
        onClick={() => {
          props.setInputStatus('');
          dispatch(AC_SetPlayerHotkeysAllowedAction(true));
          props.setRenameCurrentPlaylist(false);
        }}
        icon={<CloseCross />}
      />
      <Button
        style={{
          height: 24,
          width: 32,
          lineHeight: 0,
          border: '1px solid var(--colorPrimaryBase)',

          background: 'rgba(22, 104, 220, 0.4)',
        }}
        className="bnt-line-height"
        onClick={() => renamePlaylistHandler()}
        icon={<CheckboxFilled />}
        type="default"
      />
    </ConfigProvider>
  );
};
