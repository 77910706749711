import { playerReducerV2 } from 'reducers/player.reducer';
import { OpenedPlaylistEventType } from 'types/playlist.types';

import {
  AC_AddEventsToExpandedVideo,
  AC_AddUserEventsToExpandedVideo,
  AC_SetActiveVideoAction,
  AC_SetIsPlayingVideoAction,
  // AC_SetPlayedEpisodeAction,
  AC_SetPlayerCommandAction,
  AC_SetPlayerLoadingAction,
  AC_ToggleIsCollapsed,
} from '../../actions/player.acitons';
import { EventsAPI } from '../../api/events';
import { PlaylistsAPI } from '../../api/playlists';
import { useAppDispatch } from '../../store';
import {
  API_ENDPOINT,
  WINDOW_MODE_WIDTH,
  // WINDOW_MODE_WIDTH_MS,
} from '../../types/constants';
import { filterAndIndex } from '../../types/functions';
import {
  EpisodeTypes,
  GameVideoEpisodeType,
  GameVideoType,
  PlayCommandType,
  PlaylistType,
  VideoFileType,
} from '../../types/types';
import { MODES } from '../VideoListController';

export class VideListPlayHandler {
  videoBlocks: Array<PlaylistType | GameVideoType>; // плейлисты или отфильтрованные игры

  playerMode: string; // match | playlist

  currentVideoBlock: PlaylistType | GameVideoType; // текущий проигрываемый матч или плейлист. Берется из playedVideoSet

  currentVideoEpisode: VideoFileType; // текущий проигрываемый эпизод, часть матча/тренировки, матч/тренировка

  isPlaying: boolean; // статус проигрывания

  playConsecutive: boolean; // флаг проигрывания видео подряд

  sharedPlaylist: Array<any>;

  currentEpisode: GameVideoEpisodeType;

  currentPlayerState: any;

  playlists: { playlists: OpenedPlaylistEventType[] };

  emptyIterations: number;

  editedEpisodeRange: number[];

  visibleRange: number[];

  constructor(props: any) {
    this.videoBlocks = props.videoElements;
    this.playerMode = props.playerMode;
    this.sharedPlaylist = props.sharedPlaylist;
    this.currentVideoBlock = props.playedVideoSet;
    this.currentVideoEpisode = props.currentVideoEpisode;
    this.isPlaying = props.isPlaying;
    this.currentEpisode = props.currentEpisode;
    this.currentPlayerState = props.currentPlayerState;
    this.playConsecutive = props.playConsecutive;
    this.playlists = props.playlists;
    this.emptyIterations = 0;
    this.editedEpisodeRange = props.editedEpisodeRange;
    this.visibleRange = props.visibleRange;
  }

  dispatch = useAppDispatch();

  playEpisode = (newActiveVideo: VideoFileType, filteredEpisode: any) => {
    const { setPlayedVideoSet, setPlayedEpsiodeAction } =
      playerReducerV2.actions;
    const call = async () => {
      await this.dispatch(AC_SetPlayerLoadingAction(true));
      await this.dispatch(AC_SetActiveVideoAction(newActiveVideo));
      // await this.dispatch(
      //   AC_SetPlayedEpisodeAction(filteredEpisode, this.currentVideoBlock),
      // );
      await this.dispatch(setPlayedVideoSet(this.currentVideoBlock));
      await this.dispatch(setPlayedEpsiodeAction(filteredEpisode));
      await this.dispatch(AC_SetPlayerCommandAction(PlayCommandType.play));
      await this.dispatch(AC_SetPlayerLoadingAction(false));
    };
    call().then();
  };

  monitorPlayback = async () => {
    if (this.isPlaying) {
      let trackedTimer;
      let timerType = 'system';
      if (this.currentEpisode?.user_timer) {
        trackedTimer = this.currentEpisode.user_timer;
        timerType = 'user';
      } else {
        trackedTimer = this.currentEpisode.file_timer;
      }
      if (this.editedEpisodeRange?.length > 0) {
        // console.log(this.visibleRange[0]);
        if (
          this.visibleRange.length > 0 &&
          this.currentPlayerState?.playedSeconds >= this.visibleRange[1] / 1000
        ) {
          this.dispatch(AC_SetIsPlayingVideoAction(false));
        }
        return;
      }
      let windowOffset = WINDOW_MODE_WIDTH;
      if (this.currentEpisode.episode_type === EpisodeTypes.period) {
        windowOffset = 0;
      }
      if (
        (trackedTimer &&
          timerType === 'system' &&
          this.currentPlayerState?.playedSeconds >=
            trackedTimer.finished_at / 1000 + windowOffset) ||
        (timerType === 'user' &&
          this.currentPlayerState?.playedSeconds >=
            trackedTimer.finished_at / 1000)
      ) {
        this.dispatch(AC_SetIsPlayingVideoAction(false));
        if (this.playConsecutive) {
          this.playNext().then();
        } else {
          const { setPlayedVideoSet, setPlayedEpsiodeAction } =
            playerReducerV2.actions;
          // this.dispatch(AC_SetPlayedEpisodeAction(null, null));
          await this.dispatch(setPlayedEpsiodeAction(null));
          await this.dispatch(setPlayedVideoSet(null));
        }
      }
    }
  };

  playNthEpisode = (episodes: any, nextVideoName: string, index: number) => {
    this.emptyIterations = 0;
    const newActiveVideo: VideoFileType = {
      // @ts-ignore
      id: episodes[index].id,
      // @ts-ignore
      videoId: episodes[index].video.id,
      name: nextVideoName,
      // @ts-ignore
      file_path: `${API_ENDPOINT}api/data/videos/${episodes[index].video.id}/view/`,
      file_url: episodes[index].video.file_url,
      // @ts-ignore
      duration: episodes[index].video.meta.video_length,
    };
    this.playEpisode(newActiveVideo, episodes[index]);
  };

  playNext = async () => {
    let newActiveVideo;
    let nextEpisode;
    if (
      this.currentEpisode.episode_type === EpisodeTypes.episode ||
      this.currentEpisode.episode_type === EpisodeTypes.period
    ) {
      const newEpisodeData = this.getNextEpisode();
      newActiveVideo = newEpisodeData.newActiveVideo;
      nextEpisode = newEpisodeData.nextEpisode;
    } else if (this.currentEpisode.episode_type === EpisodeTypes.user_episode) {
      const userEpisode = this.getNextUserEpisode();
      newActiveVideo = userEpisode.newActiveVideo;
      nextEpisode = userEpisode.nextUserEpisode;
    }
    if (newActiveVideo && nextEpisode) {
      // console.log("PLAY NEXT EPISODE")
      this.playEpisode(newActiveVideo, nextEpisode);
      // } else if (this.sharedPlaylist?.length) {
      //   this.playNthEpisode(this.sharedPlaylist, '', 0);
    } else {
      const nextVideo: any = this.getNextVideo();
      if (nextVideo) {
        // console.log("PLAY NEXT VIDEO")
        this.currentVideoBlock = nextVideo;
        let episodes;
        let user_episodes;
        if (!nextVideo.isExpanded) {
          let events;
          let user_episodes_data;
          if (this.playerMode === MODES.playlist_view) {
            events = await PlaylistsAPI.getPlaylistDetails(nextVideo.id);
            this.dispatch(
              AC_AddEventsToExpandedVideo(
                events.data.playlist_events,
                nextVideo.id,
              ),
            );
            episodes = events.data.playlist_events;
          } else {
            events = await EventsAPI.getEpisodesByIds(nextVideo.events);
            this.dispatch(
              AC_AddEventsToExpandedVideo(events.data.results, nextVideo.id),
            );
            episodes = events.data.results;
            if (nextVideo.user_episodes) {
              user_episodes_data = await EventsAPI.getUserEpisodesByIds(
                nextVideo.user_episodes,
              );
              this.dispatch(
                AC_AddUserEventsToExpandedVideo(
                  user_episodes_data.data.results,
                  nextVideo.id,
                ),
              );
              user_episodes = user_episodes_data.data.results;
            }
          }
          this.dispatch(AC_ToggleIsCollapsed(nextVideo.id));
        } else {
          episodes = nextVideo.episodes;
          user_episodes = nextVideo.user_episodes;
        }
        if (
          this.currentEpisode.episode_type === EpisodeTypes.episode ||
          this.currentEpisode.episode_type === EpisodeTypes.period
        ) {
          await this.iterateVideos(nextVideo, episodes);
        }
        if (this.currentEpisode.episode_type === EpisodeTypes.user_episode) {
          await this.iterateVideos(nextVideo, user_episodes);
        }
      }
    }
  };

  playPrevious = async () => {
    // console.log('SWITCHING TO NEXT');
    let newActiveVideo;
    let previousEpisode;
    if (
      this.currentEpisode.episode_type === EpisodeTypes.episode ||
      this.currentEpisode.episode_type === EpisodeTypes.period
    ) {
      const newEpisodeData = this.getPreviousEpisode();
      newActiveVideo = newEpisodeData.newActiveVideo;
      previousEpisode = newEpisodeData.previousEpisode;
    } else if (this.currentEpisode.episode_type === EpisodeTypes.user_episode) {
      const userEpisode = this.getPreviousUserEpisode();
      newActiveVideo = userEpisode.newActiveVideo;
      previousEpisode = userEpisode.previousUserEpisode;
    }
    if (newActiveVideo && previousEpisode) {
      // console.log("PLAY NEXT EPISODE")
      this.playEpisode(newActiveVideo, previousEpisode);
    } else {
      const nextVideo: any = this.getPreviousVideo();
      if (nextVideo) {
        // console.log("PLAY NEXT VIDEO")
        this.currentVideoBlock = nextVideo;
        let episodes;
        let user_episodes;
        if (!nextVideo.isExpanded) {
          let events;
          let user_episodes_data;
          if (this.playerMode === MODES.playlist_view) {
            events = await PlaylistsAPI.getPlaylistDetails(nextVideo.id);
            this.dispatch(
              AC_AddEventsToExpandedVideo(
                events.data.playlist_events,
                nextVideo.id,
              ),
            );
            episodes = events.data.playlist_events;
          } else {
            // console.log('HERE 2')
            events = await EventsAPI.getEpisodesByIds(nextVideo.events);
            this.dispatch(
              AC_AddEventsToExpandedVideo(events.data.results, nextVideo.id),
            );
            episodes = events.data.results;
            if (nextVideo.user_episodes) {
              user_episodes_data = await EventsAPI.getUserEpisodesByIds(
                nextVideo.user_episodes,
              );
              this.dispatch(
                AC_AddUserEventsToExpandedVideo(
                  user_episodes_data.data.results,
                  nextVideo.id,
                ),
              );
              user_episodes = user_episodes_data.data.results;
            }
          }
          this.dispatch(AC_ToggleIsCollapsed(nextVideo.id));
        } else {
          episodes = nextVideo.episodes;
          user_episodes = nextVideo.user_episodes;
        }
        if (
          this.currentEpisode.episode_type === EpisodeTypes.episode ||
          this.currentEpisode.episode_type === EpisodeTypes.period
        ) {
          await this.iterateVideosBackward(nextVideo, episodes);
        }
        if (this.currentEpisode.episode_type === EpisodeTypes.user_episode) {
          await this.iterateVideosBackward(nextVideo, user_episodes);
        }
      }
    }
  };

  iterateVideos = async (nextVideo: any, episodes: any) => {
    if (nextVideo && episodes && episodes.length > 0) {
      // console.log('START NEXT BLOCK', episodes)
      this.playNthEpisode(episodes, nextVideo.name, 0);
    } else if (this.emptyIterations < 10) {
      this.emptyIterations += 1;
      // console.log('ITERATE', this.emptyIterations);
      this.currentVideoBlock = nextVideo;
      await this.playNext();
    }
  };
  iterateVideosBackward = async (nextVideo: any, episodes: any) => {
    if (nextVideo && episodes && episodes.length > 0) {
      this.playNthEpisode(episodes, nextVideo.name, episodes.length - 1);
    } else if (this.emptyIterations < 10) {
      this.emptyIterations += 1;
      this.currentVideoBlock = nextVideo;
      await this.playPrevious();
    }
  };

  getNextVideo = () => {
    let base;
    if (this.playerMode === MODES.playlist_view) {
      base = this.playlists.playlists || this.sharedPlaylist;
    } else {
      base = this.videoBlocks;
    }
    const filteredBlock = filterAndIndex(base, this.currentVideoBlock.id, 'id');
    let nextBlock:
      | GameVideoType
      | PlaylistType
      | OpenedPlaylistEventType
      | null;
    // console.log('filteredBlock', filteredBlock);
    // console.log('this.playlists', this.playlists);
    // console.log('this.currentVideoBlock.id', this.currentVideoBlock.id);
    if (filteredBlock.index + 1 < base.length) {
      nextBlock = base[filteredBlock.index + 1];
    } else {
      // nextBlock = base[0];
      nextBlock = null;
    }
    return nextBlock;
  };

  getPreviousVideo = () => {
    let base;
    if (this.playerMode === MODES.playlist_view) {
      base = this.playlists.playlists || this.sharedPlaylist;
    } else {
      base = this.videoBlocks;
    }
    const filteredBlock = filterAndIndex(base, this.currentVideoBlock.id, 'id');
    let nextBlock:
      | GameVideoType
      | PlaylistType
      | OpenedPlaylistEventType
      | null;
    if (filteredBlock.index > 0) {
      nextBlock = base[filteredBlock.index - 1];
    } else {
      nextBlock = null;
      // nextBlock = base[base.length - 1];
    }
    return nextBlock;
  };

  getNextEpisode = () => {
    const hasPlaylists =
      (this.playerMode === MODES.playlist_view &&
        this.playlists.playlists?.length > 0) ||
      this.sharedPlaylist?.length > 0;
    const hasFilteredVideos =
      this.playerMode === MODES.episodes && this.videoBlocks.length > 0;
    if (
      (hasPlaylists || hasFilteredVideos) &&
      this.currentVideoBlock &&
      this.currentVideoBlock?.episodes
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { element, index } = filterAndIndex(
        this.currentVideoBlock?.episodes,
        this.currentEpisode.id,
        'id',
      );
      if (
        this.currentVideoBlock.episodes &&
        index + 1 < this.currentVideoBlock.episodes.length
      ) {
        // есть следующий эпизод
        const newActiveVideo: VideoFileType = {
          id: this.currentVideoBlock.episodes[index + 1].id,
          // @ts-ignore
          videoId: this.currentVideoBlock.episodes[index + 1].video.id,
          name: this.currentVideoBlock.name,
          // @ts-ignore
          file_path: `${API_ENDPOINT}api/data/videos/${this.currentVideoBlock.episodes[index + 1].video.id}/view/`,
          file_url: this.currentVideoBlock.episodes[index + 1].video.file_url,
          duration:
            // @ts-ignore
            this.currentVideoBlock.episodes[index + 1].video.meta.video_length,
        };
        const nextEpisode = this.currentVideoBlock?.episodes[index + 1];
        return { newActiveVideo, nextEpisode };
      }
    }
    return {};
  };
  getPreviousEpisode = () => {
    const hasPlaylists =
      (this.playerMode === MODES.playlist_view &&
        this.playlists.playlists?.length > 0) ||
      this.sharedPlaylist?.length > 0;
    const hasFilteredVideos =
      this.playerMode === MODES.episodes && this.videoBlocks.length > 0;
    if (
      (hasPlaylists || hasFilteredVideos) &&
      this.currentVideoBlock &&
      this.currentVideoBlock?.episodes
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { element, index } = filterAndIndex(
        this.currentVideoBlock?.episodes,
        this.currentEpisode.id,
        'id',
      );
      if (this.currentVideoBlock.episodes && index > 0) {
        // есть следующий эпизод
        const newActiveVideo: VideoFileType = {
          id: this.currentVideoBlock.episodes[index - 1].id,
          // @ts-ignore
          videoId: this.currentVideoBlock.episodes[index - 1].video.id,
          name: this.currentVideoBlock.name,
          // @ts-ignore
          file_path: `${API_ENDPOINT}api/data/videos/${this.currentVideoBlock.episodes[index - 1].video.id}/view/`,
          file_url: this.currentVideoBlock.episodes[index - 1].video.file_url,
          duration:
            // @ts-ignore
            this.currentVideoBlock.episodes[index - 1].video.meta.video_length,
        };
        const previousEpisode = this.currentVideoBlock?.episodes[index - 1];
        return { newActiveVideo, previousEpisode };
      }
    }
    return {};
  };
  getNextUserEpisode = () => {
    if (this.currentVideoBlock?.user_episodes) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { element, index } = filterAndIndex(
        this.currentVideoBlock?.user_episodes,
        this.currentEpisode.id,
        'id',
      );
      if (
        index > -1 &&
        this.currentVideoBlock.user_episodes &&
        index + 1 < this.currentVideoBlock.user_episodes.length
      ) {
        const newActiveVideo: VideoFileType = {
          id: this.currentVideoBlock.user_episodes[index + 1],
          // @ts-ignore
          videoId: this.currentVideoBlock.user_episodes[index + 1].video.id,
          name: this.currentVideoBlock.name,
          // @ts-ignore
          file_path: `${API_ENDPOINT}api/data/videos/${this.currentVideoBlock.user_episodes[index + 1].video.id}/view/`,
          // @ts-ignore
          file_url:
            // @ts-ignore
            this.currentVideoBlock.user_episodes[index + 1].video.file_url,
          duration:
            // @ts-ignore
            this.currentVideoBlock.user_episodes[index + 1].video.meta
              .video_length,
        };
        // console.log('nextEpsiode', index, element)
        const nextUserEpisode =
          this.currentVideoBlock?.user_episodes[index + 1];
        return { newActiveVideo, nextUserEpisode };
      }
    }
    return {};
  };
  getPreviousUserEpisode = () => {
    if (this.currentVideoBlock?.user_episodes) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { element, index } = filterAndIndex(
        this.currentVideoBlock?.user_episodes,
        this.currentEpisode.id,
        'id',
      );
      if (index >= 1 && this.currentVideoBlock.user_episodes) {
        const newActiveVideo: VideoFileType = {
          id: this.currentVideoBlock.user_episodes[index - 1],
          // @ts-ignore
          videoId: this.currentVideoBlock.user_episodes[index - 1].video.id,
          name: this.currentVideoBlock.name,
          // @ts-ignore
          file_path: `${API_ENDPOINT}api/data/videos/${this.currentVideoBlock.user_episodes[index - 1].video.id}/view/`,
          file_url:
            // @ts-ignore
            this.currentVideoBlock.user_episodes[index - 1].video.file_url,
          duration:
            // @ts-ignore
            this.currentVideoBlock.user_episodes[index - 1].video.meta
              .video_length,
        };
        const previousUserEpisode =
          this.currentVideoBlock?.user_episodes[index - 1];
        return { newActiveVideo, previousUserEpisode };
      }
    }
    return {};
  };
}
