import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
  setCurrentClubErrorAction,
  setCurrentClubAction,
  ClubsActionsTypes,
  SetCurrentUserTeamAction,
  AC_SetCurrentSelectedTeamEvent,
} from './clubs.actions';
import { AuthAPI } from '../api/authentication';
import { ClubsAPI } from '../api/clubs';
import { AppStateType } from '../reducers';
import {
  CLEAR_ERROR,
  LOGIN_ACTION_ERROR,
  LOGIN_ACTION_SUCCESS,
  RECOVER_PASSWORD_ACTION_SUCCESS,
  SEND_LOGIN_ACTION,
  SET_CURRENT_USER,
  SET_CURRENT_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_REQUEST_ERROR,
  UPDATE_USER_REQUEST_SUCCESS,
} from '../types/action.types';
import { TeamType, UserType, UserUpdateType } from '../types/types';

export type AuthActionsType =
  | SendLoginActionType
  | LoginActionSuccessType
  | ResetSuccessType
  | SetCurrentUserActionType
  | SetCurrentUserErrorActionType
  | UpdateUserRequestActionType
  | UpdateUserRequestErrorActionType
  | UpdateUserRequestSuccessActionType
  | LoginErrorType
  | ClearErrorType;

type SendLoginActionType = {
  type: typeof SEND_LOGIN_ACTION;
  payload: LoginRequestType;
};
type LoginRequestType = {
  email: string;
  password: string;
};
export type LoginActionSuccessType = {
  type: typeof LOGIN_ACTION_SUCCESS;
};
export type ResetSuccessType = {
  type: typeof RECOVER_PASSWORD_ACTION_SUCCESS;
};

const LoginSuccessAction = (): LoginActionSuccessType => ({
  type: LOGIN_ACTION_SUCCESS,
});
const ResetSuccessAction = (): ResetSuccessType => ({
  type: RECOVER_PASSWORD_ACTION_SUCCESS,
});
type LoginErrorType = {
  type: typeof LOGIN_ACTION_ERROR;
  payload: string;
};
const AC_LoginErrorAction = (error: string): LoginErrorType => ({
  type: LOGIN_ACTION_ERROR,
  payload: error,
});
type ClearErrorType = {
  type: typeof CLEAR_ERROR;
};
export const AC_ClearErrorAction = (): ClearErrorType => ({
  type: CLEAR_ERROR,
});
export const SendLoginRequestAction =
  (
    email: string,
    password: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, AuthActionsType> =>
  async (dispatch) => {
    try {
      const response = await AuthAPI.login({ email, password });
      if (response.status === 200) {
        if (typeof window !== 'undefined' && window.localStorage) {
          await localStorage.setItem('authtoken', response.data.access);
          if (response.data.multi_token) {
            await localStorage.setItem(
              'multi_token',
              response.data.multi_token,
            );
          }
        }
        await localStorage.setItem('refreshToken', response.data.refresh);
        const userResponse = await AuthAPI.getCurrentUser();
        if (userResponse.status === 200) {
          const user = userResponse.data;
          dispatch(setCurrentUserAction(userResponse.data));
          if (user.teams && user.teams.length > 0) {
            localStorage.setItem(
              'redirectTo',
              `/teams/${user.main_team || user.teams[0]}`,
            );
          } else {
            localStorage.setItem('redirectTo', '/');
          }
        }
        dispatch(GetCurrentUserAction());
        dispatch(LoginSuccessAction());
      }
    } catch (err: any) {
      console.log(err);
      dispatch(AC_LoginErrorAction(err?.response?.data?.detail));
    }
  };
export const SendResetPasswordRequestAction =
  (
    email: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, AuthActionsType> =>
  async (dispatch) => {
    await AuthAPI.resetPassword({ email });
    dispatch(ResetSuccessAction());
  };
type SetCurrentUserActionType = {
  type: typeof SET_CURRENT_USER;
  payload: UserType;
};
const setCurrentUserAction = (userData: UserType): SetCurrentUserActionType => {
  return {
    type: SET_CURRENT_USER,
    payload: userData,
  };
};
type SetCurrentUserErrorActionType = {
  type: typeof SET_CURRENT_USER_ERROR;
  payload: any;
};
const setCurrentUserErrorAction = (
  error: any,
): SetCurrentUserErrorActionType => {
  return {
    type: SET_CURRENT_USER_ERROR,
    payload: error,
  };
};

export const GetCurrentUserAction =
  (): ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AuthActionsType | ClubsActionsTypes
  > =>
  async (dispatch) => {
    try {
      const response = await AuthAPI.getCurrentUser();
      if (response.status === 200) {
        const user = response.data;
        localStorage.setItem('multi_token', user.multi_token);
        dispatch(setCurrentUserAction(response.data));
        if (user.clubs.length > 0) {
          const clubResponse = await ClubsAPI.getClub(user.clubs[0]);
          if (clubResponse.status === 200) {
            dispatch(setCurrentClubAction(clubResponse.data));
            if (user.current_team) {
              let currentTeam = [];
              if (user.teams.includes(user.current_team)) {
                currentTeam = clubResponse.data.teams.filter(
                  (team: TeamType) => team.id === user.current_team,
                );
              } else if (user.teams.length > 0) {
                currentTeam = clubResponse.data.teams.filter(
                  (team: TeamType) => team.id === user.teams[0],
                );
              }
              if (currentTeam.length > 0) {
                dispatch(SetCurrentUserTeamAction(currentTeam[0]));
              } else {
                dispatch(AC_SetCurrentSelectedTeamEvent(null));
              }
            }
          } else {
            //@ts-ignore
            dispatch(setCurrentClubErrorAction(clubResponse.data));
          }
        }
      } else {
        dispatch(setCurrentUserErrorAction(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
type UpdateUserRequestActionType = {
  type: typeof UPDATE_USER_REQUEST;
};
const updateUserRequestAction = (): UpdateUserRequestActionType => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};
type UpdateUserRequestErrorActionType = {
  type: typeof UPDATE_USER_REQUEST_ERROR;
  error: object;
};
const updateUserRequestErrorAction = (
  error: object,
): UpdateUserRequestErrorActionType => {
  return {
    type: UPDATE_USER_REQUEST_ERROR,
    error: error,
  };
};
type UpdateUserRequestSuccessActionType = {
  type: typeof UPDATE_USER_REQUEST_SUCCESS;
  payload: object;
};
const updateUserRequestSuccessAction = (
  response: object,
): UpdateUserRequestSuccessActionType => {
  return {
    type: UPDATE_USER_REQUEST_SUCCESS,
    payload: response,
  };
};
export const UpdateUserAction =
  (
    userId: number,
    updateParams: UserUpdateType,
  ): ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AuthActionsType | AnyAction
  > =>
  async (dispatch, getState) => {
    try {
      dispatch(updateUserRequestAction);
      const response = await AuthAPI.updateUser(userId, updateParams);
      if (response.status === 200) {
        const user = response.data;
        dispatch(updateUserRequestSuccessAction(user));
        const currentUser = getState().authReducer.currentUser;
        if (currentUser?.id === user.id) {
          await dispatch(GetCurrentUserAction());
        }
      } else {
        dispatch(updateUserRequestErrorAction(response.data));
      }
    } catch (e: any) {
      dispatch(updateUserRequestErrorAction(e.data));
    }
  };
