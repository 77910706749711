import { API_ENDPOINT } from 'types/constants';
import myAxios from 'utils/axios';

const getMatchProtocol = async (matchId: string) => {
  const url = `${API_ENDPOINT}api/data/activities/${matchId}/protocol/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

export const ProtocolAPI = {
  getMatchProtocol,
};
