import React, { useEffect } from 'react';

import { ConfigProvider, Select, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';
import './styles.css';

const PositionsFilter = ({
  positions,
  positionsDurations,
  positionsLoading,
  mode = 'overview',
}: {
  positions: any[];
  positionsDurations: any[];
  positionsLoading: boolean;
  mode: string;
}) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { selectedPosition } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });
  const { selectedPosition_stats } = useSelector((state: AppStateType) => {
    return state.teamPlayerStatsSectionReducer;
  });
  const { playerId } = useParams();
  const { setSelectedPosition } = teamPlayerReducer.actions;
  const { setSelectedPosition_stats, setSelectedEventsOrComets } =
    teamPlayerStatsSectionReducer.actions;
  useEffect(() => {
    if (mode === 'overview') {
      dispatch(setSelectedPosition(null));
    } else if (mode === 'multitool') {
      dispatch(setSelectedPosition_stats(null));
      dispatch(setSelectedEventsOrComets([]));
    }
  }, [playerId]);
  return (
    <>
      {positions.length > 0 && (
        <ConfigProvider
          theme={{
            token: {
              colorText: 'var(--colorPrimaryBase, #1668DC);',
            },
            components: {
              Select: {
                optionSelectedColor:
                  'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
              },
            },
          }}
        >
          {positionsLoading ? (
            <Skeleton.Button
              active
              shape="round"
              style={{
                opacity: 0.7,
                width: '100%',
                height: 24,
                backgroundColor:
                  'var(--colorFillQuaternary, rgba(255, 255, 255, 0.1))',
              }}
            ></Skeleton.Button>
          ) : (
            <Select
              size="small"
              placeholder={
                <div className="select-placeholder">{t('All positions')}</div>
              }
              variant="borderless"
              className={'hasSelection'}
              value={
                mode === 'overview' ? selectedPosition : selectedPosition_stats
              }
              onSelect={(value: string) => {
                let updated: string | null = value;
                if (value === 'clear') {
                  updated = null;
                }
                if (mode === 'overview') {
                  dispatch(setSelectedPosition(updated));
                } else if (mode === 'multitool') {
                  dispatch(setSelectedPosition_stats(updated));
                  dispatch(setSelectedEventsOrComets([]));
                }
              }}
              popupMatchSelectWidth={false}
              placement="bottomRight"
            >
              <Select.Option
                style={{
                  mpaddingTop: 10,
                  borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                  borderRaius: 0,
                  color: 'black',
                }}
                open={true}
                className="enabledClickable"
                key={0}
                onClick={(e: any) => {
                  e.preventDefault();
                }}
                label={''}
                value={'clear'}
              >
                {t('Clear filter')}
              </Select.Option>
              {positions.map((position: any, index: number) => (
                <Select.Option
                  key={index + 1}
                  label={position.value}
                  value={position.value}
                  style={{
                    color: 'black',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      gap: 5,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div className="positions-filter-name">
                      {position.label}
                    </div>
                    <div className="positions-filter-timer">
                      (
                      {Math.round(
                        positionsDurations[position.value] / 1000 / 60,
                      )}
                      ’)
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          )}
        </ConfigProvider>
      )}
    </>
  );
};
export default PositionsFilter;
