/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import './index.css';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { GetMatchPlayerEventCoordinates } from 'actions/playerv2.actions';
import { EventsAPI } from 'api/events';
import { PlayersAPI } from 'api/player';
import MatchSkeleton from 'components/Skeletons/MatchSkeleton';
import { AppStateType } from 'reducers';
import { metricsReducer } from 'reducers/metrics.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import MatchFilters from './MatchFilters';
import MatchRow from './MatchRow';

const PlayerMatchesBlock = () => {
  const [t] = useTranslation();
  const {
    setTopPlayerRole,
    setGamesFilterPositions,
    setPositionsFilterDurations,
    setPositionsLoading,
    setSelectedMetricFilters,
  } = teamPlayerReducer.actions;

  const {
    expandedGames,
    openedTeamPlayer,
    gameEventCoordinates,
    selectedMetricFilters,
  } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  const [matches, setMatches] = useState<any>([]);
  const dispatch = useAppDispatch();
  const { playerId } = useParams();
  const getEventCoordinates = (
    matchId: string,
    metrics: string[],
    stateMetrics: string[],
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    result: string[] = ['all'],
    match?: any,
  ) => {
    dispatch(
      GetMatchPlayerEventCoordinates(
        matchId,
        metrics,
        result,
        openedTeamPlayer.id,
        match,
      ),
    );
  };
  const populateAllMetrics = (match: any) => {
    const keys: any[] = [];
    match?.stats
      ?.filter(
        (stat: any) =>
          metricsRegistry?.match.filter(
            (regStat: any) => stat.key === regStat.key,
          ).length > 0,
      )
      ?.forEach((stat: any) => {
        if (stat.value) {
          if (stat.details) {
            keys.push(stat.details.success.key);
            keys.push(stat.details.fail.key);
          } else {
            keys.push(stat.key);
          }
        }
      });
    // console.log(metricsRegistry);
    const statKeys = keys.filter((stat: any) => stat !== undefined);
    // console.log(statKeys);
    getEventCoordinates(
      match.id,
      statKeys,
      statKeys,
      ['all', 'fail', 'success'],
      match,
    );
  };
  const populateCoordinates = (match: any) => {
    if (gameEventCoordinates[match.id] === undefined) {
      populateAllMetrics(match);
    }
  };
  const { setExpandedGamesPlayer } = teamPlayerReducer.actions;
  const { showAllGameEventCoordinates } = teamPlayerReducer.actions;
  const clearAllFiltersCallback = (match: any, showAllEvents = true) => {
    dispatch(
      setSelectedMetricFilters(
        selectedMetricFilters.filter(
          (filterString: string) => !filterString.startsWith(match.id),
        ),
      ),
    );
    if (showAllEvents) {
      const hiddenMetricsByDefault = metricsRegistry.match
        .filter((m: any) => !m.default_show_event_on_field)
        .map((m: any) => m.key);
      dispatch(
        showAllGameEventCoordinates({
          matchId: match.id,
          hiddenMetrics: hiddenMetricsByDefault,
        }),
      );
    }
  };
  const toggleExpanded = (match: any) => {
    if (expandedGames.includes(match.id)) {
      dispatch(
        setExpandedGamesPlayer(expandedGames.filter((el) => el !== match.id)),
      );
      clearAllFiltersCallback(match.id, false);
    } else {
      populateCoordinates(match);
      dispatch(setExpandedGamesPlayer([...expandedGames, match.id]));
    }
  };
  useEffect(() => {
    if (playerId) {
      dispatch(setPositionsLoading(true));
      // dispatch(setpositionsLoading_stats(true));
      // console.log('player roles 1');
      PlayersAPI.getPlayerRoles(playerId)
        .then((response) => {
          EventsAPI.getMetricsRegistryV2(
            response.data.top_roles[0] === 'goalkeeper'
              ? 'goalkeeper'
              : 'player',
            null,
            playerId || '',
          ).then((response) => {
            dispatch(setMetricsRegistry(response.data));
          });
          const allPositions = [
            // {
            //   value: 'ALL',
            //   label: t('All positions'),
            //   clasName: 'select-divider',
            // },
            ...response.data.roles.map((role: string) => {
              return { value: role, label: t(role) };
            }),
          ];
          dispatch(setGamesFilterPositions(allPositions));
          // dispatch(setGamesFilterPositions_stats(allPositions));
          // dispatch(setPositions(allPositions));
          // dispatch(setPositions_stats(allPositions));
          // dispatch(setPositionsDurations(response.data.durations));
          // dispatch(setPositionsDurations_stats(response.data.durations));
          dispatch(setPositionsFilterDurations(response.data.durations));
          // dispatch(setPositionsFilterDurations_stats(response.data.durations));
          const allSchemas: any = [
            ...response.data.schemas.map((schema: any) => {
              return {
                value: schema.id,
                label: schema.name,
              };
            }),
          ];
          setPlayedSchemas(allSchemas);
          dispatch(setTopPlayerRole(response.data.top_roles));
        })
        .catch(() => {
          dispatch(setPositionsLoading(false));
          // dispatch(setpositionsLoading_stats(false));
        });
      dispatch(setPositionsLoading(false));
      // dispatch(setpositionsLoading_stats(false));
    }
  }, [playerId]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPositions, setSelectedPositions] = useState<string | null>(
    null,
  );
  const [selectedMinutes, setSelectedMinutes] = useState<any>(null);
  const [playedSchemas, setPlayedSchemas] = useState([]);
  const [selectedPlayedSchemas, setSelectedPlayedSchemas] = useState<any>(null);
  const { setMetricsRegistry } = metricsReducer.actions;
  const MATCHES_PER_PAGE = 1000;
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!playerId) {
      return;
    }
    setLoading(true);
    EventsAPI.getPlayerGamesStats(
      null,
      1,
      MATCHES_PER_PAGE,
      selectedMinutes || 'ANY',
      selectedPlayedSchemas || 'ALL',
      selectedPositions || 'ALL',
      playerId,
    ).then((matchesStatsResponse) => {
      if (matchesStatsResponse.data) {
        setMatches(matchesStatsResponse.data.results); // TODO add pagination later
        setTotal(matchesStatsResponse.data.total);
      }
      setLoading(false);
    });
  }, [selectedMinutes, selectedPlayedSchemas, selectedPositions, playerId]);

  const matchesRef = useRef<any>(null);
  return (
    <Flex vertical style={{ width: '100%' }} gap={8}>
      <Flex
        className="block-title"
        justify="flex-start"
        style={{ width: '100%' }}
        gap={16}
      >
        <div className="block-title-h" style={{ flex: 0 }}>
          {t('Matches')}
        </div>
        <MatchFilters
          show={openedTeamPlayer && openedTeamPlayer.team.sport !== 2}
          selectedPositions={selectedPositions}
          setSelectedPositions={setSelectedPositions}
          playedSchemas={playedSchemas}
          setPlayedSchemas={setPlayedSchemas}
          selectedPlayedSchemas={selectedPlayedSchemas}
          setSelectedPlayedSchemas={setSelectedPlayedSchemas}
          selectedMinutes={selectedMinutes}
          setSelectedMinutes={setSelectedMinutes}
        />
      </Flex>
      <Flex
        vertical
        gap={8}
        ref={matchesRef}
        className="block-content flex-column"
      >
        {matches.map((match: any) => (
          <MatchRow
            key={match.id}
            match={match}
            matches={matches}
            isExpanded={expandedGames.includes(match.id)}
            toggleExpanded={toggleExpanded}
            getEventCoordinates={getEventCoordinates}
            populateCoordinates={populateCoordinates}
            clearAllFiltersCallback={clearAllFiltersCallback}
          />
        ))}
        {loading && <MatchSkeleton />}
        <div ref={matchesRef} style={{ alignSelf: 'stretch', height: 1 }}></div>
      </Flex>
    </Flex>
  );
};
export default PlayerMatchesBlock;
