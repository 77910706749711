/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';

const AverageStatTeamValueTag = ({
  stat,
  statKey,
  teamId,
  teamType,
  selectedMetricFilters,
}: {
  stat: any;
  statKey: string;
  teamType: string;
  teamId: string;
  selectedMetricFilters: any;
}) => {
  const [t] = useTranslation();
  const [displayedValue, setDisplayedValue] = useState(null);
  const getMetricValue = () => {
    if (selectedMetricFilters.includes(`${statKey}_success`)) {
      if (stat?.details && stat?.details['success'][teamType]) {
        return stat?.details['success'][teamType]?.average;
      }
      return stat?.details['success'].average;
    }
    if (selectedMetricFilters.includes(`${statKey}_fail`)) {
      if (stat?.details && stat?.details['fail'][teamType]) {
        return stat?.details['fail'][teamType]?.average;
      }
      return stat?.details['fail'].average;
    }
    if (stat) {
      if (stat[teamType]) {
        return stat[teamType].average;
      }
      return stat.average;
    }
    return '--';
  };
  useEffect(() => {
    setDisplayedValue(getMetricValue());
  }, [selectedMetricFilters, stat]);
  return (
    <Flex
      className={`${stat[teamType]?.average === undefined ? 'na-stat' : stat[teamType]?.average > 0 ? 'average-stat-value-container' : 'stat-value-container-zero'}`}
      style={{ minWidth: 90, minHeight: 32, width: '100%' }}
      align="center"
    >
      <Flex
        className="stat-average"
        justify={
          stat[teamType]?.average !== undefined ? 'space-between' : 'center'
        }
        style={{ width: '100%' }}
        flex={1}
        align="center"
      >
        {stat[teamType]?.average === undefined ? (
          <span className="disabled-stat-color">{t('n/a')}</span>
        ) : stat[teamType].average > 0 ? (
          <span className="stat-average-value">{displayedValue}</span>
        ) : (
          <span className="stat-average-value">0</span>
        )}

        {stat[teamType]?.percent !== null &&
          stat[teamType]?.percent !== undefined &&
          stat[teamType].average > 0 &&
          !selectedMetricFilters.includes(`${statKey}_success`) &&
          !selectedMetricFilters.includes(`${statKey}_fail`) &&
          displayedValue !== 0 && (
            <span className={'team-stat-percent-value'}>
              {stat[teamType]?.percent}%
            </span>
          )}
      </Flex>
    </Flex>
  );
};
export default AverageStatTeamValueTag;
