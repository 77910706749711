import React, { useState } from 'react';

import { Checkbox, Col, ConfigProvider, Flex, Row } from 'antd';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';

import MatchFieldEventsField from '../MatchFieldEventsField';
import StatValueTag from '../StatValueTag';

interface MatchStatsContainerInterface {
  isExpanded: boolean;
  loadingCoordinates: boolean;
  clearAllFiltersCallback: any;
  match: any;
  makeLink: any;
}
const MatchStatsContainer: React.FC<MatchStatsContainerInterface> = ({
  isExpanded,
  loadingCoordinates,
  clearAllFiltersCallback,
  match,
  makeLink,
}) => {
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  const { selectedMetricFilters } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const {
    showAllGameEventCoordinates,
    clearGameEventCoordinates,
    leaveSingleEvent,
    showGameEventCoordinates,
    setSelectedMetricFilters,
  } = teamPlayerReducer.actions;
  const dispatch = useAppDispatch();
  const toggleSelectedMetric = async (
    match: any,
    metricString: string,
    matchId: string,
    metricObj: any,
    result: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      const filteredByGame = selectedMetricFilters.filter((m: string) =>
        m.startsWith(matchId),
      );
      if (filteredByGame.length === 1) {
        await dispatch(
          setSelectedMetricFilters(
            selectedMetricFilters.filter(
              (el: string) => !el.startsWith(matchId),
            ),
          ),
        );
        const hiddenMetricsByDefault = metricsRegistry.match
          .filter((m: any) => !m.default_on_field)
          .map((m: any) => m.key);
        await dispatch(
          showAllGameEventCoordinates({
            matchId: matchId,
            hiddenMetrics: hiddenMetricsByDefault,
          }),
        );
      } else if (filteredByGame.length > 1) {
        await dispatch(
          setSelectedMetricFilters(
            selectedMetricFilters.filter((el: any) => el !== metricString),
          ),
        );
        await dispatch(
          clearGameEventCoordinates({
            matchId: matchId,
            result: result,
            metric: metricObj.key,
          }),
        );
      }
    } else {
      const filteredByGame = selectedMetricFilters.filter((m: string) =>
        m.startsWith(matchId),
      );
      if (filteredByGame.length === 0) {
        await dispatch(
          setSelectedMetricFilters([...selectedMetricFilters, metricString]),
        );
        await dispatch(
          leaveSingleEvent({
            matchId: matchId,
            result: result,
            metric: metricObj.key,
          }),
        );
      } else {
        await dispatch(
          setSelectedMetricFilters([...selectedMetricFilters, metricString]),
        );
        await dispatch(
          showGameEventCoordinates({
            matchId: matchId,
            result: result,
            metric: metricObj.key,
          }),
        );
      }
    }
  };
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const renderMetricFilters = (
    match: any,
    metric: string,
    matchId: string,
    metricObj: any,
  ) => {
    const metricStringSuccess = `${matchId}_${metric}_success`;
    const metricStringFail = `${matchId}_${metric}_fail`;
    if (
      (selectedMetricFilters &&
        !loadingCoordinates &&
        (selectedMetricFilters.includes(metricStringSuccess) ||
          selectedMetricFilters.includes(metricStringFail))) ||
      hoveredMetricFilter.includes(`${matchId}_${metric}`)
    ) {
      return (
        <Flex gap={4} onClick={(e) => e.stopPropagation()}>
          <Flex
            className={`${metricsRegistry.match.filter((m) => m.key === metricObj.key)[0]?.reverse_match_color ? 'fail-checkbox' : 'success-checkbox'}`}
            justify="center"
            onClick={(e) => e.stopPropagation()}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: metricsRegistry.match.filter(
                    (m) => m.key === metricObj.key,
                  )[0]?.reverse_match_color
                    ? 'grey'
                    : '#1677ff',
                },
              }}
            >
              <Checkbox
                checked={selectedMetricFilters.includes(metricStringSuccess)}
                disabled={loadingCoordinates}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSelectedMetric(
                    match,
                    metricStringSuccess,
                    matchId,
                    metricObj,
                    metricObj.details ? 'success' : 'all',
                  );
                }}
              />
            </ConfigProvider>
          </Flex>
          {metricObj.details && (
            <Flex
              className={`${metricsRegistry.match.filter((m) => m.key === metricObj.key)[0]?.reverse_match_color ? 'success-checkbox' : 'fail-checkbox'}`}
              justify="center"
            >
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: metricsRegistry.match.filter(
                      (m) => m.key === metricObj.key,
                    )[0]?.reverse_match_color
                      ? '#1677ff'
                      : 'grey',
                  },
                }}
              >
                <Checkbox
                  checked={selectedMetricFilters.includes(metricStringFail)}
                  disabled={loadingCoordinates}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectedMetric(
                      match,
                      metricStringFail,
                      matchId,
                      metricObj,
                      'fail',
                    );
                  }}
                />
              </ConfigProvider>
            </Flex>
          )}
        </Flex>
      );
    } else {
      return <Flex children={undefined}></Flex>;
    }
  };
  return (
    <div
      style={{
        overflow: 'hidden',
      }}
      className={`player ${isExpanded ? 'expanded-match' : 'collapsed-match'}`}
      onClick={(e) => e.stopPropagation()}
    >
      <Flex
        gap={12}
        style={{
          backgroundColor: 'var(--colorBgContainer)',
          padding: 12,
          borderRadius: 8,
          width: '100%',
        }}
        // className="match-skills-container"
        align="center"
        justify="space-between"
        onClick={(e) => e.stopPropagation()}
      >
        <Row gutter={[12, 12]} className="w100">
          {[...metricsRegistry.match]
            .sort((a, b) => a.ordering - b.ordering)
            .map((metric: any) => {
              const s = match.stats?.filter(
                (stat: any) => stat.key === metric.key,
              )[0];
              if (s) {
                return (
                  <Col
                    span={6}
                    onMouseEnter={(e: any) => {
                      e.preventDefault();
                      setHoveredMetricFilters((prev) => [
                        ...prev,
                        `${match.id}_${s.name}`,
                      ]);
                    }}
                    onMouseLeave={() => {
                      setHoveredMetricFilters((prev) =>
                        prev.filter(
                          (el: string) => el !== `${match.id}_${s.name}`,
                        ),
                      );
                    }}
                  >
                    <Flex
                      className="stat-header"
                      justify="space-between"
                      style={{
                        marginBottom: 2,
                      }}
                    >
                      <span className=" elipsis-text">{metric.name}</span>
                      {s.value ? (
                        renderMetricFilters(match, s.name, match.id, s)
                      ) : (
                        <></>
                      )}
                    </Flex>
                    <StatValueTag
                      stat={s}
                      match={match}
                      isExpanded={isExpanded}
                      makeLink={makeLink}
                      selectedMetricFilters={selectedMetricFilters}
                    />
                  </Col>
                );
              }
            })}
        </Row>
        <MatchFieldEventsField
          gameId={match.id}
          showClearFilterButton={
            selectedMetricFilters.filter((filterString: any) =>
              filterString.startsWith(match.id),
            ).length > 0
          }
          updateDots={isExpanded}
          clearAllFiltersCallback={() => clearAllFiltersCallback(match)}
        />
      </Flex>
    </div>
  );
};
export default MatchStatsContainer;
