import React, { useEffect, useState } from 'react';

import { ConfigProvider, Flex, Select, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// import { GetTournamentTable } from 'actions/team.actions';
import { ClubsAPI } from 'api/clubs';
import { RegistriesAPI } from 'api/registries';
import { ReactComponent as CloseCrossIcon } from 'assets/img/icons/faCloseCross.svg';
import LogoGrey from 'assets/img/LogoSotaGrey.svg';
import './index.css';
import { AppStateType } from 'reducers';
// import { teamReducer } from 'reducers/team.reducer';
// import { useAppDispatch } from 'store';

const TournamentTableBlock = ({ onClose }: { onClose: any }) => {
  const [t] = useTranslation();
  const { teamId } = useParams();
  const {
    teamSeasonsList,
    teamTournamentSeasonTable,
    teamTournamentSelectedSeason,
  } = useSelector((state: AppStateType) => {
    return {
      teamSeasonsList: state.teamReducer.teamSeasonsList,
      teamTournamentSeasonTable: state.teamReducer.teamTournamentSeasonTable,
      teamTournamentSelectedSeason:
        state.teamReducer.teamTournamentSelectedSeason,
    };
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fullSeasons, setFullSeasons] = useState<any[]>([]);
  // const { setTeamTournamentSelectedSeason } = teamReducer.actions;
  const initSeasons = () => {
    return teamSeasonsList.map((season: any) => {
      const startYear = dayjs(season.start_date).format('YYYY');
      const endYear = dayjs(season.end_date).year().toString();
      let label: string;
      if (startYear === endYear) {
        label = startYear;
      } else {
        label = `${startYear}/${endYear}`;
      }
      return {
        label: label,
        value: `${season.tournament.id}-${season.id}`,
      };
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [seasons, setSeasons] = useState<any>(initSeasons());
  const [selectedSeason, setSelectedSeason] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stats, setStats] = useState([]);
  const [tournamentIsLoading, setTournamentIsLoading] = useState(false);
  const PERFORMANCE_ICONS: any = {
    win: <div className="performance-icon win">w</div>,
    draw: <div className="performance-icon draw">d</div>,
    loss: <div className="performance-icon loss">l</div>,
    unknown: <div className="performance-icon unknown">?</div>,
  };
  useEffect(() => {
    setSeasons(initSeasons());
  }, [teamSeasonsList]);
  // const dispatch = useAppDispatch();
  useEffect(() => {
    const getLinup = async () => {
      if (teamId) {
        setSeasons([]);
        const seasonsResponse = await ClubsAPI.getTeamSeasons(teamId, true);
        const seasonsList = seasonsResponse.data;
        if (seasonsList !== undefined) {
          setTournamentIsLoading(true);
          setFullSeasons(seasonsList);
          const s = seasonsList.map((season: any) => {
            const startYear = dayjs(season.start_date).format('YYYY');
            const endYear = dayjs(season.end_date).year().toString();
            let label: string;
            if (startYear === endYear) {
              label = `${season.tournament.name} ${startYear}`;
            } else {
              label = `${season.tournament.name} ${startYear}/${endYear}`;
            }
            return season.tournament_tables.map((table: any) => {
              return {
                label: `${label} ${table.part_id ? table.part_name : ''}`,
                value: `${season.tournament.id}-${season.id}-${table.part_id}`,
              };
            });
          });
          if (s[0].length) {
            setSelectedSeason(s[0][0]);
            setSeasons(s[0]);
          }
          if (seasonsResponse?.data?.length) {
            const teamResponse = await RegistriesAPI.getScoreTable(
              seasonsList[0].tournament.id,
              seasonsList[0].id,
              seasonsList[0]?.tournament_tables[0]?.part_id,
            );
            if (teamResponse.data !== undefined) {
              setStats(teamResponse.data);
            } else {
              setStats([]);
            }
          } else {
            setStats([]);
          }
          setTournamentIsLoading(false);
        }
      }
    };
    getLinup().then();
  }, [teamId]);
  useEffect(() => {
    const getTournamentTable = async () => {
      if (selectedSeason && selectedSeason.value) {
        setTournamentIsLoading(true);
        const teamResponse = await RegistriesAPI.getScoreTable(
          selectedSeason?.value.split('-')[0],
          selectedSeason?.value.split('-')[1],
          selectedSeason?.value.split('-')[2] || '',
        );
        if (teamResponse.data !== undefined) {
          setStats(teamResponse.data);
        }
        setTournamentIsLoading(false);
      }
    };
    getTournamentTable().then();
  }, [selectedSeason]);
  // const navigate = useNavigate();
  const getTournamentImage = () => {
    return teamSeasonsList.filter(
      (fullSeason: any) =>
        fullSeason.id === Number(selectedSeason?.value?.split('-')[1]),
    )[0]?.tournament?.image;
  };
  const getTournamentName = () => {
    return teamSeasonsList.filter(
      (fullSeason: any) =>
        fullSeason.id === Number(teamTournamentSelectedSeason?.split('-')[1]),
    )[0]?.tournament?.name;
  };
  return (
    <Flex
      vertical
      style={{
        width: '100%',
      }}
      gap={8}
    >
      <div className="tournament-header">
        <img src={getTournamentImage() || LogoGrey} alt="Tournament logo" />
        <div className="tournament-name elipsis-text">
          {getTournamentName()}
        </div>
        {seasons.length > 1 ? (
          <Select
            // style={{minWidth: 130}}
            variant="borderless"
            value={selectedSeason?.value}
            onChange={(value: any) => {
              setSelectedSeason(
                seasons.filter((s: any) => s.value === value)[0],
              );
            }}
            popupMatchSelectWidth={false}
          >
            {seasons.map((season: any, index: number) => (
              <Select.Option
                key={index}
                label={season.value}
                value={season.value}
              >
                {season.label}
              </Select.Option>
            ))}
          </Select>
        ) : (
          seasons[0]?.label
        )}
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <CloseCrossIcon className="enabledClickable" onClick={onClose} />
        </div>
      </div>
      <>
        <div className="tournament-table-header">
          <div style={{ width: 24 }}>{t('#')}</div>
          <div className="team-word">{t('Team')}</div>
          <Flex
            gap={12}
            className="header-column-names"
            align="center"
            flex={1}
          >
            <div>{t('M-header')}</div>
            <div>{t('W-header')}</div>
            <div>{t('D-header')}</div>
            <div>{t('L-header')}</div>
            <div style={{ width: 38, textAlign: 'center' }}>
              {t('G-header')}
            </div>
            <div>{t('GD-header')}</div>
            <div>{t('P-header')}</div>
            <div style={{ minWidth: 164, textAlign: 'end' }}>
              {t('Form-header')}
            </div>
          </Flex>
        </div>
        {!tournamentIsLoading ? (
          <Flex
            gap={12}
            vertical
            style={{
              overflowY: 'auto',
              height: 'calc(100vh - 120px)',
            }}
          >
            {teamTournamentSeasonTable.map((team: any, index: number) => (
              <div
                onClick={() => {
                  // navigate(`/teams/${team.id}`);
                }}
                key={index}
                className={`tournament-team-wrapper ${team.id.toString() === teamId?.toString() ? 'active' : ''}`}
              >
                <div className="tournament-team-position">{index + 1}</div>
                <div className="tournament-team-logo">
                  <img src={team.logo || LogoGrey} alt={'Team Logo'} />
                </div>
                <div className="tournament-team-name">{team.name}</div>
                <div className="tournament-team-stats">
                  <div style={{ flex: 1, width: 20 }}>{team.matches}</div>
                  <div style={{ flex: 1, width: 20 }}>{team.wins}</div>
                  <div style={{ flex: 1, width: 20 }}>{team.draws}</div>
                  <div style={{ flex: 1, width: 20 }}>{team.losses}</div>
                  <div style={{ width: 38 }}>{team.goals}</div>
                  <div style={{ flex: 1, width: 20 }}>{team.rg}</div>
                  <div style={{ flex: 1, width: 20 }}>{team.points}</div>
                </div>
                <Flex gap={4}>
                  {[0, 1, 2, 3, 4, 5].map((el: number) =>
                    team.performance[el]
                      ? PERFORMANCE_ICONS[team.performance[el]]
                      : PERFORMANCE_ICONS['unknown'],
                  )}
                </Flex>
              </div>
            ))}
          </Flex>
        ) : (
          <ConfigProvider
            theme={{
              token: {
                borderRadiusSM: 8,
              },
            }}
          >
            <Flex vertical gap={8}>
              <Skeleton.Button
                active
                style={{
                  width: '100%',
                  height: 59,
                }}
              />
              <Skeleton.Button
                active
                style={{
                  width: '100%',
                  height: 59,
                  opacity: 0.8,
                }}
              />
              <Skeleton.Button
                active
                style={{
                  width: '100%',
                  height: 59,
                  opacity: 0.6,
                }}
              />
              <Skeleton.Button
                active
                style={{
                  width: '100%',
                  height: 59,
                  opacity: 0.4,
                }}
              />
              <Skeleton.Button
                active
                style={{
                  width: '100%',
                  height: 59,
                  opacity: 0.2,
                }}
              />
            </Flex>
          </ConfigProvider>
        )}
      </>
    </Flex>
  );
};
export default TournamentTableBlock;
