import React, { useEffect, useState } from 'react';

import { Flex, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  GetMultitoolEvents,
  GetMultitoolEventsAndOpenVideoPlayer,
  ToggleMultipleCells,
} from 'actions/playerMultitool.actions';
import { makeLink } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import MetricFilter from 'components/PlayerPageController/PlayerStatisticsBlock/MetricFilter';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import MultitoolAverageStatValueTag from './MultitoolAverageStatValueTag';
import MultitoolConsolidatedStatValueTag from './MultitoolConsolidatedStatValueTag copy';
import { ReactComponent as DownOutlinedBlack } from '../../../../assets/img/icons/faDownOutlinedBlack.svg';

import './index.css';

const StatisticsDetailsMultitoolBlock = ({
  setExpandedQty,
  metricsControllerRef,
  rightMargin = 16,
}: {
  setExpandedQty: any;
  metricsControllerRef: any;
  rightMargin?: number;
}) => {
  const { playerId } = useParams();
  const {
    statsState_stats,
    teamPlayerStats_stats,
    timeOnField_stats,
    selectedRowKeys_stats,
    timeOnFieldByPosition_stats,
    loadingPercentiles_stats,
    selectedPosition_stats,
    selectedStat,
    selectedFromFieldCells,
    selectedToFieldCells,
    selectedEventsOrComets,
    multitoolEvents,
    fromFieldEventsDetails,
    toFieldEventsDetails,
    selectedMetricFilters,
  } = useSelector((state: AppStateType) => {
    return state.teamPlayerStatsSectionReducer;
  });
  const { openedTeamPlayer } = useSelector((state: AppStateType) => {
    return state.teamPlayerReducer;
  });
  const {
    setSelectedStat,
    setSelectedFromFieldsCells,
    setSelectedToFieldsCells,
    setDisplayedComets,
    setMatchedPointEvents,
    setFromFieldEventsDetails,
    setToFieldEventsDetails,
    setMultitoolEvents,
    setSelectedEventsOrComets,
    setMultitoolEventsLoading,
    setSelectedMetricFilters,
  } = teamPlayerStatsSectionReducer.actions;
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const [showPth, setShowPth] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { metricsRegistry } = useSelector((state: AppStateType) => {
    return state.metricsReducer;
  });
  useEffect(() => {
    let count = 0;
    teamPlayerStats_stats.forEach((stat) => {
      if (expandedStats.includes(stat.key)) {
        count += stat.children.length;
      }
    });
    console.log(count);
    setExpandedQty(count);
  }, [expandedStats]);
  useEffect(() => {
    setShowPth(openedTeamPlayer && openedTeamPlayer.team.sport !== 2);
  }, [openedTeamPlayer, selectedFromFieldCells, selectedToFieldCells]);
  const showAveragesTag = openedTeamPlayer && openedTeamPlayer.team.sport !== 2;
  const toggleSelectedStat = async (stat: any) => {
    if (stat.disabled || stat.key === 'turnover') {
      return;
    }
    if (selectedStat?.key !== stat.key) {
      await dispatch(
        setSelectedStat({
          key: stat.key,
          as_point: stat.as_point,
          reverse_match_color: stat.reverse_match_color,
        }),
      );
      await dispatch(setSelectedToFieldsCells([]));
      await dispatch(setSelectedFromFieldsCells([]));
      await dispatch(setSelectedEventsOrComets([]));
      await dispatch(setSelectedMetricFilters([]));
      if (stat?.percent !== null && stat?.percent !== undefined) {
        setHoveredMetricFilters(() => [stat.key]);
      }
    }
  };
  const dispatch = useAppDispatch();
  const makeLinkAndNavigateV2 = async (stat: any) => {
    const link = makeLink(
      [{ id: null }],
      null,
      [],
      undefined,
      'manual',
      playerId,
      null,
    );
    if (selectedStat.key !== stat.key) {
      toggleSelectedStat(stat);
      const eventIds = await dispatch(
        GetMultitoolEventsAndOpenVideoPlayer(
          stat.key,
          playerId || '',
          selectedRowKeys_stats || [],
          selectedPosition_stats || '',
          [],
        ),
      );
      localStorage.setItem(`player_events_${link}`, JSON.stringify(eventIds));
      window.open(link, '_blank');
      return;
    }
    let eventIds: string[] = [];
    if (
      selectedStat?.as_point &&
      selectedFromFieldCells.length === 0 &&
      selectedToFieldCells.length === 0 &&
      selectedEventsOrComets.length === 0
    ) {
      eventIds = [...multitoolEvents.map((evt) => evt.id)];
    } else if (
      selectedFromFieldCells.length > 0 &&
      selectedToFieldCells.length > 0
    ) {
      eventIds = multitoolEvents
        .filter(
          (evt: any) =>
            selectedFromFieldCells
              .map((cellId: number) => fromFieldEventsDetails[cellId])
              .filter(
                (cell: any) =>
                  cell && cell.events && cell.events.includes(evt.id),
              ).length,
        )
        .filter(
          (evt: any) =>
            selectedToFieldCells
              .map((cellId: number) => toFieldEventsDetails[cellId])
              .filter(
                (cell: any) =>
                  cell && cell.events && cell.events.includes(evt.id),
              ).length,
        )
        .map((el: any) => el.id);
    } else if (selectedFromFieldCells.length || selectedToFieldCells.length) {
      if (selectedFromFieldCells) {
        eventIds = multitoolEvents
          .filter(
            (evt: any) =>
              selectedFromFieldCells
                .map((cellId: number) => fromFieldEventsDetails[cellId])
                .filter(
                  (cell: any) =>
                    cell && cell.events && cell.events.includes(evt.id),
                ).length,
          )
          .map((el: any) => el.id);
      }
      if (selectedToFieldCells) {
        eventIds = [
          ...eventIds,
          ...multitoolEvents
            .filter(
              (evt: any) =>
                selectedToFieldCells
                  .map((cellId: number) => toFieldEventsDetails[cellId])
                  .filter(
                    (cell: any) =>
                      cell && cell.events && cell.events.includes(evt.id),
                  ).length,
            )
            .filter(
              (el) =>
                eventIds.filter((elInner) => elInner === el.id).length === 0,
            )
            .map((el: any) => el.id),
        ];
      }
    } else {
      eventIds = [...multitoolEvents.map((evt) => evt.id)];
    }
    if (selectedEventsOrComets.length) {
      eventIds = eventIds.filter((evtId) =>
        selectedEventsOrComets.includes(evtId),
      );
    }
    localStorage.setItem(`player_events_${link}`, JSON.stringify(eventIds));
    window.open(link, '_blank');
  };
  const toggleSelectedMetric = (
    metricString: string,
    removedMetricString: string,
    metric: string,
    asPoint: boolean,
    reverse_match_color: false,
  ) => {
    dispatch(setMultitoolEventsLoading(true));
    dispatch(setSelectedEventsOrComets([]));
    if (!selectedStat || (selectedStat && selectedStat.key !== metric)) {
      dispatch(
        setSelectedStat({
          key: metric,
          as_point: asPoint,
          reverse_match_color: reverse_match_color,
        }),
      );
    }
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        dispatch(setSelectedMetricFilters([]));
      } else if (selectedMetricFilters.length > 1) {
        dispatch(
          setSelectedMetricFilters(
            selectedMetricFilters.filter((el: any) => el !== metricString),
          ),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        dispatch(setSelectedMetricFilters([metricString]));
      } else {
        dispatch(setSelectedMetricFilters([metricString]));
        // dispatch(
        //   setSelectedMetricFilters([
        //     ...selectedMetricFilters.filter(
        //       (p: any) => p !== removedMetricString,
        //     ),
        //     metricString,
        //   ]),
        // );
      }
    }
  };
  const onMouseEnter = (e: any, stat: any, hasSuccess: boolean) => {
    e.preventDefault();
    if (stat?.percent !== null && stat?.percent !== undefined && hasSuccess) {
      setHoveredMetricFilters((prev) => [...prev, stat.key]);
    }
  };
  useEffect(() => {
    const x = async () => {
      const controller: AbortController = new AbortController();
      if (selectedStat && playerId) {
        if (metricsControllerRef?.current) {
          metricsControllerRef.current.abort();
        }
        metricsControllerRef.current = controller;
        dispatch(setDisplayedComets([]));
        await dispatch(setMatchedPointEvents([]));
        await dispatch(setFromFieldEventsDetails([]));
        await dispatch(setToFieldEventsDetails([]));
        await dispatch(setMultitoolEvents([]));
        await dispatch(
          GetMultitoolEvents(
            selectedStat.key,
            playerId,
            selectedRowKeys_stats || [],
            selectedPosition_stats,
            selectedMetricFilters,
            metricsControllerRef.current.signal,
          ),
        );
        dispatch(ToggleMultipleCells());
        return () => {
          metricsControllerRef.current.abort();
        };
      }
    };
    x().then();
  }, [selectedStat, selectedPosition_stats, selectedMetricFilters]);
  return (
    <Flex
      vertical
      className="mulitool-player-stats-container"
      style={{
        margin: `0 ${rightMargin}px 0px 8px`,
        alignSelf: 'stretch',
        // @ts-ignore
        overflowY: 'overlay',
        overflowX: 'hidden',
        // minHeight: `calc(${metricsRegistry.stats.length * 42}px)`,
      }}
    >
      {teamPlayerStats_stats?.map((stat: any, index: number) => {
        return (
          <div key={index}>
            <Flex
              key={index}
              justify="flex-start"
              style={{ width: 'auto', margin: '2px 0px 2px 8px' }}
            >
              <Flex
                justify="space-between"
                style={{ width: '100%' }}
                align="center"
                gap={8}
                className={`w100 player-profile-metric-row parent ${selectedStat?.key === stat.key && 'metric-selected'}`}
                onMouseEnter={(e: any) => {
                  onMouseEnter(e, stat.stat, stat.success);
                }}
                onMouseLeave={() => {
                  setHoveredMetricFilters((prev) =>
                    prev.filter((el: string) => el !== stat.key),
                  );
                }}
                onClick={() => toggleSelectedStat(stat).then()}
              >
                <Flex
                  style={{
                    marginLeft: 0,
                    overflow: 'hidden',
                    textWrap: 'nowrap',
                  }}
                  align="center"
                  justify="flex-start"
                  gap={8}
                  flex={1}
                  className={`${stat.children?.length ? 'enabledClickable' : ''}`}
                >
                  <div className="elipsis-text oy-h">{stat.label}</div>
                  {stat.children?.length ? (
                    <DownOutlinedBlack
                      style={{
                        zIndex: 50,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          stat.children?.length &&
                          expandedStats.includes(stat.key)
                        ) {
                          setExpandedStats((prev) =>
                            prev.filter((item: string) => item !== stat.key),
                          );
                        } else {
                          setExpandedStats((prev) => [...prev, stat.key]);
                        }
                      }}
                      // onMouseEnter={(e) => e.stopPropagation()}
                      className={`collapse-button-stats enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                    />
                  ) : (
                    <></>
                  )}
                </Flex>
                <Flex>
                  <MetricFilter
                    metric={stat.key}
                    asPoint={stat.as_point}
                    selectedMetricFilters={selectedMetricFilters}
                    hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                      stat.key,
                    )}
                    toggleSelectedMetric={toggleSelectedMetric}
                    reverseMatchColor={stat.reverse_match_color}
                  />
                </Flex>
                <Flex gap={8} style={{ zIndex: 10 }}>
                  {statsState_stats === 'loading' ||
                  statsState_stats === 'init' ? (
                    <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                      <Skeleton.Button
                        active
                        style={{ minWidth: 130, minHeight: 38 }}
                      />
                    </div>
                  ) : (
                    <MultitoolConsolidatedStatValueTag
                      stat={stat.stat || stat}
                      overBaseMetric={stat?.over_base_metric}
                      showPth={showPth}
                      selectedMetricFilters={selectedMetricFilters}
                      makeLinkAndNavigate={() => {
                        makeLinkAndNavigateV2(stat);
                      }}
                    />
                  )}
                  {openedTeamPlayer &&
                    openedTeamPlayer.team.sport !== 2 &&
                    (statsState_stats === 'loading' ||
                    statsState_stats === 'init' ? (
                      <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                        <Skeleton.Button
                          active
                          style={{
                            maxWidth: 56,
                            minWidth: 56,
                            minHeight: 38,
                          }}
                        />
                      </div>
                    ) : (
                      <MultitoolAverageStatValueTag
                        stat={stat.stat}
                        loadingPercentiles={loadingPercentiles_stats}
                        selectedMetricFilters={selectedMetricFilters}
                        selectedPositions={selectedPosition_stats}
                        timeOnField={timeOnField_stats}
                        timeOnFieldByPosition={
                          selectedPosition_stats &&
                          timeOnFieldByPosition_stats[selectedPosition_stats]
                        }
                        hideValue={
                          selectedFromFieldCells.length > 0 ||
                          selectedToFieldCells.length > 0
                        }
                      />
                    ))}
                </Flex>
              </Flex>
            </Flex>
            {stat.children?.length > 0 && (
              <Flex
                key={`${index}-x`}
                vertical
                style={{ width: '100%', marginBottom: 2 }}
                gap={4}
                className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
              >
                {stat.children.map((child: any, index: number) => {
                  return (
                    <Flex
                      key={index}
                      justify="space-between"
                      style={{ width: '100%' }}
                      gap={8}
                      className="child-wrapper"
                    >
                      <Flex
                        style={{
                          marginLeft: 20,
                          overflow: 'hidden',
                        }}
                        align="center"
                        justify="space-between"
                        gap={8}
                        flex={1}
                        className={`w100 player-profile-metric-row child ${selectedStat?.key === child.key && 'metric-selected'}`}
                        onMouseEnter={(e: any) => {
                          onMouseEnter(
                            e,
                            child.stat.stat || child.stat,
                            child.stat?.success || child.success,
                          );
                        }}
                        onMouseLeave={() => {
                          const s = child.stat?.stat || child.stat;
                          setHoveredMetricFilters((prev) =>
                            prev.filter((el: string) => el !== s.key),
                          );
                        }}
                        onClick={() => toggleSelectedStat(child)}
                      >
                        <Flex className="elipsis-text">{child.label}</Flex>
                        <MetricFilter
                          metric={child.key}
                          asPoint={child.as_point}
                          selectedMetricFilters={selectedMetricFilters}
                          hoveredMetricFilterIncludes={hoveredMetricFilter.includes(
                            child.key,
                          )}
                          toggleSelectedMetric={toggleSelectedMetric}
                          reverseMatchColor={child.reverse_match_color}
                        />
                      </Flex>
                      <Flex gap={8} style={{ zIndex: 10 }}>
                        {statsState_stats === 'loading' ||
                        statsState_stats === 'init' ? (
                          <>
                            <div
                              style={{
                                borderRadius: 8,
                                overflow: 'hidden',
                              }}
                            >
                              <Skeleton.Button
                                active
                                style={{ minWidth: 130, minHeight: 38 }}
                              />
                            </div>
                            <div
                              style={{
                                borderRadius: 8,
                                overflow: 'hidden',
                              }}
                            >
                              <Skeleton.Button
                                active
                                style={{
                                  maxWidth: 56,
                                  minWidth: 56,
                                  minHeight: 38,
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <MultitoolConsolidatedStatValueTag
                              overBaseMetric={stat?.over_base_metric}
                              stat={child?.stat?.stat || child?.stat}
                              showPth={showPth}
                              selectedMetricFilters={selectedMetricFilters}
                              makeLinkAndNavigate={() => {
                                makeLinkAndNavigateV2(
                                  child?.stat?.stat || child?.stat,
                                );
                              }}
                            />
                            {/* <ConsolidatedStatValueTag
                              showPth={showPth}
                              stat={child?.stat?.stat || child?.stat}
                              loadingPercentiles={loadingPercentiles_stats}
                              selectedMetricFilters={selectedMetricFilters}
                              makeLink={makeLinkAndNavigateV2}
                              selectedPositions={selectedPosition_stats}
                            /> */}
                            {showAveragesTag && (
                              <MultitoolAverageStatValueTag
                                overBaseMetric={stat?.over_base_metric}
                                stat={child?.stat?.stat || child?.stat}
                                loadingPercentiles={loadingPercentiles_stats}
                                selectedMetricFilters={selectedMetricFilters}
                                selectedPositions={selectedPosition_stats}
                                timeOnField={timeOnField_stats}
                                timeOnFieldByPosition={
                                  selectedPosition_stats &&
                                  timeOnFieldByPosition_stats[
                                    selectedPosition_stats
                                  ]
                                }
                                hideValue={
                                  selectedFromFieldCells.length > 0 ||
                                  selectedToFieldCells.length > 0
                                }
                              />
                            )}
                          </>
                        )}
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            )}
          </div>
        );
      })}
    </Flex>
  );
};
export default StatisticsDetailsMultitoolBlock;
