import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { ConfigProvider, Flex, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/types/base';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import {
  AC_AddSelectedTournamentFilterAction,
  AC_OpenFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_SetLastXFilterAction,
  AC_setIntermediateActivitiesAction,
  setActiveTeamFilterAction,
  setSelectedAttributes,
} from 'actions/filter.actions';
import {
  AC_AddEventsToExpandedVideo,
  AC_GetVideoEpisodesSuccess,
  AC_SetIsPlayingVideoAction,
  AC_SetPlayerCommandAction,
  AC_SetPlayerHotkeysAllowedAction,
  AC_SetPlayerLoadingAction,
  // AC_SetPlayerPausedAtAction,
  AC_SetSelectedGames,
  AC_SetVisibleRangeAction,
  AC_ToggleIsCollapsed,
  AC_setPlayerMode,
} from 'actions/player.acitons';
import { EventsAPI } from 'api/events';
import './index.css';
import GameProtocolSidePanel from 'components/GameProtocolSidePanel';
import { usePlaylistHandler } from 'components/VideoPlayerController';
import ReelsEditPlayerController from 'components/VideoPlayerController/ReelsEditPlayerController';
import VideoPlayerButtonsController from 'components/VideoPlayerController/VideoPlayerButtonsController';
import { AppStateType } from 'reducers';
import { hotkeysReducer } from 'reducers/hotkeys.reducer';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';
import { WINDOW_MODE_WIDTH, WINDOW_MODE_WIDTH_MS } from 'types/constants';
import { EpisodeTypes, PlayCommandType } from 'types/types';
import { detectOS } from 'utils/functions';
import { initTimer } from 'utils/functions';

import CreateEpisodePlayerController from './CreateEpisodePlayerController';
import EditEpisodePlayerController from './EditEpisodePlayerController';
import EpisodeFloatingLabel from './EpisodeFloatingLabel';
import PlayerWrapper from './PlayerWrapper';
import VideoPlayerTimeline from './VideoPlayerTimeline';
import { MODES } from '../VideoListController';

export const VideoPlayerController: FC<any> = ({
  toggleFullScreen,
  isNarrowScreenMode,
}) => {
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const { statsCustomGameList } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const { statsCustomGameListPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { intermediateAcitivityIds } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  const refPlayer = useRef<ReactPlayer>(null);

  const {
    activeVideo,
    isPlaying,
    playCommand,
    playConsecutive,
    playerMode,
    filteredVideos,
    isLoading,
    videoTimeZoom,
    playerHotkeysAllowed,
    visibleRange,
  } = useSelector((state: AppStateType) => {
    return state.playerReducer;
  });
  const {
    setEditedEpisodeRange,
    setEditedEpisodeFileRange,
    setVideoListEditMode,
    resetEditMode,
    setVideosListIsLoading,
  } = playerReducerV2.actions;
  const {
    editedEpisodeRange,
    editedFileEpisodeRange,
    playedEpisode,
    createEpisodeMode,
    playedVideoSet,
  } = useSelector((state: AppStateType) => {
    return state.playerReducerV2;
  });
  const playerReducer = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { sharedPlaylist, openedPlaylist } = useSelector(
    (state: AppStateType) => {
      return state.playlistReducerV2;
    },
  );
  const { setAddNewEpisodeToPlaylistPopoverIsOpen } = playlistReducerV2.actions;
  const { setAddToPlaylistPopoverIsOpen } = playlistReducerV2.actions;
  const { setCreateEpisodeMode } = playerReducerV2.actions;
  const playerReducerRef = useRef(playerReducer);
  const hotkeysSettingsRef = useRef(playerHotkeysAllowed);
  const [playerState, setPlayerState] = useState<OnProgressProps | undefined>(
    undefined,
  ); // Состояние проигрывателя
  const handleProgress = (state: OnProgressProps) => {
    setPlayerState(state);
  };
  const { reelsModeOn } = useSelector((state: AppStateType) => {
    return state.reelsReducer;
  });
  const reelsModeOnRef = useRef(reelsModeOn);
  const { setToggleFrameState } = reelsReducer.actions;

  const handler = usePlaylistHandler({
    videoElements: filteredVideos,
    sharedPlaylist: sharedPlaylist?.playlist_events,
    currentPlayedVideo: activeVideo,
    isPlaying: isPlaying && !isLoading,
    currentEpisode: playedEpisode || activeVideo,
    currentPlayerState: playerState,
    playerRef: refPlayer,
    currentUser: currentUser,
    playConsecutive: playConsecutive,
    playlists: { playlists: openedPlaylist?.playlist_events },
    playedVideoSet: playedVideoSet,
    playerMode: playerMode,
    editedEpisodeRange: editedEpisodeRange,
    visibleRange: visibleRange,
  });
  const { setStatsCustomGameList } = teamReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!activeVideo && searchParams.size > 0 && filteredVideos.length === 0) {
      // navigate('/player')
      if (playerMode !== MODES.episodes) {
        dispatch(AC_setPlayerMode(MODES.episodes));
      }
      const fullPath = `${window.location.pathname}${window.location.search}`;
      const rawStorageGames = localStorage.getItem(
        `player_matches_${decodeURI(fullPath)}`,
      );
      const rawStorageTeamGames = localStorage.getItem(
        `team_matches_${decodeURI(fullPath)}`,
      );
      const rawStorageEvents = localStorage.getItem(
        `player_events_${decodeURI(fullPath)}`,
      );
      const rawStorageTeamEvents = localStorage.getItem(
        `team_events_${decodeURI(fullPath)}`,
      );
      let storageActivitiesIds = [];
      let storageEventsIds = [];
      if (rawStorageGames) {
        storageActivitiesIds = JSON.parse(rawStorageGames);
        localStorage.removeItem(`player_matches_${fullPath}`);
      }
      if (rawStorageTeamGames) {
        storageActivitiesIds = [
          ...storageActivitiesIds,
          ...JSON.parse(rawStorageTeamGames),
        ];
        localStorage.removeItem(`team_matches_${fullPath}`);
      }
      if (rawStorageEvents) {
        storageEventsIds = JSON.parse(rawStorageEvents);
        // localStorage.removeItem(`player_events_${decodeURI(fullPath)}`);
      }
      if (rawStorageTeamEvents) {
        storageEventsIds = [
          ...storageEventsIds,
          JSON.parse(rawStorageTeamEvents),
        ];
        // localStorage.removeItem(`player_events_${decodeURI(fullPath)}`);
      }
      const teamId = searchParams.get('teamId')?.toString();
      const exTid = searchParams.get('exTid')?.toString();
      let authorTeamId: string | undefined = '';
      if (searchParams.get('tid')?.toString()) {
        authorTeamId = searchParams.get('tid')?.toString();
      }
      const eventIds = searchParams.getAll('eid');
      const events = searchParams.getAll('eventId');
      const metric = searchParams.getAll('m');
      const metricId = searchParams.getAll('mid');
      const mSearchType = searchParams.get('mst');
      const opposite = searchParams.getAll('o');
      const lastX = searchParams.getAll('lX');
      const seasons = searchParams.getAll('s');
      const phase = searchParams.getAll('ph');
      const role = searchParams.getAll('role');
      let activities = [
        ...new Set([
          ...intermediateAcitivityIds,
          ...events,
          ...eventIds,
          ...storageActivitiesIds,
        ]),
      ];
      if (!events.length && statsCustomGameListPlayer.length) {
        activities = [...activities, ...statsCustomGameListPlayer];
      }
      if (!events.length && statsCustomGameList.length) {
        activities = [...activities, ...statsCustomGameList];
      }

      // console.log('activities');
      // console.log(activities);
      // console.log(intermediateAcitivityIds);
      // console.log(events);
      // console.log(statsCustomGameList);
      // console.log(eventIds);
      const season = searchParams.get('season');
      const playersIds = searchParams.getAll('pl');
      const markupIds = searchParams.getAll('markupId');
      const customAttributes = searchParams.getAll('p');
      const tournaments = searchParams.getAll('t');

      // console.log('teamId', teamId);
      // console.log('metric', metric);
      // console.log('metricId', metricId);
      // console.log('lastX', lastX);
      // console.log('opposite', opposite);
      // console.log('seasons', seasons);
      // console.log('activities', activities);
      // console.log('part', part);
      // console.log('tournaments', tournaments);
      let newFiltersV2: any = {
        type: 'game',
        games: {},
      };
      if (markupIds.length) {
        newFiltersV2.markup_ids = markupIds;
      }
      if (storageEventsIds?.length) {
        newFiltersV2.event_ids = storageEventsIds;
      }
      if (teamId && activities.length > 0) {
        newFiltersV2.games[teamId] = {
          match: activities || [],
        };
      }
      if (eventIds.length && authorTeamId) {
        newFiltersV2.games[authorTeamId] = {
          match: activities || [],
        };
      }
      if (customAttributes && teamId) {
        newFiltersV2 = {
          ...newFiltersV2,
          custom_attributes: customAttributes,
        };
      }
      if (teamId && phase.length > 0) {
        if (customAttributes) {
          newFiltersV2.games[teamId] = {
            ...newFiltersV2.games[teamId],
            phases: phase[0],
          };
        } else {
          newFiltersV2 = {
            ...newFiltersV2.games[teamId],
            custom_attributes: phase,
          };
        }
      }
      if (season && teamId && activities.length === 0) {
        if (season.includes('-')) {
          newFiltersV2.games[teamId] = {
            season: [season.split('-')[1]],
            tournament: [season.split('-')[0]],
          };
        } else if (season === 'manual') {
          // console.log('manual');
        } else {
          newFiltersV2.games[teamId] = {
            lastX: season.split('last')[1],
          };
        }
      }
      if (playersIds.length && teamId) {
        newFiltersV2 = {
          ...newFiltersV2,
          players: {
            [teamId]: playersIds,
          },
        };
      }
      if (lastX.length > 0 && teamId) {
        newFiltersV2.games[teamId] = {
          ...newFiltersV2.games[teamId],
          lastX: lastX[0],
        };
        dispatch(AC_SetLastXFilterAction(Number(lastX[0])));
      }
      if (metricId.length > 0 && metric.length === 0) {
        newFiltersV2 = {
          ...newFiltersV2,
          attributes: metricId.map((el) => Number(el)),
        };
        if (mSearchType) {
          newFiltersV2 = {
            ...newFiltersV2,
            metrics_search_type: mSearchType,
          };
        }
        dispatch(setSelectedAttributes(metricId.map((m) => Number(m))));
      } else if (metric.length > 0) {
        if (newFiltersV2.custom_attributes) {
          newFiltersV2 = {
            ...newFiltersV2,
            custom_attributes: [...newFiltersV2.custom_attributes, ...metric],
          };
          if (mSearchType) {
            newFiltersV2 = {
              ...newFiltersV2,
              metrics_search_type: mSearchType,
            };
          }
        } else {
          newFiltersV2 = {
            ...newFiltersV2,
            custom_attributes: metric,
          };
          if (mSearchType) {
            newFiltersV2 = {
              ...newFiltersV2,
              metrics_search_type: mSearchType,
            };
          }
        }
      }
      if (tournaments.length) {
        dispatch(
          AC_AddSelectedTournamentFilterAction(
            tournaments.map((t) => Number(t)),
          ),
        );
      }
      if (authorTeamId) {
        newFiltersV2.author_team_id = authorTeamId;
      }
      if (opposite.length > 0 && opposite[0] === 'true') {
        newFiltersV2.opposite = true;
      }
      if (
        seasons.length > 0 &&
        teamId &&
        seasons[0] !== 'manual' &&
        activities.length === 0
      ) {
        newFiltersV2.games[teamId] = {
          ...newFiltersV2.games[teamId],
          season: seasons,
        };
      }
      if (role) {
        newFiltersV2.role = role[0];
      }
      if (exTid) {
        newFiltersV2.ext_team_id = exTid;
      }
      if (authorTeamId) {
        dispatch(setActiveTeamFilterAction(Number(authorTeamId), false));
      }
      if (
        teamId ||
        exTid ||
        markupIds.length > 0 ||
        (eventIds.length && authorTeamId) ||
        storageEventsIds.length
      ) {
        dispatch(AC_SetSelectedGames(activities));
        dispatch(setVideosListIsLoading(true));
        EventsAPI.getEpisodes(newFiltersV2)
          .then((response) => {
            dispatch(AC_GetVideoEpisodesSuccess(response.data.results));
            for (const game of response.data.results) {
              dispatch(AC_ToggleIsCollapsed(game.id));
              dispatch(AC_AddEventsToExpandedVideo([], game.id));
            }
            dispatch(AC_setIntermediateActivitiesAction([]));
            dispatch(setStatsCustomGameList([]));
            dispatch(setVideosListIsLoading(false));
          })
          .catch(() => {
            dispatch(setVideosListIsLoading(false));
          });
      }
      if (activities) {
        dispatch(AC_SelectedMatchesFilterAction(activities));
      }
      dispatch(AC_OpenFilterAction(''));
    }
  }, []);
  const handleError = (error: any) => {
    if (error.message?.includes('The play() request was interrupted')) {
      // dispatch(VideoErrorAction('Видео загружается, пожалуйста, подождите'));
    } else {
      // dispatch(VideoErrorAction('Ошибка получения видео'));
    }
  };
  const [horizontalScroll, setHorizontalScroll] = useState(0);

  const progressContainerRef = useRef<any>(null);
  useEffect(() => {
    // console.log(horizontalScroll)
    if (progressContainerRef && progressContainerRef.current) {
      progressContainerRef.current.style.left = `-${horizontalScroll}px`;
    }
  }, [horizontalScroll]);

  const dispatch = useAppDispatch();
  const onPlayerReady = async () => {
    await dispatch(AC_SetPlayerLoadingAction(false));
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const triggerSeekAndPlay = (play = true) => {
    if (playedEpisode && playedVideoSet) {
      const section = playedEpisode;
      const period = playedVideoSet?.periods?.filter(
        (period: any) => period.name === playedEpisode.period_name,
      )[0];
      let timer;
      if (section.user_timer) {
        timer = section.user_timer;
      } else {
        timer = section.file_timer;
      }
      let windowTime = WINDOW_MODE_WIDTH;
      if (playedEpisode.episode_type === EpisodeTypes.period) {
        windowTime = 0;
      }
      const tt = Math.max(
        timer.started_at - windowTime * 1000,
        period?.started_at,
      );
      refPlayer?.current?.seekTo(tt / 1000, 'seconds');
      if (play) {
        dispatch(AC_SetIsPlayingVideoAction(true));
      }
      const episodeDiv = document.getElementById(section?.id);
      if (episodeDiv) {
        episodeDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
    }
  };
  useEffect(() => {
    if (!isLoading && playCommand === PlayCommandType.play) {
      triggerSeekAndPlay(true);
      dispatch(AC_SetPlayerCommandAction(PlayCommandType.none));
    } else if (playCommand === PlayCommandType.resume) {
      dispatch(AC_SetIsPlayingVideoAction(true));
      dispatch(AC_SetPlayerCommandAction(PlayCommandType.none));
    } else if (!isLoading && playCommand === PlayCommandType.scrollTo) {
      triggerSeekAndPlay(false);
      dispatch(AC_SetPlayerCommandAction(PlayCommandType.pause));
    }
  }, [playedVideoSet, isLoading, playCommand]);
  const onBufferEndReady = async () => {
    // await dispatch(AC_SetPlayerLoadingAction(false));
  };

  const scrollerRef = useRef<any>(null);
  useLayoutEffect(() => {
    if (activeVideo) {
      const progressWidth = scrollerRef.current?.clientWidth;
      let oldScreenPosition = 0;
      let newScreenPosition = 0;
      if (!!playerState?.playedSeconds && !!activeVideo?.duration) {
        oldScreenPosition =
          (playerState?.playedSeconds / activeVideo?.duration) * progressWidth;
      }
      const newWidth = progressWidth * (1 + videoTimeZoom / 100);
      if (!!playerState?.playedSeconds && !!activeVideo?.duration) {
        const newProgressBarPosition =
          (playerState?.playedSeconds / activeVideo?.duration) * newWidth;
        newScreenPosition = newProgressBarPosition - oldScreenPosition;
      }
      if (scrollerRef && scrollerRef?.current) {
        scrollerRef.current.scrollLeft = newScreenPosition;
      }
      setHorizontalScroll(newScreenPosition);
    }
  }, [videoTimeZoom]);

  const videoPlayerContainerRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [containerHeight, setContainerHeight] = useState(
    videoPlayerContainerRef?.current?.getBoundingClientRect().height - 16,
  );
  useEffect(() => {
    setContainerHeight(
      videoPlayerContainerRef?.current?.getBoundingClientRect().height - 16,
    );
  }, [isNarrowScreenMode, wrapperRef, editedEpisodeRange, createEpisodeMode]);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(0);
  const [rewindSpeed, setRewindSpeed] = useState(1);
  const rewindRef = useRef(rewindSpeed);
  const playedEpisodeRef = useRef(playedEpisode);
  const isPlayingRef = useRef(isPlaying);
  const visibleRangeRef = useRef(visibleRange);
  useEffect(() => {
    rewindRef.current = rewindSpeed;
  }, [rewindSpeed]);
  useEffect(() => {
    playerReducerRef.current = playerReducer;
  }, [playerReducer]);
  useEffect(() => {
    hotkeysSettingsRef.current = playerHotkeysAllowed;
  }, [playerHotkeysAllowed]);
  useEffect(() => {
    reelsModeOnRef.current = reelsModeOn;
  }, [reelsModeOn]);

  useEffect(() => {
    playedEpisodeRef.current = playedEpisode;
  }, [playedEpisode]);
  useEffect(() => {
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);
  useEffect(() => {
    visibleRangeRef.current = visibleRange;
  }, [visibleRange]);
  const { toggleNextEpisode, togglePreviousEpisode } = hotkeysReducer.actions;
  const { nextEpisodeCommand, previousEpisodeCommand } = useSelector(
    (state: AppStateType) => {
      return state.hotkeysReducer;
    },
  );
  useEffect(() => {
    if (nextEpisodeCommand) {
      handler.playNext().then();
      dispatch(toggleNextEpisode(false));
    }
    if (previousEpisodeCommand) {
      handler.playPrevious().then();
      dispatch(togglePreviousEpisode(false));
    }
  }, [nextEpisodeCommand, previousEpisodeCommand]);

  const keyDownHandler = useCallback(
    (event: any) => {
      // Обработчик горячих клавиш
      if (hotkeysSettingsRef.current && playerReducerRef.current.activeVideo) {
        const ct = refPlayer?.current?.getCurrentTime() || 0;
        let fromT;
        let toT;
        if (visibleRangeRef.current.length > 0) {
          fromT = visibleRangeRef.current[0];
          toT = visibleRangeRef.current[1];
        } else {
          const period = playedVideoSet?.periods?.filter(
            (period: any) => period.name === playedEpisode?.period_name,
          )[0];
          fromT = initTimer(playedEpisodeRef.current, 'started_at', period);
          toT = initTimer(playedEpisodeRef.current, 'finished_at', period);
        }
        if (
          event.code === 'ArrowLeft' &&
          !(
            (detectOS() === 'MacOS' && event.metaKey) ||
            (detectOS() === 'Windows' && event.ctrlKey)
          )
        ) {
          if (refPlayer?.current && fromT < ct * 1000) {
            if (ct * 1000 - rewindRef.current > fromT) {
              refPlayer?.current?.seekTo(ct - rewindRef.current, 'seconds');
            } else {
              refPlayer?.current?.seekTo(fromT / 1000, 'seconds');
            }
          }
        } else if (
          event.code === 'ArrowRight' &&
          !(
            (detectOS() === 'MacOS' && event.metaKey) ||
            (detectOS() === 'Windows' && event.ctrlKey)
          ) &&
          refPlayer?.current &&
          toT > ct * 1000
        ) {
          refPlayer?.current?.seekTo(ct + rewindRef.current, 'seconds');
        } else if (event.code === 'Space') {
          console.log(); // это не убирать :-) иначе иконка pause/play не меняется
          dispatch(AC_SetIsPlayingVideoAction(!isPlayingRef.current));
        } else if (
          event.code === 'ArrowRight' &&
          ((detectOS() === 'MacOS' && event.metaKey) ||
            (detectOS() === 'Windows' && event.ctrlKey))
        ) {
          event.preventDefault();
          if (reelsModeOnRef.current) {
            dispatch(setToggleFrameState('next'));
          } else if (playerReducerRef.current.editedEpisodeRange.length === 0) {
            dispatch(toggleNextEpisode(true));
          }
        } else if (
          event.code === 'ArrowLeft' &&
          ((detectOS() === 'MacOS' && event.metaKey) ||
            (detectOS() === 'Windows' && event.ctrlKey))
        ) {
          event.preventDefault();
          if (reelsModeOnRef.current) {
            dispatch(setToggleFrameState('previous'));
          } else if (playerReducerRef.current.editedEpisodeRange.length === 0) {
            dispatch(togglePreviousEpisode(true));
          }
        }
      }
    },
    [
      playerReducerRef,
      rewindRef,
      hotkeysSettingsRef,
      playedEpisodeRef,
      isPlayingRef,
      visibleRangeRef,
      reelsModeOnRef,
    ],
  );
  const [subscriptions, setSubscriptions] = useState<Array<any>>([]);
  const subsriptionsRef = useRef<any>(keyDownHandler);
  const [from, setFrom] = useState(
    editedFileEpisodeRange.length > 0 ? editedFileEpisodeRange[0] : 0,
  );
  const [to, setTo] = useState<number>(
    editedFileEpisodeRange.length > 0 ? editedFileEpisodeRange[1] : 0,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editedFrom, setEditedFrom] = useState<number>(() => {
    if (playedEpisode && editedEpisodeRange.length > 0) {
      return editedEpisodeRange[0] - 0;
    } else {
      return 0;
    }
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editedTo, setEditedTo] = useState<number>(() => {
    if (playedEpisode && editedEpisodeRange.length > 0) {
      return editedEpisodeRange[1] - 0;
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (activeVideo?.duration) {
      if (playedEpisode && editedEpisodeRange.length > 0) {
        if (editedEpisodeRange[0] >= 0) {
          setEditedFrom(editedEpisodeRange[0]);
          setFrom(editedFileEpisodeRange[0]);
        } else {
          setEditedFrom(0);
          setFrom(0);
        }
        if (editedEpisodeRange[1] <= activeVideo?.duration * 1000) {
          setEditedTo(editedEpisodeRange[1] - 0);
          setTo(editedFileEpisodeRange[1]);
        } else {
          setEditedTo(0);
          setTo(activeVideo.duration * 1000);
        }
      } else {
        const period = playedVideoSet?.periods?.filter(
          (period: any) => period.name === playedEpisode?.period_name,
        )[0];
        setFrom(initTimer(playedEpisode, 'started_at', period, false, true));
        setTo(initTimer(playedEpisode, 'finished_at', period, false, true));
      }
    }
  }, [editedEpisodeRange, editedFileEpisodeRange]);

  useEffect(() => {
    if (
      activeVideo?.duration &&
      editedEpisodeRange.length === 0 &&
      !createEpisodeMode &&
      playedEpisode &&
      playedVideoSet
    ) {
      const period = playedVideoSet?.periods?.filter(
        (period: any) => period.name === playedEpisode?.period_name,
      )[0];
      setFrom(initTimer(playedEpisode, 'started_at', period, false, true));
      setTo(initTimer(playedEpisode, 'finished_at', period, false, true));
    }
  }, [playedEpisode, playedVideoSet]);
  // react to create episode mode
  useEffect(() => {
    let gameTimerFrom = 0;
    let gameTimerTo = 0;
    let fileFrom = 0;
    let fileTo = 0;
    if (playerState && createEpisodeMode && activeVideo?.duration) {
      const offset =
        playedEpisode?.file_timer.started_at -
        playedEpisode?.game_timer.started_at;
      if (playerState?.playedSeconds * 1000 - WINDOW_MODE_WIDTH_MS > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gameTimerFrom =
          playerState?.playedSeconds * 1000 - WINDOW_MODE_WIDTH_MS - offset;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fileFrom = playerState?.playedSeconds * 1000 - WINDOW_MODE_WIDTH_MS;
      }
      if (
        playerState?.playedSeconds * 1000 + WINDOW_MODE_WIDTH_MS <
        activeVideo?.duration * 1000
      ) {
        gameTimerTo =
          playerState?.playedSeconds * 1000 + WINDOW_MODE_WIDTH_MS - offset;
        fileTo = playerState?.playedSeconds * 1000 + WINDOW_MODE_WIDTH_MS;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gameTimerTo = activeVideo?.duration * 1000;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fileTo = activeVideo?.duration * 1000;
      }
      const period = playedVideoSet?.periods?.filter(
        (period: any) => period.name === playedEpisode?.period_name,
      )[0];
      const timerStart = initTimer(
        playedEpisode,
        'started_at',
        period,
        true,
        false,
        playerState?.playedSeconds,
      );
      const timerFinish = initTimer(
        playedEpisode,
        'finished_at',
        period,
        true,
        false,
        playerState?.playedSeconds,
      );
      const range = [timerStart, timerFinish];
      const fileRange = [
        initTimer(
          playedEpisode,
          'started_at',
          period,
          false,
          true,
          playerState?.playedSeconds,
        ),
        initTimer(
          playedEpisode,
          'finished_at',
          period,
          false,
          true,
          playerState?.playedSeconds,
        ),
      ];
      if (createEpisodeMode) {
        dispatch(setEditedEpisodeRange(range));
        dispatch(setEditedEpisodeFileRange(fileRange));
        dispatch(setVideoListEditMode(playedEpisode?.id));
      }
    }
  }, [createEpisodeMode]);
  const [isMuted, setIsMuted] = useState(true);
  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, true);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, true);
    };
  }, [subsriptionsRef]);
  const [t] = useTranslation();
  return (
    <div className="contentContainer">
      {
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: `${isNarrowScreenMode ? 'row-reverse' : 'column'}`,
              // justifyContent: `space-around`,
              justifyContent: 'center',
              height: `${isNarrowScreenMode ? '100%' : 'auto'}`,
              alignItems: 'center',
              // paddingTop:`${!isNarrowScreenMode ? '2rem' : ''}`,
              width: '100%',
              gap: 8,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: isNarrowScreenMode ? 0 : 16,
              // width: contentWidth(),
              objectFit: 'cover',
              position: 'relative',
              flexGrow: 1,
              flex: 1,
              overflow: 'hidden',
            }}
            ref={videoPlayerContainerRef}
          >
            {activeVideo ? (
              <>
                <PlayerWrapper
                  isMuted={isMuted}
                  isNarrowScreenMode={isNarrowScreenMode}
                  onClick={() => {
                    dispatch(AC_SetIsPlayingVideoAction(!isPlayingRef.current));
                  }}
                  toggleFullScreen={toggleFullScreen}
                  activeVideo={activeVideo}
                  keyDownHandler={keyDownHandler}
                  subscriptions={subscriptions}
                  rewindSpeed={rewindSpeed}
                  refPlayer={refPlayer}
                  videoPlayerContainerRef={videoPlayerContainerRef}
                  playbackRate={playbackRate}
                  isPlaying={isPlaying}
                  handleError={handleError}
                  handleProgress={handleProgress}
                  onBufferEndReady={() => onBufferEndReady()}
                  onPlayerReady={() => onPlayerReady()}
                  volume={volume}
                  setSubscriptions={setSubscriptions}
                  monitorPlayback={handler.monitorPlayback}
                  playerState={playerState}
                  playedEpisode={playedEpisode}
                  subsriptionsRef={subsriptionsRef}
                />
                {/* </div> */}
              </>
            ) : playerMode === MODES.playlists && !openedPlaylist ? (
              <Flex vertical>
                <div className="no-episodes-title">
                  {t('Nothing is being played')}
                </div>
                <div className="no-episodes-content">
                  {t('Please select a playlist from the panel on the right')}
                </div>
              </Flex>
            ) : playerMode === MODES.playlist_view &&
              openedPlaylist &&
              openedPlaylist.playlist_events.length === 0 ? (
              <></>
            ) : (
              <div>
                <Flex gap={6} vertical>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: 'rgba(255, 255, 255, 0.65)',
                        colorText:
                          'var(--colorTextLabel, rgba(255, 255, 255, 0.65))',
                      },
                    }}
                  >
                    <Spin className="video-spinner">
                      <div />
                    </Spin>
                  </ConfigProvider>
                </Flex>
              </div>
            )}
            <Flex align="center" style={{ zIndex: 10, position: 'absolute' }}>
              <div
                style={{
                  position: 'relative',
                  margin: 'auto',
                  width: '200px',
                }}
              >
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: 'rgba(255, 255, 255, 0.65)',
                      colorText:
                        'var(--colorTextLabel, rgba(255, 255, 255, 0.65))',
                    },
                  }}
                >
                  <Spin spinning={isLoading} className="video-spinner">
                    <div />
                  </Spin>
                </ConfigProvider>
              </div>
            </Flex>
          </div>
          {activeVideo && (
            <>
              <div
                className={
                  'cameraCheckWindow_VideoControls flex-column w100 ai-c'
                }
                style={{
                  width: '100%',
                  minHeight: `${!isNarrowScreenMode || createEpisodeMode ? '111px' : 'auto'}`,
                  position: 'relative',
                }}
              >
                {(editedEpisodeRange?.length > 0 || createEpisodeMode) && (
                  <div
                    className={'cameraCheckWindow_ProgressBar'}
                    ref={progressContainerRef}
                  >
                    {!reelsModeOn &&
                      !createEpisodeMode &&
                      editedEpisodeRange?.length > 0 && (
                        <EditEpisodePlayerController
                          from={from}
                          setFrom={setFrom}
                          to={to}
                          setTo={setTo}
                        />
                      )}
                    {reelsModeOn && (
                      <ReelsEditPlayerController
                        playerState={playerState}
                        setFrom={setFrom}
                        to={to}
                        setTo={setTo}
                      />
                    )}
                    {createEpisodeMode && (
                      <CreateEpisodePlayerController
                        to={to}
                        setTo={setTo}
                        from={from}
                        setFrom={setFrom}
                        setEditedTo={setEditedTo}
                        setEditedFrom={setEditedFrom}
                        duration={activeVideo?.duration}
                        currentPosition={playerState?.playedSeconds}
                        closeCallback={() => {
                          dispatch(setCreateEpisodeMode(false));
                          dispatch(AC_SetPlayerHotkeysAllowedAction(true));
                          dispatch(resetEditMode());
                          dispatch(AC_SetVisibleRangeAction([]));
                          dispatch(setAddToPlaylistPopoverIsOpen(false));
                          dispatch(
                            setAddNewEpisodeToPlaylistPopoverIsOpen(false),
                          );
                        }}
                      />
                    )}
                  </div>
                )}
                <VideoPlayerTimeline
                  refPlayer={refPlayer}
                  playerState={playerState}
                  to={to}
                  setTo={setTo}
                  from={from}
                  setFrom={setFrom}
                  setEditedFrom={setEditedFrom}
                  setEditedTo={setEditedTo}
                />
                <VideoPlayerButtonsController
                  refPlayer={refPlayer}
                  rewindSpeed={rewindSpeed}
                  setRewindSpeed={setRewindSpeed}
                  playbackRate={playbackRate}
                  setPlaybackRate={setPlaybackRate}
                  playerState={playerState}
                  isNarrowScreenMode={isNarrowScreenMode}
                  toggleFullScreen={toggleFullScreen}
                  volume={volume}
                  setVolume={setVolume}
                  setIsMuted={setIsMuted}
                  to={to}
                  from={from}
                />
              </div>
            </>
          )}
        </>
      }
      <GameProtocolSidePanel />
      {isNarrowScreenMode && <EpisodeFloatingLabel />}
      <Tooltip
        variant={'dark'}
        id={'videoPlayerControlsTooltip'}
        noArrow={false}
        className={'videoAttributesTooltip'}
        place={'top'}
        globalCloseEvents={{
          escape: true,
          resize: true,
          clickOutsideAnchor: true,
          scroll: true,
        }}
      />
    </div>
  );
};
